<div>
    <h2 mat-dialog-title>Select Category 1 and Category 2 <span>
            <mat-icon (click)="dialogclose()"
                      class="float-right cursor-pointer icon-18">close</mat-icon>
        </span> </h2>
</div>
<mat-dialog-content class="mat-typography view-rfq-respond add-product">
    <form novalidate class="app-form-horizontal">
        <div fxFlex="99">
            <div fxLayout="row wrap"
                 fxLayoutGap="30px grid"
                 class="m-y-16">
                <div fxFlex="50"
                     fxFlex.lt-sm="100">
                    <div fxFlex
                         fxFlex.lt-sm="100" style="padding-left: 5px;">
                        <label class="register-form-label font-bold">Category 1</label>
                        <mat-form-field class="form-field-style"
                                        appearance="outline">
                            <mat-select [multiple]="true"
                                        disableOptionCentering
                                        panelClass="common-drop-down-style"
                                        placeholder="select"
                                        [formControl]="category1Ctrl"
                                        #multiSelect1>
                                <mat-option>
                                    <ngx-mat-select-search [placeholderLabel]="'Search'"
                                                           [noEntriesFoundLabel]="'No matching Company found'"
                                                           [formControl]="CategoryList1FilterCtrl">
                                        <span ngxMatSelectSearchClear></span>
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let data of filteredCategoryList1 | async; index as i;"
                                            [value]="data">{{data.categoryName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex="50"
                     fxFlex.lt-sm="100">
                    <div fxFlex
                         fxFlex.lt-sm="100">
                        <label class="register-form-label font-bold">Category 2</label>
                        <mat-form-field class="form-field-style"
                                        appearance="outline">
                            <mat-select [multiple]="true"
                                        disableOptionCentering
                                        panelClass="common-drop-down-style"
                                        placeholder="select"
                                        [formControl]="category2Ctrl"
                                        #multiSelect2>
                                <mat-option>
                                    <ngx-mat-select-search [placeholderLabel]="'Search'"
                                                           [noEntriesFoundLabel]="'No matching Company found'"
                                                           [formControl]="CategoryList2FilterCtrl">
                                        <span ngxMatSelectSearchClear></span>
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let data of filteredCategoryList2 | async; index as i;"
                                            [value]="data">{{data.categoryName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex="50" fxFlex.lt-sm="100" *ngIf="category1Ctrl?.value">
                    <div fxFlex="100"  fxFlex.lt-sm="100">
                        <label class="register-form-label font-bold">Selected items in Category 1</label>
                        <mat-list class="selectd-categories">
                                <mat-list-item *ngFor="let data of category1Ctrl?.value"> {{ data.categoryName}}</mat-list-item> 
                        </mat-list>
                    </div>
                </div>
                <div fxFlex="50" fxFlex.lt-sm="100" *ngIf="category2Ctrl?.value">
                    <div fxFlex="100" fxFlex.lt-sm="100">
                        <label class="register-form-label font-bold">Selected items in Category 2</label>
                        <mat-list class="selectd-categories">
                            <mat-list-item *ngFor="let data of category2Ctrl?.value"> {{ data.categoryName}}</mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </div>

        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="m-r-8 cancel-button"
            (click)="dialogclose()"
            mat-stroked-button> Cancel </button>
    <button class="m-r-8 action-button"
            (click)="save()"
            mat-stroked-button> Save </button>
</mat-dialog-actions>
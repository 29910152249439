import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ServiceConfigs, HttpService, SessionsService } from '@core';
import { MustMatch } from '@shared';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from '@core/services/message.service';
import { Subject } from 'rxjs';
import { Regex } from '@core/regex';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public emailForm: UntypedFormGroup;
  public otpForm: UntypedFormGroup;
  public resetForm: UntypedFormGroup;
  public resetPasswordFlag: boolean = false;
  public enterEmailIDFlag: boolean = true;
  public enterOTPFlag: boolean = false;
  public enterOTP: boolean = false;
  public userType: string = '';
  public resetPasswordRedirect: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private serviceConfigs: ServiceConfigs,
    private toastr: ToastrServiceClass,
    private router: Router,
    private httpService: HttpService,
    private sessionsSrv: SessionsService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute) { }

  // life cycle hooks

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['isResetPassword'] == 'true') {
        this.enterEmailIDFlag = false;
        this.resetPasswordFlag = true;
        this.resetPasswordRedirect = true;
        this.generateResetForm();
      } else {
        this.generateEmailForm();
      }
    });


    this.userType = this.activatedRoute.snapshot.paramMap.get('user');

    setTimeout(() => {
      if (this.userType === '') {
        this.router.navigate(['/']);
      }
    }, 100);

  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  public hasError = (controlName: string, errorName: string) => {
    return this.emailForm.controls[controlName].hasError(errorName);
  }

  public hasErrorOtp = (controlName: string, errorName: string) => {
    return this.otpForm.controls[controlName].hasError(errorName);
  }

  public hasErrorPassword = (controlName: string, errorName: string) => {
    return this.resetForm.controls[controlName].hasError(errorName);
  }

  public generateEmailForm() {
    this.emailForm = this.formBuilder.group({
      email: new UntypedFormControl('', [Validators.required])
    });
  }

  public generateOTPForm() {
    this.otpForm = this.formBuilder.group({
      otpInput: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  public generateResetForm() {
    this.resetForm = this.formBuilder.group({
      password: new UntypedFormControl(null, [Validators.required, Validators.pattern(Regex.passwordPattern)]),
      confirmPassword: new UntypedFormControl(null, [Validators.required])
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  public onlyNumber(evt): boolean {
    evt = (evt) ? evt : '';
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public EmailSubmitButtonClick(event: any) {
    if (this.emailForm.valid) {
      this.toastr.loaderShow();
      const request = '?emailId=' + this.emailForm.value.email;
      let serviceConfigs = this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.EMAIL_VERIFICATION;

      if (this.userType === 'mngr') {
        serviceConfigs = this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.EMAIL_VERIFICATION_ADMIN;
      }

      this.httpService.invokeGet(serviceConfigs, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        try {
          if (data && data?.isError === false) {
            this.enterEmailIDFlag = false;
            this.enterOTPFlag = true;
            this.resetPasswordFlag = false;
            // this.toastr.info(data?.message, 'Information');
            this.generateOTPForm();
          } else if (data && data?.isError === true) {
            if (data?.message === 'New') {
              this.messageService.displayBoxMessage = 'Your registration is pending for approval. You will be allowed to login after approval.';
              this.router.navigate(['/auth/message']);
            } else if (data?.message === 'Rejected') {
              this.messageService.displayBoxMessage = 'Sorry, Your registration is rejected. You should have received an email on this. Kindly check it for more information.';
              this.router.navigate(['/auth/message']);
            } else {
              this.toastr.error(data?.message);
            }
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      });
    }
  }

  public OTPSubmitButtonClick(event: any) {
    if (this.otpForm.valid) {
      this.toastr.loaderShow();
      const request = '?otpCode=' + this.otpForm.value.otpInput;
      let serviceConfigs = this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.OTP_VERIFICATION;

      if (this.userType === 'mngr') {
        serviceConfigs = this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.OTP_VERIFICATION_ADMIN;
      }
      this.httpService.invokeGet(serviceConfigs, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        try {
          if (data && data?.isError === false) {
            if (data?.message === 'OTP Validated') {
              this.enterEmailIDFlag = false;
              this.enterOTPFlag = false;
              this.resetPasswordFlag = true;
              // this.toastr.success(data?.message);
              this.generateResetForm();
            }
          } else if (data?.isError === true) {
            this.toastr.error(data?.message);
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      });
    }
  }

  public ResetButtonClick(event: any) {
    const request = this.resetForm.value;
    if (this.resetPasswordRedirect) {
      request.email = localStorage.getItem('supplier-temail');
    } else {
      request.email = this.emailForm.value.email;
    }

    request.oldPassword = '';
    this.toastr.loaderShow();

    let serviceConfigs = this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.RESET_PASSWORD;
    if (this.userType === 'mngr') {
      serviceConfigs = this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.RESET_PASSWORD_ADMIN;
    }

    this.httpService.invokePost(serviceConfigs, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data) {
          if (data.body.isError === false) {
            if (this.userType === 'splr') {
              this.router.navigate(['/auth/login']);
            } else {
              if (this.resetPasswordRedirect) {
                localStorage.removeItem('supplier-temail');
              }
              this.router.navigate(['/admin-login']);
            };
            // this.toastr.success(data?.body?.message);
            this.sessionsSrv.setStorage({});

          } else {
            this.toastr.error(data?.body?.message);
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    });
  }

  public resendOTP() {
    this.toastr.loaderShow();
    const request = '?emailId=' + this.emailForm.value.email;
    let serviceConfigs = this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.RESEND_OTP;
    if (this.userType === 'mngr') {
      serviceConfigs = this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.RESEND_OTP_ADMIN;
    }
    this.httpService.invokeGet(serviceConfigs, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data?.message !== undefined && data?.message !== '') {
          // succeeded
          this.toastr.success(data?.message);
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    });
  }

  public cancelClick() {
    if (this.userType === 'splr') {
      this.router.navigate(['/auth/login']);
    } else {
      this.router.navigate(['/admin-login']);
    }
  }
}

<div>
    <h2 mat-dialog-title>View Service Information <span><mat-icon (click)="dialogclose()" class="float-right cursor-pointer icon-18">close</mat-icon> </span> </h2>
</div>
<mat-dialog-content class="mat-typography">
    <div fxLayout="row wrap"  fxLayoutAlign="space-around center" fxLayoutGap="16px grid"  >
        <div fxFlex="50" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Service Offered </div> 
            <div class="text-grey-900" > {{service.serviceName}} </div>
        </div>

        <div fxFlex="50" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Category</div> 
            <div class="text-grey-900" > {{service.categoryName}} </div>
        </div>
        <div fxFlex="50" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Sub-Category </div> 
            <div class="text-grey-900" > {{service.subCategoryName}}</div>
        </div>
        <div fxFlex="50" fxFlex.lt-sm="100" *ngIf="service.url !== null">
            <div class="text-grey-500" >Hyperlink / File url</div> 
            <div class="text-grey-900 " > <a style="color:red; text-decoration: underline;" href="https://{{service.url}}" target="_blank">{{ service.url }}</a></div>
        </div>
        <div fxFlex="100" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Description</div> 
            <div class="text-grey-900 " >  {{service.serviceDescription}}</div>
        </div>
        <div *ngIf='service.attachment1 !== null && service.attachment1 !== "" ' fxFlex="100" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Attachments</div> 
            <div class="text-grey-900 " ><span *ngIf="service.attachment1 !== null && service.attachment1 !== '' " matPrefix><mat-icon class="attach-icon-style"> attach_file</mat-icon>&nbsp;</span> 
                <span style="text-decoration: underline;cursor: pointer;" (click)="downloadFile(service.attachment1)">{{service.attachment1}}</span>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="m-r-8 cancel-button" (click)="dialogclose()" mat-stroked-button >
        Back
    </button>
</mat-dialog-actions>


export enum Regex {
    decimalNumberPattern = '^([1-9][0-9]{1,9})(\.([0-9]{1,4})?)?$',
    wholeNumberPattern = '[0-9]+',
    wholeNumberPatternMarketPlace = '^[1-9][0-9]*$',
    restrictFirstSpecialChar = '^[a-zA-Z0-9]+.*$',
    websiteURLPattern = '^(http[s]?:\\/\\/){0,1}(www\\.){0,1}[a-zA-Z0-9\\/\\.\\-]+\\.[a-zA-Z\\/\\.\\-]+$',
    alphaNumericPattern = '^[a-zA-Z0-9]+.*$',
    alphaNumericPatternHelp = '^[^0-9 ]{1}([0-9a-zA-Z-/_%?#$. ])+[0-9a-zA-Z-/_%?#$.]+$',
    alphaNumericPatternMarketPlace = '^[-_a-zA-Z0-9]+([0-9a-zA-Z-/_ ])*$',
    websiteURLPatternMarketPlace = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
    websiteURLPatternBook = '^(http[s]?:\\/\\/){0,1}(www\\.){0,1}[a-zA-Z0-9\\.\\-]+\\.[a-zA-Z]{2,5}[\\.]{0,1}$',
    websiteURLPatternSupplierMgmt = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?|n.a|N.A|N.a|n.A',
    numberPattern = '[+0-9- ]*',
    contactNumberPattern = '[+0-9-]+',
    alphaNumericPatternProduct = '^[^0-9 ]{1}([0-9a-zA-Z-/_ ])+[0-9a-zA-Z-/_]+$',
    passwordPattern = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[@#$%^&+=!]).{12,}$',
    faxNumberPattern = '[+0-9- ]+',
    currencyRatePattern = '^[0-9]+(\.([0-9]{1,4})?)?$',
    currencyRatePattern1 = '^[0-9]+(\.([0-9]{1,8})?)?$',
    usernamePattern = '^[a-zA-Z0-9_]{5,50}$',
    alphaNumericPatternRegister = '^[a-zA-Z0-9 ]*',
    interestedQtyPattern = '^[0-9]*$',
    alphaNumericPatternAddTask = '^[^0-9 ]{1}([0-9a-zA-Z-+/_()& ])+[0-9a-zA-Z-+/_()&]+$'
}
import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { ServiceConfigs, HttpService } from '@core';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SecondarySupplierDetails, SecondarySupplierResponse } from '@core/interfaces/user';



@Component({
  selector: 'app-blocked-view-secondary',
  templateUrl: './view-secondary.component.html',
  styleUrls: ['./view-secondary.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class BlockedViewSecondaryComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public service: SecondarySupplierDetails;
  public supplierId: any = '';
  public id: any = '';
  constructor(
    private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
    private toastr: ToastrServiceClass,
    private dialogRef: MatDialogRef<BlockedViewSecondaryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  // life cycle hooks

  ngOnInit() {
    this.supplierId = this.data.userId;
    this.id = this.data.id;
    this.getServiceByID();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  public getServiceByID() {
    const id = this.supplierId;
    if (id) {
      const request = '?userId=' + this.supplierId;
      this.toastr.loaderShow();
      this.httpService.invokeService(this.serviceConfigs.PROFILE_SERVICES.GETBYID_SECONDRY_DATA, request).then((data: SecondarySupplierResponse) => {
        try {
          if (data && data?.isError === false) {
            // succeeded
            this.service = data?.payload;
          } else if (data && data?.isError === true) {
            this.toastr.error(data?.message);
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      });
    }
  }

  public rejectClick() {
    const request = '?newSupplierStatus=Rejected&supplierId=' + this.id + '&comments=null';
    this.toastr.loaderShow();
    this.httpService.invokePut(this.serviceConfigs.ADMIN_APP_CONFIG.SUPPLIER_STATUS, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data && data?.isError === false) {
          // succeeded
          this.toastr.success(data?.message);
          this.dialogRef.close();
        } else if (data && data?.isError === true) {
          this.toastr.error(data?.message);
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    });
  }

  public approveClick() {
    const request = '?newSupplierStatus=Approved&supplierId=' + this.id + '&comments=null';
    this.toastr.loaderShow();
    this.httpService.invokePut(this.serviceConfigs.ADMIN_APP_CONFIG.SUPPLIER_STATUS, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data && data?.isError === false) {
          // succeeded
          this.toastr.success(data?.message);
          this.dialogRef.close();
        } else if (data && data?.isError === true) {
          this.toastr.error(data?.message);
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    });
  }
  
  public dialogclose() {
    this.dialogRef.close();
  }

}

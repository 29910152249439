import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { ServiceConfigs } from '../../../core/serviceconfig';
import { DashboardService } from './dashboard.srevice';
import { HttpService } from '../../../core/services/http.service';
import { SmartDialog, AddTaskDialogComponent } from '@shared';
import { MatDialog } from '@angular/material/dialog';
import { SessionsService } from '@core';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardCountResponse } from '@core/interfaces/dashboard';
 
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [
    ` .mat-mdc-card {
                border-radius: 10px;
                padding: 0px !important;
                font-family: Montserrat;
                font-style: normal;
                width: 100%
              }
              .mat-mdc-card-header {
                font-family: Montserrat;
                color: #ffffff;
                padding:12px;
                font-weight: bold !important;
                font-size: 14px !important;
                line-height: 22px;
                background-color: #E30613 !important;
                border-radius: 10px 10px 0px 0px;
              }
              .icon-right {
                right: 12px;
                position: absolute;
              }
              .card-left-logo {
                position: absolute;
                top: 72px;
                left: 19px;
                font-size: 42px;
              }
        
            `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DashboardService, HttpService, ServiceConfigs, DatePipe]
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public dashColumns: any[] = [];
  public dashList: any[] = [];
  public isLoading: boolean = true;
  public openCount: string = '0';
  public invitedCount: string = '0';
  public responseCount: string = '0';
  public messageCount: string = '0';
  public searchTxt: string = '';
  constructor(
    private dashboardSrv: DashboardService,
    private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
    private cdr: ChangeDetectorRef,
    private smartDialog: SmartDialog,
    private matDialog: MatDialog,
    private toastr: ToastrServiceClass,
    private sessionService: SessionsService,
    public datePipe: DatePipe
  ) {
    this.dashColumns = this.dashboardSrv.getColumns(
    record => this.delete(record),
    record => this.edit(record),
    record => this.markAsCompleted(record));
    this.getTenderCounts();
  }

  // life cycle hooks

  ngOnInit() {
    this.initToDo(this.searchTxt);
    this.toastr.loaderHide();
  }

  ngAfterViewInit() { }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  public applyFilter(txt: any) {
    this.searchTxt = txt
    this.initToDo(this.searchTxt);
   }

   public initToDo(searchTxt) {
    this.dashList = [];
    const requestObject = '?userId=' + this.sessionService.getUserId()+'&searchKey=' + searchTxt;
    this.httpService.invokeService(this.serviceConfigs.DASHBOARD_SERVICES_CONFIG.GET_TODO_LIST, requestObject).then((response: any) => {
      if (response) {
         
        this.dashList = response?.payload;
        this.dashList.forEach((data) => {
          data.deadLine = this.datePipe.transform(data.deadLine , 'dd MMM yyyy HH:mm:ss');
        });
        this.cdr.detectChanges();
        this.isLoading = false;
      }
    });
  }

  public getTenderCounts() {
    this.toastr.loaderShow();
    this.dashList = [];
    const requestObject = '?userId=' + this.sessionService.getUserId() + '&companyId=' + this.sessionService.getCompanyUniqueId();
    this.httpService.invokeService(this.serviceConfigs.DASHBOARD_SERVICES_CONFIG.GET_DASHBOARD_TENDER_COUNTS, requestObject).then((response: DashboardCountResponse) => {
      if (response) {
        this.openCount = response?.payload['openCount'];
        this.invitedCount =  response?.payload['invitedCount'];
        this.responseCount =  response?.payload['responseCount'];
        this.messageCount  = response?.payload['messageCount'];
        this.cdr.detectChanges();
      }
      setTimeout(() => {
        this.toastr.loaderHide();
      }, 500 );
    });
  }

  public delete(value: any) {
    this.smartDialog.open({
      title: value.taskName,
      description: 'Are you sure to Delete the task ?',
      buttons: [
        {
          type: 'primary',
          text: 'Confirm',
          onClick: () => {
            const requestObject = '?taskId=' + value.id + '&status=' + 'deleted';
            this.httpService.invokeDelete(this.serviceConfigs.DASHBOARD_SERVICES_CONFIG.DELETE_TASK_DATA, requestObject).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
              try {
                if (data && data?.isError === false) {
                  this.initToDo(this.searchTxt);
                   this.toastr.success(data?.message);
                   this.cdr.detectChanges();
                } else if (data?.message === true) {
                  this.toastr.error(data?.message);
                }
              } catch (ex) {
                this.toastr.error(ex);
              }
            });
         }
       },
        {
          type: '',
          text: 'Cancel',
          onClick: () => {
            return ;
          },
        },
      ],
    });
  }

  public edit(value: any) {
    const dialogRef = this.matDialog.open(AddTaskDialogComponent, {
      panelClass : 'add-task-container',
      data: {screenName: 'RFQ', isEdit: true, taskId: value.id}
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
       
      this.initToDo(this.searchTxt);
    });
  }

  public changeSort(e: any) {
     
  }

  public markAsCompleted(value: any) {
    this.smartDialog.open({
      title: value.taskName,
      description: 'Are you sure to mark it as complete ?',
      buttons: [
        {
          type: 'primary',
          text: 'Confirm',
          onClick: () => {
            const requestObject = '?taskId=' + value.id + '&status=' + 'markAsCompleted';
            this.httpService.invokeDelete(this.serviceConfigs.DASHBOARD_SERVICES_CONFIG.DELETE_TASK_DATA, requestObject).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
              try {
                if (data && data?.isError === false) {
                  this.initToDo(this.searchTxt);
                   this.toastr.success(data?.message);
                   this.cdr.detectChanges();
                } else if (data?.message === true) {
                  this.toastr.error(data?.message);
                }
              } catch (ex) {
                this.toastr.error(ex);
              }
            });
         }
       },
        {
          type: '',
          text: 'Cancel',
          onClick: () => {
            return ;
          },
        },
      ],
    });
  }

}

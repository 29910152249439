import { Component, OnInit, Optional, Inject, ViewChild, ElementRef , OnDestroy } from '@angular/core';
import { ServiceConfigs, HttpService, SessionsService } from '@core';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { SmartDialog } from 'app/shared/components/smart-dialog/smart-dialog';
import { CustomDateRemoverPipe } from '@shared/pipes/custom-date-remover.pipe';
import { Subject } from 'rxjs';
import { Regex } from '@core/regex';
import { takeUntil } from 'rxjs/operators';
import { Helper } from '@core/helper';
import { CategoryBySubCategory, ServiceCategory, ServiceCategoryResponse, ServiceViewResponse } from '@core/interfaces/supplier-app-service-config';

 
 
@Component({
	selector: 'app-edit-view-service',
	templateUrl: './edit-service.component.html',
	styleUrls: ['./edit-service.component.scss'],
	providers: [HttpService, ServiceConfigs]
})
export class EditServiceComponent implements OnInit, OnDestroy {
	@ViewChild('fileUpload') fileUpload: ElementRef;
    private destroySubject: Subject<void> = new Subject();
	public serviceForm: UntypedFormGroup;
	public categoryList: ServiceCategory[];
	public subcategoryList: CategoryBySubCategory[];
	public serviceID: any;
	public attachment1: any;
	public isExistAttachDelete: boolean = false;

	public companyID: any;
	public userID: any;

	constructor(private serviceConfigs: ServiceConfigs,
		private httpService: HttpService,
		private toastr: ToastrServiceClass,
		private sessionService: SessionsService,
		private dialogRef: MatDialogRef<EditServiceComponent>,
		private dateRemover: CustomDateRemoverPipe,
		private smartDialog: SmartDialog,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) {	}

	// life cycle hooks

	ngOnInit() {
		this.serviceID = this.data.id;
		this.companyID = this.data.companyId;
		this.userID = this.data.userId;
		this.buildProductForm();
		this.getcategoryList();
		this.getByID();
	}
	ngOnDestroy() {
		this.destroySubject.next();
		this.destroySubject.complete();
	}

	// functions

	public buildProductForm() {
		this.serviceForm = new UntypedFormGroup({
			serviceName: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.alphaNumericPatternProduct)]),
			category: new UntypedFormControl('', [Validators.required]),
			subcategory: new UntypedFormControl('', [Validators.required]),
			description: new UntypedFormControl('', [Validators.required]),
			uploadAttachements: new UntypedFormControl('', [Validators.required]),
			hyperLink: new UntypedFormControl('', [Validators.pattern(Regex.websiteURLPatternMarketPlace)])
		});
	}

	public getByID() {
		const id = this.serviceID;
		if (id) {
			const requestObject = '?serviceId=' + id;
			this.httpService.invokeService(this.serviceConfigs.SERVICE_TAB_SERVICES_CONFIG.GETBYID_SERVICE_DATA, requestObject).then((response: ServiceViewResponse) => {
				if (response && response?.isError === false) {

					if (response?.payload?.categoryId !== undefined) {
						this.getsubCategoryListEdit(response?.payload?.categoryId);
					}
					this.serviceForm.patchValue({ 'serviceName': response?.payload?.serviceName });
					this.serviceForm.patchValue({ 'category': response?.payload?.categoryId });
					this.serviceForm.patchValue({ 'subcategory': response?.payload?.subCategoryId });
					this.serviceForm.patchValue({ 'description': response?.payload?.serviceDescription });
					this.serviceForm.patchValue({ 'uploadAttachements': response?.payload?.attachment1 });
					this.serviceForm.patchValue({ 'hyperLink': response?.payload?.url });
					this.attachment1 = response?.payload?.attachment1;

				} else if (response && response?.isError === true) {
					this.toastr.error(response.message);
				}
			});
		}
	}

	public getcategoryList() {
		const requestObject = '';
		this.httpService.invokeService(this.serviceConfigs.SERVICE_TAB_SERVICES_CONFIG.GET_SERVICE_CATEGORY, requestObject).then((response: ServiceCategoryResponse) => {
			if (response) {
				this.categoryList = response?.payload;
			}
		});
	}

	public getsubCategoryListEdit(id: any) {
		const requestObject = '?categoryId=' + id;
		this.httpService.invokeService(this.serviceConfigs.ADD_PRODUCT_SERVICES_CONFIG.GET_PRODUCTS_SUBCATEGORY, requestObject).then((response: any) => {
			if (response) {
				this.subcategoryList = response?.payload;
			}
		});
	}

	public getsubCategoryList(event: any) {
		const requestObject = '?categoryId=' + event.value;
		this.httpService.invokeService(this.serviceConfigs.ADD_PRODUCT_SERVICES_CONFIG.GET_PRODUCTS_SUBCATEGORY, requestObject).then((response: any) => {
			if (response) {
				this.subcategoryList = response?.payload;
				this.serviceForm.get('subcategory').setValue('');
			}
		});
	}

	public hasError = (controlName: string, errorName: string) => {
		return this.serviceForm.controls[controlName].hasError(errorName);
	}

	public UpdateService() {
		if (this.serviceForm.valid === true) {
			this.toastr.loaderShow();
			if (this.isExistAttachDelete === true) {
				this.hardDeleteFile(this.attachment1);
			}

			const request = {
				categoryId: this.serviceForm.value.category,
				subCategoryId: this.serviceForm.value.subcategory,
				serviceName: this.serviceForm.value.serviceName,
				url: this.serviceForm.value.hyperLink,
				attachment1: Helper.removeStringEmptySpace(this.serviceForm.value.uploadAttachements, false),
				serviceDescription: this.serviceForm.value.description,
				attachment2: '',
				attachment3: '',
				isActive: true,
				userId: this.userID,
				id: this.serviceID,
				companyId: this.companyID
			};
			 
			this.httpService.invokePutBody(this.serviceConfigs.SERVICE_TAB_SERVICES_CONFIG.UPDATE_SERVICE_DATA, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
				try {
					if (data) {
						if (data.body.isError === false) {
							this.toastr.success(data?.body?.message);
							//this.router.navigate(['/products-services/services']);
							this.dialogRef.close(true);
						} else {
							this.toastr.error(data?.body?.message);
						}
					}
					this.toastr.loaderHide();
				} catch (ex) {
					 
					this.toastr.loaderHide();
				}
			}, (err) => {
				  
				this.toastr.loaderHide();
			}, () => {
				 
				this.toastr.loaderHide();
			});
		} else {
			this.validateAllFormFields(this.serviceForm);
		}
	}

	public validateAllFormFields(formGroup: UntypedFormGroup) {
		this.toastr.info('Please fill all the Required fields.');
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof UntypedFormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof UntypedFormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}

	public AttachmentScannUplaod(event: any) {
		const input = event.target as HTMLInputElement;
		const files = input.files;
		const allowedExtensions = /(\.pdf|\.xls|\.xlsx)$/i;
		if (files) {
		for (let i = 0; i < files.length; i++) {
			if (!allowedExtensions.exec(files[i].name)) {
			this.toastr.error('Only .pdf , .xls and .xlsx supported !');
			input.value = '';
			return;
			}
		}
		}
		const file = event.target.files[0];
		if(!Helper.validateFilename(file.name)) {
			this.toastr.error("File name should not contain these special characters @$%&\/:?\"'<>|~`#^={}[];!");
			return;
		} else {
			if (file && file != null && file.size > 10615705) {
				this.toastr.info('The attachment file should not be more than 10 MB size');
				this.toastr.loaderHide();
				return false;
			}
			setTimeout(() => {
				this.toastr.loaderShow('Scanning in progress...');
			}, 100);
			this.toastr.loaderShow();
			const files = (this.fileUpload.nativeElement as HTMLInputElement).files;
			const formData = new FormData();
			formData.append('file', file, file.name);
			const requestFile = formData;
			this.httpService.invokeScanFileUpload(this.serviceConfigs.FILE_SCAN_SERVICES_CONFIG.UPLOAD, requestFile).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
				try {
					if (data && data?.type !== 0) {
						if (data.body.isError === false) {
							// this.AttachmentUplaod(event.target.files[0]);
							this.serviceForm.controls.uploadAttachements.setValue(file.name);
							this.attachment1 = file.name;
							this.saveScanAduit(data?.body?.message, file.name, 'File Scan');
						} else {
							this.toastr.error(data?.body?.message);
							this.saveScanAduit(data?.body?.message, file.name, 'File Scan');
						}
					}
					setTimeout(() => {
						this.toastr.loaderHide();
					}, 200);
				} catch (ex) {
					
					setTimeout(() => {
						this.toastr.loaderHide();
					}, 200);
				}
			}, (err) => {
				
				setTimeout(() => {
					this.toastr.loaderHide();
				}, 200);
			}, () => {
				
				setTimeout(() => {
					this.toastr.loaderHide();
				}, 200);
			});
			setTimeout(() => {
				this.toastr.loaderHide();
			}, 200);
		}
	}

	public AttachmentUplaod(data) {
		const files = (this.fileUpload.nativeElement as HTMLInputElement).files;
		const file = data;
		this.isExistAttachDelete = false;
		const formData = new FormData();
		formData.append('file', file, file.name);
		const requestFile = formData;
		this.httpService.invokeFileUploadForRegisterSupplierManager(this.serviceConfigs.SERVICE_TAB_SERVICES_CONFIG.SERVICE_UPLOAD, requestFile, this.companyID).subscribe((data: any) => {
			try {
				if (data && data?.type !== 0) {
					if (data.body.isError === false) {
						this.serviceForm.controls.uploadAttachements.setValue(data?.body?.fileName);
						this.attachment1 = data?.body?.fileName;
					} else {
						this.toastr.error(data?.body?.message);
					}
				}
				this.toastr.loaderHide();
			} catch (ex) {
				 
				this.toastr.loaderHide();
			}
		}, (err) => {
			  
			this.toastr.loaderHide();
		}, () => {
			 
			this.toastr.loaderHide();
		});
	}

	public confirmDelete() {
		this.smartDialog.open({
			title: 'Confirm delete',
			description: 'Sure you want to delete?',
			buttons: [
				{
					type: '',
					text: 'Cancel',
					onClick: () => {
						return;
					},
				},
				{
					type: 'primary',
					text: 'Delete',
					onClick: () => {
						let msg = "The following file deleted successfully!";
						this.saveScanAduit(msg, this.serviceForm.controls.uploadAttachements.value, 'File Delete');
						this.isExistAttachDelete = true;
						this.serviceForm.controls.uploadAttachements.setValue('');
					},
				}
			],
		});
	}

	public hardDeleteFile(fileName) {
		if (fileName.indexOf('000+0000/') > -1) {
			const requestObject = '?filename=' + encodeURIComponent(fileName) + '&companyId=' + this.companyID;
			this.httpService.invokeDelete(this.serviceConfigs.SERVICE_TAB_SERVICES_CONFIG.DELETE_SERVICE_DATA, requestObject).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
				try {
					if (data && data?.isError === false) {
						this.attachment1 = '';
					} else if (data?.message === true) {
						// this.toastr.error(data?.message);
					}
				} catch (ex) {
					this.toastr.error(ex);
				}
			});
		}
	}

	public downloadFile(fileName: any) {
		 
		this.toastr.loaderShow();
		const request = '?filename=' + encodeURIComponent(fileName) + '&companyId=' + this.companyID;
		this.httpService.invokePostWithParams(this.serviceConfigs.SERVICE_TAB_SERVICES_CONFIG.DOWNLOAD_FILE, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
			try {
				if (data) {
					if (data?.isError === false) {
						if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
							Helper.downloadIE(data?.payload, this.dateRemover.transform(fileName));
						} else {
							Helper.download(data?.payload, this.dateRemover.transform(fileName));
						}

					} else {
						this.toastr.error(data?.message);
					}
				}
				this.toastr.loaderHide();
			} catch (ex) {
				 
				this.toastr.loaderHide();
			}
		}, (err) => {
			  
			this.toastr.loaderHide();
		});
	}

	public dialogclose() {
		this.dialogRef.close(true);
	}

	public saveScanAduit(msg: any, fileName: any, type: any) {
		const request = {
			tableKey: this.sessionService.getUserId(),
			email: this.sessionService.getUserMailId(),
			description: msg,
			fileName: fileName,
			action: type
		}
		this.httpService.invokePost(this.serviceConfigs.FILE_SCAN_SERVICES_CONFIG.SCAN_AUDIT, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
			try {
				if (data) {
					if (data.body.isError === false) {
						this.toastr.success(data?.body?.message);
					} else {
						this.toastr.error(data?.body?.message);
					}
				}
				this.toastr.loaderHide();
			} catch (ex) {
				 
				this.toastr.loaderHide();
			}
		}, (err) => {
			  
			this.toastr.loaderHide();
		}, () => {
			 
			this.toastr.loaderHide();
		});

	}
}

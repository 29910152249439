<div class="app-sidenav-inner">
    <div class="m-t-24 m-l-24"
         fxLayout="row wrap"
         fxLayoutAlign="space-between center">
        <ng-container *ngFor="let item of menus">
            <div class="menu-container"
                 fxFlex.gt-sm="50"
                 fxFlex.gt-xs="50"
                 fxFlex="50"
                 *ngIf="isGaurd(item.role)">
                <span *ngIf="item.children && item.children.length > 0">
                    <mat-card appearance="outlined" class="text-center"
                              [popperTrigger]="'hover'"
                              (click)="changeMenu()"
                              [popperPlacement]="'right'"
                              [popperApplyClass]="'popper-container'"
                              [popperApplyArrowClass]="'popper-arrow'"
                              [popperPositionFixed]="true"
                              [popper]="childMenu"
                              [popperPreventOverflow]="false"
                              [popperHideOnScroll]="true"
                              routerLinkActive="active"
                              routerLinkActiveOptions="{ exact: true }"
                              [ngClass]="{'active': isActiveMenu(item.state) }"
                              [ngStyle]="{'pointer-events': item.status == 'true' ?'auto':'none'}">
                        <mat-card-content style="padding: 0px;">
                            <img src="assets/images/nav-icons/{{item.icon}}"
                                 class="svg">
                            <span class="title"> {{item.name}}</span>
                        </mat-card-content>
                    </mat-card>

                    <popper-content #childMenu>
                        <mat-list>
                            <span *ngFor="let child of item.children; let last = last;">
                                <mat-list-item routerLink="/{{child.state}}"
                                               class="cursor-pointer">
                                    <span>{{child.name}} </span>
                                </mat-list-item>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </span>
                        </mat-list>
                    </popper-content>
                </span>

                <span *ngIf="!item.children || item.children.length === 0">
                    <mat-card appearance="outlined" class="text-left"
                              routerLink="/{{item.state}}"
                              routerLinkActive="active"
                              (click)="changeMenu()"
                              [ngStyle]="{'pointer-events': item.status == 'true' ?'auto':'none'}">
                        <mat-card-content style="padding: 0px;">
                            <img src="assets/images/nav-icons/{{item.icon}}"
                                 [ngClass]="{'qaleft': item.name=='QA Documents' }"
                                 class="svg">
                            <span class="title"> {{item.name}} </span>
                        </mat-card-content>
                    </mat-card>
                </span>
            </div>
        </ng-container>
    </div>
</div>
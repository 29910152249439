import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import * as screenfull from 'screenfull';
import {animate, state, style, transition, trigger} from '@angular/animations';

 
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
   animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class HeaderComponent implements OnInit {
  public expanded!: boolean;
  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() toggleSidenavNotice = new EventEmitter<void>();
  @Input() showToggle: boolean = true;
  @Input() showBranding: boolean = false;
  @Input() showUser: boolean = true;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  private get screenfull(): screenfull.Screenfull {
    return screenfull as screenfull.Screenfull;
  }

  constructor() {}

  // life cycle hooks

  ngOnInit() {this.ariaExpanded = this.expanded;}

  // functions

  // TODO:
  toggleFullscreen() {
    if (this.screenfull.enabled) {
      this.screenfull.toggle();
    }
  }
  toggleCollapsedExpanded(){
    this.expanded = !this.expanded;
  }
}


<div>
    <h2 mat-dialog-title>Sold Products  <span><mat-icon (click)="dialogclose()" class="float-right cursor-pointer icon-18">close</mat-icon> </span> </h2>
  </div>
  <mat-dialog-content class="mat-typography view-rfq-respond add-product">
      <div  class="w-full" fxFlex="100"  fxLayout="row wrap">
        <div fxLayout="row" fxFlex="100" >
            <smart-table class="table-card"
                   [data]="listSupplier"
                   [columns]="supplierColumns"
                   [front]="false"
                   [length]="total"
                   [showPager]="false"
                   [pageSizeOptions]="[5,10,20,50]"
                   [pageIndex]="query.page"
                   [pageSize]="query.size"
                  >
            </smart-table>  
            
            </div>
      </div>  
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button class="cancel-button cursor-pointer m-r-8" *ngIf="listSupplier.length > 0" (click)="ExportToExcel()" >Export into Excel</button>

      <button class="m-r-8 cancel-button" (click)="dialogclose()" mat-stroked-button > Cancel </button>

  </mat-dialog-actions>
  
  
  
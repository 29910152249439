<div class="app-content-inner">

<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1%">
  <div class=" dash-card" fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="99">
        <mat-card appearance="outlined" class="cursor-pointer" [routerLink]="'/admin/supplier-mgmt/approved'">
          <mat-card-header class="f-s-12 f-w-100">{{'suppliers-in-system' | translate }}
            <mat-icon class="icon-right icon-18">arrow_forward</mat-icon>
          </mat-card-header >
          <mat-card-content class="text-center">
              <img class="card-left-logo" alt="" src="assets/images/icons/supplier-management.svg">
            <span style="font-weight: 600;font-size: 48px; line-height: 65px;">{{supplierCount}}</span>
            <div style="font-weight: 600;font-size: 14px;line-height: 19px;padding-bottom: 16px;" >{{'suppliers-in-system' | translate }}</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class=" dash-card" fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="99">
        <mat-card appearance="outlined" class="cursor-pointer" [routerLink]="'/admin/tender-details/list'">
          <mat-card-header class="f-s-12 f-w-100 ">{{'admin-tender-count' | translate }}
            <mat-icon class="icon-right icon-18" >arrow_forward</mat-icon>
          </mat-card-header >
          <mat-card-content class="text-center ">
              <img class="card-left-logo" alt="" src="assets/images/icons/open-tenders-rfp.svg">
            <span style="font-weight: 600;font-size: 48px; line-height: 65px;">{{tenderCount}}</span>
            <div style="font-weight: 600;font-size: 14px;line-height: 19px;padding-bottom: 16px;" >{{'admin-tender-count-sub' | translate }}</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class=" dash-card" fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="99">
        <mat-card appearance="outlined" class="cursor-pointer" [routerLink]="'/admin/new-request/request'" >
          <mat-card-header class="f-s-12 f-w-100">{{'new-supplier-request' | translate }}
            <mat-icon class="icon-right icon-18" >arrow_forward</mat-icon>
          </mat-card-header >
          <mat-card-content class="text-center">
              <img class="card-left-logo" alt="" src="assets/images/icons/supplier-management.svg">
            <span style="font-weight: 600;font-size: 48px; line-height: 65px;">{{newSuppliers}}</span>
            <div style="font-weight: 600;font-size: 14px;line-height: 19px;padding-bottom: 16px;" >{{'new-supplier-request' | translate }}</div>
          </mat-card-content>
        </mat-card>
      </div>
      <!--manager-->
      <div *ngIf="currentUserType == 0" class=" dash-card" fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="99">
        <mat-card appearance="outlined" class="cursor-pointer" [routerLink]="'/admin/market-place/product-sale'" >
          <mat-card-header class="f-s-12 f-w-100">Products on Sale
            <mat-icon class="icon-right icon-18" >arrow_forward</mat-icon>
          </mat-card-header >
          <mat-card-content class="text-center">
              <img class="card-left-logo" alt="" src="assets/images/nav-icons/resource-centre-mgnt.svg">
            <span style="font-weight: 600;font-size: 48px; line-height: 65px;">{{allProductsSaleCount}}</span>
            <div style="font-weight: 600;font-size: 14px;line-height: 19px;padding-bottom: 16px;" >Products on Sale</div>
          </mat-card-content>
        </mat-card>
      </div>
     <!--super manager-->
      <div *ngIf="currentUserType == 4" class=" dash-card" fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="99">
          <mat-card appearance="outlined" class="cursor-pointer" [routerLink]="'/admin/market-place/product-sale'" >
            <mat-card-header class="f-s-12 f-w-100">Products on Sale
              <mat-icon class="icon-right icon-18" >arrow_forward</mat-icon>
            </mat-card-header >
            <mat-card-content class="text-center">
                <img class="card-left-logo" alt="" src="assets/images/nav-icons/resource-centre-mgnt.svg">
              <span style="font-weight: 600;font-size: 48px; line-height: 65px;">{{allProductsSaleCount}}</span>
              <div style="font-weight: 600;font-size: 14px;line-height: 19px;padding-bottom: 16px;" >Products on Sale</div>
            </mat-card-content>
          </mat-card>
        </div>
</div>
<div fxLayout="row wrap" >
    <div fxFlex="100" 
     style="height:400px; background: #ffffff; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); border-radius: 10px;padding-bottom: 24px;">
        <div class="f-s-14 f-w-600 m-24">{{'to-do-list' | translate }}</div>
        <smart-table class="table-card p-l-24 p-r-24"
              [data]="dashList"
              [columns]="dashColumns"
              [loading]="isLoading"
              [front]="false"
              [showPager]="false"
              (changeSort)="changeSort($event)">
        </smart-table>
        <div  class="text-center m-t-48" *ngIf="dashList.length === 0">
          <span class="no-data-text">No To-Do items...</span> 
       </div> 
    </div>
</div>
</div>
export class Helper {

    // remove space in a string
    static removeStringEmptySpace(data: any, isArray: boolean) {
        if (isArray) {
            return data.map(item => item.replace(/\s/g, ""))
        } else {
            return data.replace(/\s/g, "");
        }
    }

    // download pdf
    static download(data: any, fileName: any) {
        const content: any = encodeURIComponent(data);
        let uriContent: any;
        uriContent = 'data:application/octet-stream;base64,' + content;
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.href = uriContent;
        a.download = fileName;
        a.click();
    }

    static downloadUri(data: any) {
        const content: any = encodeURIComponent(data);
        let uriContent: any;
        uriContent = 'data:application/octet-stream;base64,' + content;
        return uriContent;
    }

    static downloadIE(data: any, fileName: any) {
        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
        };
        (window.navigator as any).msSaveOrOpenBlob(
            b64toBlob(data, 'application/octet-stream'),
            fileName
        );
    }
    
    static downloadIEUri(data: any) {
        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
        }

        const blob = b64toBlob(data, 'application/octet-stream');
        return blob;
    }

    static validateFilename(fileName: string) {
        const regex = /^[^@$%&\/:?"'<>|~`#^={}[\];!]+$/;
        return regex.test(fileName);
    }
}
import { Component, OnInit, Optional, Inject, Directive } from '@angular/core';
import { ServiceConfigs, HttpService } from '@core';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SecondarySupplierResponse } from '@core/interfaces/user';



@Component({
  selector: 'app-view-secondary-supplier',
  templateUrl: './view-secondary-supplier.component.html',
  styleUrls: ['./view-secondary-supplier.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class ViewSecondarySupplierComponent implements OnInit {
  service: any = {};
  supplierId: any = '';
  public id: any = '';
  constructor(
    private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
    private toastr: ToastrServiceClass,
    private dialogRef: MatDialogRef<ViewSecondarySupplierComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.supplierId = data.userId;
    this.id = data.id;
  }

  // life cycle hooks

  ngOnInit() {
    this.getServiceByID();
  }

  // functions

  public getServiceByID() {
    const id = this.supplierId;
    if (id) {
      const request = '?userId=' + this.supplierId;
      this.toastr.loaderShow();
      this.httpService.invokeService(this.serviceConfigs.PROFILE_SERVICES.GETBYID_SECONDRY_DATA, request).then((data: SecondarySupplierResponse) => {
        try {
          if (data && data?.isError === false) {
            // succeeded
            this.service = data?.payload;
          } else if (data && data?.isError === true) {
            this.toastr.error(data?.message);
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      });
    }
  }

  public dialogclose() {
    this.dialogRef.close();
  }
}

import { Pipe, PipeTransform , OnDestroy } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, regexValue: string, replaceValue: string): any {

    let regex = new RegExp(regexValue, 'g');
    if( replaceValue ! == null ) {
      return value.replace(regex, replaceValue);
    } else {
      return value;
    }
  }


}
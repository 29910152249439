
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { SessionsService } from './sessions.service';

const httpOptions = {
  headers: new HttpHeaders({
  })
};

const httpUploadOptions = {
  headers: new HttpHeaders({
    accept: 'application/json'
  })
};
/*
 Generated class for the HttpService provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable()
export class HttpService {

  isMock: boolean = environment.isMock;
  BASE_URL: string = environment.baseUrl;
  loader: any;
  serviceConfig: any;
  apiParentNodeName: string = 'DataResponse';
  responseProperty: string = 'dataList';
  client_id: string = 'ste';
  client_secret: string = 'ste-secret';


  constructor(private httpClient: HttpClient,
              private sessionsService: SessionsService) { }

  /**
   * Post http service using HttpClient.
   * Subscribe the current observer as observable.
   * Evaluate bind callback function.
   * Handle error callback if any.
   * @param {string} url
   * @param body
   */
  post(url: string, body?: any) {
    return this.httpClient.post(url, body, httpOptions).toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((response: any) => {
        return response;
      });
  }

  /**
   * Get http service using HttpClient.
   * Subscribe the current observer as observable.
   * Evaluate bind callback function.
   * Handle errorCallback if any.
   * @param {string} url
   */
  get(url: string) {
    return this.httpClient.get(url, httpOptions)
      .toPromise()
      .then((response: any) => {
        return this.populateResponse(response);
      })
      .catch((response: any) => {
        return this.populateResponse(response);
      });

  }


  /**
   * Get http service using HttpClient.
   * Subscribe the current observer as observable.
   * Evaluate bind callback function.
   * Handle errorCallback if any.
   * @param {string} url
   */
  delete(url: string) {
    this.httpClient.delete(url, httpOptions).subscribe(response => {
      const resValue: any = response;
      this.populateResponse(resValue);
    }, error => {

      // if (this.errorCallback != null) {
      //   // return this.errorCallback(error);
      // }
    });
  }
  /**
   * General invocation function as globally.
   * Assign serviceConfigs, request, callback & errorCallback.
   * Generate base_uri & form the http urls with http methods.
   * @param config
   * @param request
   * @param callback
   * @param errorCallback
   * @returns {Observable<any>}
   */
  invokeService(config: any, request: any) {
    let api;
    if (config.isMock && config.isMock === true) {
      this.isMock = true;
    } else {
      this.isMock = false;
    }
    this.serviceConfig = config;
    if (this.isMock) {
      return this.get(config.mockUrl);
    }
    this.changeBaseUri(config);

    if (config.reqType.toLowerCase() === 'get') {
      if (typeof request === 'object') {
        api = this.BASE_URL + config.url;
      } else if (request !== '') {
        api = this.BASE_URL + config.url + request;
      } else {
        api = this.BASE_URL + config.url;
      }
      return this.get(api);
    } else if (config.reqType.toLowerCase() === 'post') {
      if (request !== '') {
        // this.post(this.BASE_URI + config.url, this.populateRequest(config.reqType, config.reqWrapper, request));
        return this.post(this.BASE_URL + config.url, request);
      } else {
        return this.post(this.BASE_URL + config.url);
      }
    } else if (config.reqType.toLowerCase() === 'delete') {
      if (request !== '') {
        this.delete(this.BASE_URL + config.url + request);
      } else {
        this.delete(this.BASE_URL + config.url);
      }
    }

  }

  populateRequest(reqType: string, reqWrapper: string, request: any) {
    if (reqWrapper && reqWrapper.toLowerCase() === 'plain') {
      return request;
    }

    if (!request.returnContentType) {
      request.returnContentType = 'json';
    }

    let dataRequest;
    if (reqType.toLowerCase() === 'get') {
      dataRequest = encodeURIComponent('{"DataRequest":' + JSON.stringify(request) + '}');
    } else {
      dataRequest = '{"DataRequest":' + JSON.stringify(request) + '}';
    }
    return 'DataRequest=' + dataRequest;
  }

  changeBaseUri(config: any) {
    this.BASE_URL = environment.baseUrl;
  }

  populateResponse(resValue: any) {

    if (resValue && resValue !== '') {
      if (resValue.isError === false) {
        if (resValue.payload) {
          return resValue;
        }
      } else {
        return resValue;
      }

    }

  }
  /*
   * file upload
  */
 invokeFileUpload(config: any, request: any) {
  let api = this.BASE_URL + config.url;
  const userType = this.sessionsService.getUserType();
  if (userType === '1'  || userType === '2' ) {
      api = api + '?companyId=' + this.sessionsService.getCompanyUniqueId();
    } else if (userType === '0') {
       api = api;
    } else {
      api = api + '?userId=' + this.sessionsService.getUserId();
    }
  return this.httpClient.post(api, request, {observe: 'events', headers: httpUploadOptions.headers}).pipe(
      catchError(this.handleError)
    );
  }
  // Scan Product and Service RegisterSupplierManager
  invokeFileUploadForRegisterSupplierManager(config: any, request: any,companyId:any) {
    let api = this.BASE_URL + config.url;
    const userType = this.sessionsService.getUserType();
    api = api + '?companyId=' + companyId;
    return this.httpClient.post(api, request, {observe: 'events', headers: httpUploadOptions.headers}).pipe(
        catchError(this.handleError)
      );
    }

  invokeFileUploadSupplier(config: any, request: any , id: any) {
    let api = this.BASE_URL + config.url;
    api = api + '?companyId=' + this.sessionsService.getCompanyUniqueId() + '&tenderId=' + id;
    return this.httpClient.post(api, request, {observe: 'events', headers: httpUploadOptions.headers}).pipe(
      catchError(this.handleError)
    );
  }

 invokeFileUploadInfoCenter(config: any, request: any, id: any) {
  const api = this.BASE_URL + config.url + '?id=' + id;
  return this.httpClient.post(api, request, {observe: 'events', headers: httpUploadOptions.headers}).pipe(
      catchError(this.handleError)
    );
  }
  invokeTenderFileUpload(config: any, request: any) {
    const api = this.BASE_URL + config.url + '?companyId=' + this.sessionsService.getUserId();
    return this.httpClient.post(api, request, {observe: 'events', headers: httpUploadOptions.headers}).pipe(
        catchError(this.handleError)
      );
    }
    invokeMessageFileUpload(config: any, request: any, id: any) {
      const api = this.BASE_URL + config.url + '?msgId=' + id;
      return this.httpClient.post(api, request, {observe: 'events', headers: httpUploadOptions.headers}).pipe(
          catchError(this.handleError)
        );
    }
   /*
   * file upload
  */
 invokeFileUploadID(config: any, request: any, id: any) {
  const api = this.BASE_URL + config.url + '?userId=' + id;
  return this.httpClient.post(api, request, {observe: 'events', headers: httpUploadOptions.headers}).pipe(
      catchError(this.handleError)
    );
  }

  invokeFileUploadIDProfile(config: any, request: any, id: any, file: string) {
    const api = this.BASE_URL + config.url + '?userId=' + id + '&fileName=' + file;
    return this.httpClient.post(api, request, {observe: 'events', headers: httpUploadOptions.headers}).pipe(
        catchError(this.handleError)
      );
    }

  invokeFileUploadPut(config: any, request: any) {
    let api = this.BASE_URL + config.url;
    if (this.sessionsService.getUserId()) {
        api = api + '?userId=' + this.sessionsService.getUserId();
      }
    return this.httpClient.put(api, request, {observe: 'events', headers: httpUploadOptions.headers}).pipe(
        catchError(this.handleError)
      );
    }
    // scan file
    invokeScanFileUpload(config: any, request: any) {
      let api =  config.url + '?userId=' + this.sessionsService.getUserId() + '&email=' + this.sessionsService.getUserMailId();
      let userType= this.sessionsService.getUserType();
      // if (userType == '1') {
      //     api = api + '?companyId=' + this.sessionsService.getCompanyUniqueId();
      //   } else {
      //     api = api + '?userId=' + this.sessionsService.getUserId();
      //   }
      return this.httpClient.put(api, request, {observe: 'events', headers: httpUploadOptions.headers}).pipe(
          catchError(this.handleError)
        );
      }

    //Register Supplier scan file
    invokeScanFileUploadRegisterSupplier(config: any, request: any, email:any) {
      let api =  config.url + '?userId=' + '' + '&email=' + email;
      let userType= this.sessionsService.getUserType();
      return this.httpClient.post(api, request, {observe: 'events', headers: httpUploadOptions.headers}).pipe(
          catchError(this.handleError)
        );
      }

  invokePost(config: any, request: any) {
    return this.httpClient.post(this.BASE_URL + config.url, request, { observe: 'response'}).pipe(
      catchError(this.handleError)
    );
  }
  invokePostWithParams(config: any, request: any) {
    const api = this.BASE_URL + config.url + request;
    return this.httpClient.post(api, { observe: 'response' }).pipe(
      catchError(this.handleError)
    );
  }
  invokePostWithParamsAndBody(config: any, request: any, bodyContent: any) {
    const api = this.BASE_URL + config.url + request;
    return this.httpClient.post(api, bodyContent, { observe: 'response' }).pipe(
      catchError(this.handleError)
    );
  }
  invokeGet(config: any, request: any) {
    const api = this.BASE_URL + config.url + request;
    return this.httpClient.get(api).pipe(
      // map(this.extractData),
      catchError(this.handleError)
    );
  }
  invokePut(config: any, request: any) {
    const api = this.BASE_URL + config.url + request;
    return this.httpClient.put(api, '').pipe(
      catchError(this.handleError)
    );
  }
  invokePutBody(config: any, request: any) {
    return this.httpClient.put(this.BASE_URL + config.url, request, { observe: 'response' }).pipe(
      catchError(this.handleError)
    );
  }

  invokeDelete(config: any, request: any) {
    const api = this.BASE_URL + config.url + request;
    return this.httpClient.delete(api).pipe(
      catchError(this.handleError)
    );
  }
  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const err = error || '';
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    if (error.status === 401) {

    }
    if (error.status === 503) {
      errMsg = 'We are currently undergoing maintenance. this won\'t take long';
      return observableThrowError(error.status);
    }
    return observableThrowError(errMsg);
  }

}

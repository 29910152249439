<h2 mat-dialog-title>View Secondary Supplier Information </h2>
<mat-dialog-content class="mat-typography">
    <div fxLayout="row wrap"
         fxLayoutAlign="space-around center"
         fxLayoutGap="16px grid">
        <div fxFlex="50"
             fxFlex.lt-sm="100">
            <div class="text-grey-500">Name</div>
            <div class="text-grey-900"> {{service.userName}} </div>
        </div>

        <div fxFlex="50"
             fxFlex.lt-sm="100">
            <div class="text-grey-500">Email Address</div>
            <div class="text-grey-900"
                 *ngIf="data.isFrom == 'edit'">
                <form novalidate
                      [formGroup]="supplierForm"
                      class="app-form-horizontal">
                    <mat-form-field class="form-field-style"
                                    appearance="outline">
                        <input matInput
                               maxlength="50"
                               email
                               formControlName="email">
                        <mat-error *ngIf="hasError('email', 'required')">{{ 'email-address-req' | translate}}
                        </mat-error>
                        <mat-error *ngIf="hasError('email', 'email')">{{ 'email-address-invalid' | translate}}
                        </mat-error>
                    </mat-form-field>
                </form>
            </div>
            <div class="text-grey-900"
                 *ngIf="data.isFrom != 'edit'">
                {{service.email}}
            </div>
        </div>
        <div fxFlex="100"
             fxFlex.lt-sm="100">
            <div class="text-grey-500">Business Contact Number </div>
            <div class="text-grey-900"> {{service.contactNumber}}</div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="m-r-8 cancel-button"
            (click)="dialogclose()"
            mat-stroked-button>
        Cancel
    </button>
    <button class="m-r-8 action-button"
            *ngIf="data.isFrom != 'edit'"
            (click)="rejectClick()"
            mat-stroked-button>
        Reject
    </button>
    <button class="m-r-8 action-button"
            (click)="approveClick()"
            mat-stroked-button>
        <span *ngIf="data.isFrom != 'edit'">Approve</span>
        <span *ngIf="data.isFrom == 'edit'">Update</span>
    </button>
</mat-dialog-actions>
<!-- Editable Cell -->
<ng-container *ngIf="cell.edit; else uneditTpl"></ng-container>

<!-- Uneditable Cell -->
<ng-template #uneditTpl>
  <div [ngSwitch]="cell.type">
    <!-- Tag -->
    <ng-container *ngSwitchCase="'tag'">
      <mat-chip-listbox *ngIf="cell.tag[cellValue]; else tagEmptyTpl">
        <mat-chip-option color="primary" [ngClass]="['bg-' + cell.tag[cellValue].color]">
          {{cell.tag[cellValue].text}}
        </mat-chip-option>
      </mat-chip-listbox>
      <ng-template #tagEmptyTpl>{{cellValue}}</ng-template>
    </ng-container>
    <!-- Image -->
    <ng-container *ngSwitchCase="'img'">
      <img class="img" [src]="cellValue" (click)="preview(cellValue)">
    </ng-container>
    <!-- Link -->
    <ng-container *ngSwitchCase="'link'">
      <a style="color:red; text-decoration: underline;" href="https://{{data.websiteURL}}" target="_blank">{{cellValue}}</a>
    </ng-container>
    <ng-container *ngSwitchCase="'icon'">
      <mat-icon [style.color] = "data.color ? data.color : 'text-grey-600'" class="icon-18" >flag</mat-icon>
    </ng-container>
    <ng-container *ngSwitchCase="'awardedIcon'">
      <mat-icon *ngIf="data.status === 'Awarded'" style= "color: green;" class="icon-18" >flag</mat-icon>
    </ng-container>
    <ng-container *ngSwitchCase="'unreadMessageBuyer'">
      <mat-icon *ngIf="data.buyerUnRead" class="icon-18" >flag</mat-icon>
    </ng-container>
    <ng-container *ngSwitchCase="'unreadMessageSupplier'">
      <mat-icon *ngIf="data.supplierUnRead" class="icon-18" >flag</mat-icon>
    </ng-container>
    <!-- Buttons -->
    
    <ng-container *ngSwitchCase="'button'">
      <ng-container *ngFor="let btn of cell.buttons; index as i; last as isLast">
        <ng-container *ngIf="!btn.iif || btn.iif(data)">
          <button *ngIf="btn.pop; else btnDefaultTpl"
                  mat-icon-button
                  [color]="btn.color || 'primary'"
                  [matTooltip]="btn.tooltip"
                  (click)="confirm(btn.popTitle, btn.click, data)">
            <mat-icon class="icon-18">{{btn.icon}}</mat-icon>
            {{btn.text}} 
          </button>
          <ng-template #btnDefaultTpl>
            <button *ngIf="btn.icon == ''" style="border: none;background-color: #fff; color: #E30613;font-size: 17px;font-weight: 600;cursor: pointer;" [color]="btn.color || 'primary'"
            [matTooltip]="btn.tooltip"
            (click)="fnCall(btn.click, data)">{{btn.text}}</button>
            <button class="icon-btn" *ngIf="btn.icon != ''" mat-icon-button
                    [color]="btn.color || 'primary'"
                    [matTooltip]="btn.tooltip"
                    (click)="fnCall(btn.click, data)">
              <mat-icon class="icon-18">{{btn.icon}}</mat-icon>
              {{btn.text}}
            </button>
          </ng-template>
        </ng-container>
        
        {{isLast !== true ? '&nbsp;' : '' }}
      </ng-container>
    </ng-container>
    <!-- Format -->
    <ng-container *ngSwitchCase="'format'">
      <span [innerHTML]="fnCall(cell.format, data) || '--'"></span>
    </ng-container>
    <!-- Number -->
    <ng-container *ngSwitchCase="'number'">
      <!-- {{(cellValue | number:'1.2-2') || '--'}} -->
      <!-- {{(cellValue | numberFixed) || '--'}} -->
    </ng-container>
    <!-- Currency -->
    <ng-container *ngSwitchCase="'currency'">
      <!-- {{(cellValue | currency:'¥') || '--'}} -->
      <!-- {{(cellValue | numberFixed | numberFormat:cell.type) || '--'}} -->
    </ng-container>
    <!-- Percent -->
    <ng-container *ngSwitchCase="'percent'">
      <!-- {{(cellValue | percent) || '--'}} -->
      <!-- {{(cellValue | numberFixed | numberFormat:cell.type) || '--'}} -->
    </ng-container>
    <!-- Percent -->
    <ng-container *ngSwitchCase="'rating'">
      <mat-icon class="icon-12 tender-open" >star</mat-icon> {{ cellValue|| '--'}} 
    </ng-container>
    <!-- Default -->
    <ng-container *ngSwitchDefault>
      {{cellValue || '-'}}
    </ng-container>
  </div>
</ng-template>

import { Component } from '@angular/core';

@Component({
  selector: 'app-branding',
  template: `
    <a class="app-branding"  href="javascript:void(0)" >
      <!-- <img src="./assets/images/branding-logo.png" class="app-branding-logo-expanded" alt="" /> -->
       <img src="./assets/images/st-engineering-vector-logo.svg" class="app-branding-logo-expanded" alt="" />
      <!-- <span class="app-branding-name">ST Engineering</span> -->
    </a>
  `,
})
export class BrandingComponent {}

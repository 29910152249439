import { Component, OnInit, Inject, Optional } from '@angular/core';
import { ServiceConfigs, HttpService } from '@core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';

 
 
@Component({
	selector: 'app-category-popup-view',
	templateUrl: './category-view.component.html',
	styleUrls: ['./category-view.component.scss'],
	providers: [HttpService, ServiceConfigs]
})
export class CategoryListViewPopupComponent implements OnInit {
	public popupData: any;
	public productForm: UntypedFormGroup;
	public CategoryList1:any = [];
	public CategoryList2:any = [];

	constructor(
		private dialogRef: MatDialogRef<CategoryListViewPopupComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) { }

	// life cycle hooks

	ngOnInit() {
		this.popupData = this.data;
		if(this.popupData.length > 0){
			this.CategoryList1 = this.popupData[0].category1;
			this.CategoryList2 = this.popupData[0].category2;
		}
	}

	// functions

	public dialogclose() {
		this.dialogRef.close();
	}

}

<!-- Progress Bar-->
<!-- <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar> -->
<!-- Main Table -->
<div class="app-smart-table-content">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="handleSortChange($event)">

    <ng-container *ngFor="let col of columns; let c=index">
      <ng-container [matColumnDef]="col.index"
                    [sticky]="col.fixed==='left'"
                    [stickyEnd]="col.fixed==='right'">

        <th mat-header-cell
            *matHeaderCellDef
            [ngClass]="{'mat-table-sticky-left':col.fixed==='left','mat-table-sticky-right':col.fixed==='right'}"
            [ngStyle]="{'width':col.width,'min-width':col.width}"
            mat-sort-header [disabled]="!col.sort">

          <mat-checkbox *ngIf="col.type==='checkbox'"
                        (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>

          <span *ngIf="col.type!=='checkbox'">{{col.title}}</span>

        </th>
        <td mat-cell
            *matCellDef="let row; let r=index"
            [ngClass]="{'mat-table-sticky-left':col.fixed==='left','mat-table-sticky-right':col.fixed==='right'}"
            [ngStyle]="{'width':col.width,'min-width':col.width}">

          <mat-checkbox *ngIf="col.type==='checkbox'"
                        (click)="$event.stopPropagation()"
                        (change)="$event ? singleToggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>

          <smart-table-cell *ngIf="col.type!=='checkbox'"
                           [data]="row"
                           [cell]="col">
          </smart-table-cell>

        </td>

      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>



<!-- Paginator -->
<mat-paginator [showFirstLastButtons]="showFirstLastButtons"
               [length]="length"
               [pageIndex]="pageIndex"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions"
               [hidePageSize]="hidePageSize"
               (page)="page.emit($event)"
               [disabled]="pageDisabled"
               [ngStyle]="{display: showPager ? 'block' : 'none'}">
</mat-paginator>

import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Registerinterface } from './registerInterface';
import { industryLengthValidator, MustMatch } from '@shared';
import { HttpService, ServiceConfigs } from '@core';
import { Router } from '@angular/router';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { MessageService } from '@core/services/message.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Regex } from '@core/regex';
import { Helper } from '@core/helper';
import { Country, IndustryType, IndustryTypeList } from '@core/interfaces/user';



@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [HttpService, ServiceConfigs]
})

export class RegisterComponent implements OnInit, OnDestroy {
  @ViewChild('fileUpload') fileUpload: ElementRef;
  @Output() click: EventEmitter<MouseEvent>;
  protected _onDestroy = new Subject<void>();
  /** control for the MatSelect filter keyword */
  public countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public industryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public subIndustryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredCountries: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredIndustryTypes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredSubIndustryTypes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public registerForm1: UntypedFormGroup;
  public registerForm2: UntypedFormGroup;
  public registerVariables: Registerinterface;
  public industryTypeList: IndustryType[];
  public subIndustryTypeList: any;
  public countryList: Country[];
  public isdcode: any;
  public isdlength: any;
  public generatedID: any = '';
  public registerFlag: boolean = false;
  public registerSaving: boolean = false;
  public sBrowser: string;
  public isIndustryTypeDisabled: boolean = false;
  constructor(private formBuilder: UntypedFormBuilder, private messageService: MessageService,
    private toastr: ToastrServiceClass, private router: Router, private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
  ) { }

  // life cycle hooks

  ngOnInit() {
    this.registerVariables = {
      firstPageFlag: true,
      secondPageFlag: false
    };
    this.detectModeOfAccess();
    this.getCountyList();
    this.getIndustryType();
    this.buildRegisterForm();
    // listen for state search field value changes
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountry();
      });
    this.industryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterIndustryType();
      });
    this.subIndustryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSubIndustryType();
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  // functions

  public buildRegisterForm() {
    let restrictFirstSpecialCharAllowEnter = new RegExp('^[a-zA-Z0-9]+.*$', 'm');
    this.registerForm1 = this.formBuilder.group({
      // userName: new FormControl('', [Validators.required, Validators.pattern(Regex.usernamePattern)]),
      userName: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.restrictFirstSpecialChar)]),
      nameOfTheCompany: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.restrictFirstSpecialChar)]),
      email: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl(null, [Validators.required, Validators.pattern(Regex.passwordPattern)]),
      confirmPassword: new UntypedFormControl(null, [Validators.required]),
      contactNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.contactNumberPattern)]),
      subIndustryId: new UntypedFormControl('', []),
      industryTypesList: new UntypedFormControl([], [industryLengthValidator()]),
      countryId: new UntypedFormControl('', [Validators.required]),
      didNumber: new UntypedFormControl('', [Validators.pattern(Regex.faxNumberPattern)]),
      extension: new UntypedFormControl('', [Validators.pattern(Regex.faxNumberPattern)])
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    this.registerForm2 = this.formBuilder.group({
      officeAddressLine1: new UntypedFormControl('', [Validators.required, Validators.pattern(restrictFirstSpecialCharAllowEnter)]),
      officeAddressLine2: new UntypedFormControl('', []),
      officepostalCode: new UntypedFormControl('', [Validators.pattern(Regex.alphaNumericPatternRegister)]),
      manufacturingAddressLine1: new UntypedFormControl('', [Validators.pattern(Regex.restrictFirstSpecialChar)]),
      manufacturingAddressLine2: new UntypedFormControl('', []),
      manufacturingpostalCode: new UntypedFormControl('', [Validators.pattern(Regex.alphaNumericPatternRegister)]),
      businessRegistrationNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.restrictFirstSpecialChar)]),
      websiteUrl: new UntypedFormControl('', [Validators.pattern(Regex.websiteURLPatternSupplierMgmt)]),
      attachmentFile: new UntypedFormControl('', []),
      description: new UntypedFormControl('', [Validators.required]),
    });
  }

  public selectUptoThree() {
    if (this.registerForm1.value.industryTypesList.length > 3) {
      return false;
    } else {
      return true;
    }
  }

  public validateEmailID(event: any) {
    if (event.target.value) {
      const requestObject = '?email=' + event.target.value;
      this.httpService.invokeService(this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.VALIDATE_EMAILID, requestObject).then((response: any) => {
        if (response) {
          if (response?.isError === true) {
            this.toastr.error(response.message);
          }
        }
      });
    }
  }

  public validateBusinessRegistrationNumber(event: any) {
    if (event.target.value) {
      const requestObject = '?businessRegistrationNumber=' + event.target.value;
      this.httpService.invokeService(this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.VALIDATE_BUSINESS_REGISTRATION_NUMBER, requestObject).then((response: any) => {
        if (response) {
          if (response?.isError === true) {
            this.toastr.error(response.message);
          }
        }
      });
    }
  }

  public getIndustryType() {
    const requestObject = '';
    this.httpService.invokeService(this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.GET_INDUSTRY_TYPE, requestObject).then((response: IndustryTypeList) => {
      if (response) {
        this.industryTypeList = response?.payload;
        this.filteredIndustryTypes.next(this.industryTypeList.slice());
      }
    });
  }

  public getSubIndustryType(event: any) {
    if (event.value.length >= 3) {
      this.isIndustryTypeDisabled = true;
    } else {
      this.isIndustryTypeDisabled = false;
    }
    // if (event.value && event.value) {
    //   const requestObject = '?industryId=' + event.value;
    //   this.httpService.invokeService(this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.GET_SUB_INDUSTRY_TYPE, requestObject).then((response: any) => {
    //     if (response && response?.payload) {
    //       this.subIndustryTypeList = response?.payload;
    //       this.filteredSubIndustryTypes.next(this.subIndustryTypeList.slice());
    //     }
    //   });
    // }
  }

  public getCountyList() {
    const requestObject = '';
    this.httpService.invokeService(this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.GET_COUNTRY_TYPE, requestObject).then((response: any) => {
      if (response && response?.payload) {
        this.countryList = response?.payload;
        this.filteredCountries.next(this.countryList.slice());
      }
    });
  }

  public getCountryObject(event) {
    const selectedObj = this.countryList.filter(item => item.id === event.value)[0];
    this.registerForm1.patchValue({ contactNumber: '' });
    this.isdcode = selectedObj.isdcode;
    if (selectedObj.noOfDigits && selectedObj.noOfDigits > 0) {
      this.isdlength = selectedObj.noOfDigits;
    } else {
      this.isdlength = 20;
    }
    this.registerForm1.controls['contactNumber'].setValidators([Validators.required, Validators.pattern('[+0-9-]+'), Validators.minLength(this.isdlength)]);
    // if (selectedObj.countryName === 'England') {
    //   this.registerForm2.controls['officepostalCode'].setValidators([Validators.pattern(Regex.usernamePattern)]);
    //   this.registerForm2.controls['manufacturingpostalCode'].setValidators([Validators.pattern(Regex.usernamePattern)]);
    // } else {
    //   this.registerForm2.controls['officepostalCode'].setValidators([Validators.pattern(Regex.faxNumberPattern)]);
    //   this.registerForm2.controls['manufacturingpostalCode'].setValidators([Validators.pattern(Regex.faxNumberPattern)]);
    // }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.registerForm1.controls[controlName].hasError(errorName);
  }

  public hasError2 = (controlName: string, errorName: string) => {
    return this.registerForm2.controls[controlName].hasError(errorName);
  }

  public nextButtonClick(event: any) {
    this.registerVariables.firstPageFlag = false;
    this.registerVariables.secondPageFlag = true;
    this.registerFlag = false;
  }

  public previousButtonClick(event: any) {
    this.registerVariables.firstPageFlag = true;
    this.registerVariables.secondPageFlag = false;
  }
  public validateDidNumber() {
    this.registerForm1.get('didNumber').value === '' ? this.registerForm1.controls.extension.setValue('') : ''
  }

  public RegisterButtonClick() {
    this.toastr.loaderShow();
    if (this.registerForm2.valid === true) {
      this.toastr.loaderShow();
      this.registerFlag = true;
      const request = this.registerForm1.value;
      request.officeAddressLine1 = this.registerForm2.value.officeAddressLine1;
      request.officeAddressLine2 = this.registerForm2.value.officeAddressLine2;
      request.officepostalCode = this.registerForm2.value.officepostalCode;
      request.manufacturingAddressLine1 = this.registerForm2.value.manufacturingAddressLine1;
      request.manufacturingAddressLine2 = this.registerForm2.value.manufacturingAddressLine2;
      request.manufacturingpostalCode = this.registerForm2.value.manufacturingpostalCode;
      request.businessRegistrationNumber = this.registerForm2.value.businessRegistrationNumber;
      request.websiteUrl = this.registerForm2.value.websiteUrl;
      request.attachmentFile = Helper.removeStringEmptySpace(this.registerForm2.value.attachmentFile, false);
      request.description = this.registerForm2.value.description;
      request.userId = this.generatedID;

      this.httpService.invokePost(this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.REGISTER, request).pipe(takeUntil(this._onDestroy)).subscribe((data: any) => {
        try {
          if (data) {
            if (data.body.isError === false) {
              this.messageService.displayBoxMessage = 'Thanks for registering with us as a supplier. You will receive a notification to your registered email when ST Engineering Land Systems Manager approves it. You will be allowed to login after approval.';
              this.router.navigate(['/auth/message']);
              // this.toastr.success(data?.body?.message);
            } else {
              this.toastr.error(data?.body?.message);
            }
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      }, () => {

        this.toastr.loaderHide();
      });
    } else {
      this.validateAllFormFields(this.registerForm2);
      this.toastr.loaderHide();
    }
  }

  public validateAllFormFields(formGroup: UntypedFormGroup) {
    this.toastr.info('Please fill all the Required fields.');
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public CancelButton1click() {
    this.registerForm1.reset();
  }

  public CancelButton2click() {
    // code to delete the file uploaded on cancel
    if (this.generatedID && this.generatedID && this.registerForm2.controls.attachmentFile && this.registerForm2.value.attachmentFile) {
      const requestObject = '?filename=' + encodeURIComponent(this.registerForm2.value.attachmentFile) + '&userId=' + this.generatedID;
      this.httpService.invokeDelete(this.serviceConfigs.ADMIN_APP_CONFIG.DELETE_FILE, requestObject).pipe(takeUntil(this._onDestroy)).subscribe((data: any) => {
        try {
          if (data && data?.isError === false) {
            // this.toastr.success(data?.message);
            this.generatedID = '';
          } else if (data?.message === true) {
            this.toastr.error(data?.message);
            this.generatedID = '';
          }
        } catch (ex) {
          this.toastr.error(ex);
        }
      });
    }
    this.registerForm2.reset();
  }

  public CompanyProfileUplaod(event: any) {
    // const files = (this.fileUpload.nativeElement as HTMLInputElement).files;
    const input = event.target as HTMLInputElement;
    const files = input.files;
    const allowedExtensions = /(\.pdf|\.xls|\.xlsx)$/i;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        if (!allowedExtensions.exec(files[i].name)) {
          this.toastr.error('Only .pdf , .xls and .xlsx supported !');
          input.value = '';
          return;
        }
      }
    }
    const file = event.target.files[0];
    if (!Helper.validateFilename(file.name)) {
      this.toastr.error("File name should not contain these special characters @$%&\/:?\"'<>|~`#^={}[];!");
      return;
    } else {
      if (file && file != null && file.size > 10615705) {
        this.toastr.info('The attachment file should not be more than 10 MB size');
        this.toastr.loaderHide();
        return false;
      }
      setTimeout(() => {
        this.toastr.loaderShow('Scanning in progress...');
      }, 100);
      const formData = new FormData();
      formData.append('file', file, file.name);
      const requestFile = formData;
      this.httpService.invokeScanFileUploadRegisterSupplier(this.serviceConfigs.FILE_SCAN_SERVICES_CONFIG.UPLOAD, requestFile, this.registerForm1.get('email').value).subscribe((data: any) => {
        try {
          if (data && data?.type !== 0) {
            if (data.body.isError === false) {
              // this.CompanyFileUplaod(event.target.files[0]);
              this.registerForm2.controls.attachmentFile.setValue(file.name);
              this.saveScanAduit(data?.body?.message, file.name, 'File Scan');
            } else {
              this.toastr.error(data?.body?.message);
              this.saveScanAduit(data?.body?.message, file.name, 'File Scan');
            }
          }
          setTimeout(() => {
            this.toastr.loaderHide();
          }, 200);
        } catch (ex) {

          setTimeout(() => {
            this.toastr.loaderHide();
          }, 200);
        }
      }, (err) => {

        setTimeout(() => {
          this.toastr.loaderHide();
        }, 200);
      }, () => {

        setTimeout(() => {
          this.toastr.loaderHide();
        }, 200);
      });
      setTimeout(() => {
        this.toastr.loaderHide();
      }, 200);
    }
  }

  public saveScanAduit(msg: any, fileName: any, type: any) {
    const request = {
      tableKey: '',
      email: this.registerForm1.value.email,
      description: msg,
      fileName: fileName,
      action: type
    }
    this.httpService.invokePost(this.serviceConfigs.FILE_SCAN_SERVICES_CONFIG.SCAN_AUDIT, request).pipe(takeUntil(this._onDestroy)).subscribe((data: any) => {
      try {
        if (data) {
          if (data.body.isError === false) {
            this.toastr.success(data?.body?.message);
          } else {
            this.toastr.error(data?.body?.message);
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    }, () => {

      this.toastr.loaderHide();
    });

  }

  public CompanyFileUplaod(data) {
    const files = (this.fileUpload.nativeElement as HTMLInputElement).files;
    const file = data;
    const formData = new FormData();
    formData.append('file', file, file.name);
    const requestFile = formData;
    this.httpService.invokeFileUpload(this.serviceConfigs.SUPPLIER_APP_SERVICES_CONFIG.SUPPLISER_UPLOAD, requestFile).pipe(takeUntil(this._onDestroy)).subscribe((data: any) => {
      try {
        if (data && data?.body) {
          if (data?.body && data?.body?.isError === false) {
            this.registerForm2.controls.attachmentFile.setValue(data?.body?.fileName);
            this.generatedID = data?.body?.userId;
          } else {
            this.toastr.error(data?.body?.message);
            this.generatedID = '';
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    }, () => {

      this.toastr.loaderHide();
    });
  }

  public DeleteFile() {
    let msg = "The following file deleted successfully!";
    this.saveScanAduit(msg, this.registerForm2.controls.attachmentFile.value, 'File Delete');
    this.registerForm2.patchValue({ 'attachmentFile': '' });
  }

  private detectModeOfAccess() {
    const sUsrAg = navigator.userAgent.toLowerCase();
    if (sUsrAg.indexOf('firefox') > -1) {
      this.sBrowser = 'Firefox';
    } else if (sUsrAg.indexOf('trident') > -1) {
      this.sBrowser = 'IE';
    } else if (sUsrAg.indexOf('chrome') > -1) {
      this.sBrowser = 'Chrome';
    } else if (sUsrAg.indexOf('safari') > -1) {
      this.sBrowser = 'Safari';
    } else {
      this.sBrowser = '';
    }
  }

  protected filterCountry() {
    if (!this.countryList) {
      return;
    }
    // get the search keyword
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountries.next(this.countryList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCountries.next(
      this.countryList.filter(data => data.countryName.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterIndustryType() {
    if (!this.industryTypeList) {
      return;
    }
    // get the search keyword
    let search = this.industryFilterCtrl.value;
    if (!search) {
      this.filteredIndustryTypes.next(this.industryTypeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredIndustryTypes.next(
      this.industryTypeList.filter(data => data.industryName.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterSubIndustryType() {
    if (!this.subIndustryTypeList) {
      return;
    }
    // get the search keyword
    let search = this.subIndustryFilterCtrl.value;
    if (!search) {
      this.filteredSubIndustryTypes.next(this.subIndustryTypeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredSubIndustryTypes.next(
      this.subIndustryTypeList.filter(data => data.subIndustryName.toLowerCase().indexOf(search) > -1)
    );
  }
}

<div>
    <h2 mat-dialog-title>Edit Price <span><mat-icon (click)="dialogclose('cancel')" class="float-right cursor-pointer icon-18">close</mat-icon> </span> </h2>
</div>
<mat-dialog-content class="mat-typography price-conversion">
    <div class="p-b-32" fxFlex="100"  fxLayout="row wrap">
        <div fxFlex="100" class="m-t-8" fxLayout="row wrap" >
            <div class="heading" fxLayoutAlign="start center" fxFlex="43">
                <span class="key-style" >Product : </span> 
                <span class="value-style" >{{ prodName }}</span>
            </div>
            <div class="heading" fxLayoutAlign="start center" fxFlex="47" >
                <span class="key-style" >Quantity : </span> 
                <span class="value-style" >{{ prodQty }}</span>
            </div >
         </div>
        <form fxFlex="100" [formGroup]="priceForm" class="w-full" style="margin-top: 20px;">
            <div fxLayoutAlign="start center" fxFlex="43">
                Unit Price
            </div>
            <div fxLayoutAlign="start center" fxFlex="47" >
                <mat-form-field class="form-field-style" appearance="outline">
                    <input matInput value="unitPrice" formControlName="unitPrice" style="cursor : default; font-weight: bold;">
                    <mat-error class="m-t-8" *ngIf="hasError('unitPrice', 'required')">This field is required</mat-error>
                </mat-form-field>  
            </div>
        </form>
    </div>  
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="m-r-8 action-button" (click)="editProductPrice()" mat-stroked-button >Submit</button>
</mat-dialog-actions>

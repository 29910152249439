import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceConfigs, HttpService, SessionsService } from '@core';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { MessageService } from '@core/services/message.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [HttpService, ServiceConfigs]

})
export class LoginComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public loginForm: UntypedFormGroup;
  public hasDangerAlert: boolean = false;
  public sBrowser: string;
  public errorMsg: string = 'Unable to Login!';
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
    private messageService: MessageService,
    private toastr: ToastrServiceClass,
    private sessionsSrv: SessionsService) { }

  // life cycle hooks

  ngOnInit() {
    const sessionUser = this.sessionsSrv.getCurrentUser();
    this.sessionsSrv.setStorage({});
    // if (sessionUser['access_token'] && sessionUser['userType'] === '1' || sessionUser['access_token'] && sessionUser['userType'] === '2' ) {
    //   this.router.navigate(['/products-services']);
    // } 
    // if (sessionUser['access_token'] && sessionUser['userType'] === '0') {
    //   this.router.navigate(['/admin/supplier-mgmt']);
    // }
    // if (sessionUser['access_token'] && sessionUser['userType'] === '3') {
    //   this.router.navigate(['/buyer/dashboard']);
    // }
    this.detectModeOfAccess();
    this.buildLoginForm();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  public onSubmit() {
    if (this.loginForm.valid) {
      const request = 'emailId=' + this.loginForm.value.username + '&password=' + this.loginForm.value.password;
      this.toastr.loaderShow();
      this.httpService.invokePost(this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.STE_LOGIN, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        try {
          if (data?.body !== undefined && data?.body?.isError === false) {
            // Login succeeded
            if (data?.body?.payload.userType === '1' || data?.body?.payload.userType === '2') {
              if (data?.body?.payload.status === 'New') {
                this.messageService.displayBoxMessage = 'Your registration is pending for approval. You will be allowed to login after approval.';
                this.router.navigate(['/auth/message']);
              } else if (data?.body?.payload.status === 'Rejected') {
                this.messageService.displayBoxMessage = 'Sorry, Your registration is rejected. You should have received an email on this. Kindly check it for more information.';
                this.router.navigate(['/auth/message']);
              } else if (data?.body?.payload.status === 'Approved') {
                localStorage.setItem('supplier-tuser', this.loginForm.value.username);
                localStorage.setItem('supplier-tpass', this.loginForm.value.password);
                localStorage.setItem('supplier-temail', data?.body?.payload.email);
                this.router.navigate(['/auth/enter-otp']);
              }
            }
            this.toastr.loaderHide();
          } else if (data?.body !== undefined && data?.body?.isError === true) {
            if (data?.body?.message === 'New') {
              this.messageService.displayBoxMessage = 'Your registration is pending for approval. You will be allowed to login after approval.';
              this.router.navigate(['/auth/message']);
            } else if (data?.body?.message === 'Rejected') {
              this.messageService.displayBoxMessage = 'Sorry, Your registration is rejected. You should have received an email on this. Kindly check it for more information.';
              this.router.navigate(['/auth/message']);
            } else {
              this.errorMsg = (data?.body?.message) ? data?.body?.message : this.errorMsg;
              this.hasDangerAlert = true;
            }
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      }, () => {

        this.toastr.loaderHide();
      });
    }
  }

  public cancelClick() {
    this.loginForm.patchValue({ username: '' });
    this.loginForm.patchValue({ password: '' });
    this.loginForm.controls['username'].setErrors({ 'incorrect': true });
    this.loginForm.controls['password'].setErrors({ 'incorrect': true });
    this.loginForm.markAsUntouched();
  }

  public forgotPassword() {
    const user = 'splr';
    this.router.navigate(['/auth/forgot-password/' + user + '/false']);
  }

  public login() {
    this.router.navigateByUrl('/');
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  }

  public detectModeOfAccess() {
    const sUsrAg = navigator.userAgent.toLowerCase();

    if (sUsrAg.indexOf('firefox') > -1) {
      this.sBrowser = 'Firefox';
    } else if (sUsrAg.indexOf('trident') > -1) {
      this.sBrowser = 'IE';
    } else if (sUsrAg.indexOf('chrome') > -1) {
      this.sBrowser = 'Chrome';
    } else if (sUsrAg.indexOf('safari') > -1) {
      this.sBrowser = 'Safari';
    } else {
      this.sBrowser = '';
    }
  }

  private buildLoginForm() {
    const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      password: ['', [Validators.required]],
    });
  }
}

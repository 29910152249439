<div>
    <h2 mat-dialog-title>View Secondary Supplier Information <span><mat-icon (click)="dialogclose()" class="float-right cursor-pointer icon-18">close</mat-icon> </span> </h2>
</div>
<mat-dialog-content class="mat-typography">
    <div fxLayout="row wrap"  fxLayoutAlign="space-around center" fxLayoutGap="16px grid"  >
        <div fxFlex="50" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Name</div> 
            <div class="text-grey-900" > {{service.userName}} </div>
        </div>

        <div fxFlex="50" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Email Address</div> 
            <div class="text-grey-900" > {{service.email}} </div>
        </div>
        <div fxFlex="100" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Contact Number </div> 
            <div class="text-grey-900" > {{service.contactNumber}}</div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="m-r-8 cancel-button" (click)="dialogclose()" mat-stroked-button >
        Back
    </button>
</mat-dialog-actions>
<div class="app-content-inner">
  
<div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="1%" >
    <div fxFlex.gt-sm="24" fxFlex.gt-xs="49" fxFlex="99">
        <mat-card appearance="outlined" class="cursor-pointer" [routerLink]="'/rfq-tenders/new/open'" >
          <mat-card-header class="f-s-12 f-w-100">{{'open-tenders' | translate }}
            <mat-icon class="icon-right icon-18">arrow_forward</mat-icon>
          </mat-card-header >
          <mat-card-content class="text-center">
              <img class="card-left-logo" alt="" src="assets/images/icons/open-tenders-rfp.svg">
            <span style="font-weight: 600;font-size: 48px; line-height: 65px;">{{openCount}}</span>
            <div style="font-weight: 600;font-size: 14px;line-height: 19px;padding-bottom: 16px;" >{{'open-tenders' | translate }}</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div  fxFlex.gt-sm="24" fxFlex.gt-xs="49" fxFlex="99">
        <mat-card appearance="outlined" class="cursor-pointer" [routerLink]="'/rfq-tenders/new/invited'" >
          <mat-card-header class="f-s-12 f-w-100 ">{{'invited-tenders' | translate }} 
            <mat-icon class="icon-right icon-18" >arrow_forward</mat-icon>
          </mat-card-header >
          <mat-card-content class="text-center ">
              <img class="card-left-logo" alt="" src="assets/images/icons/invited-tenders-rfp.svg">
            <span style="font-weight: 600;font-size: 48px; line-height: 65px;">{{invitedCount}}</span>
            <div style="font-weight: 600;font-size: 14px;line-height: 19px;padding-bottom: 16px;" >{{'invited-tenders' | translate }}</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div  fxFlex.gt-sm="24" fxFlex.gt-xs="49" fxFlex="99">
        <mat-card  appearance="outlined"  class="cursor-pointer" [routerLink]="'/rfq-tenders/responded/list'" >
          <mat-card-header class="f-s-12 f-w-100">{{'responded-tenders' | translate }}
            <mat-icon class="icon-right icon-18" >arrow_forward</mat-icon>
          </mat-card-header >
          <mat-card-content class="text-center">
              <img class="card-left-logo" alt="" src="assets/images/icons/responded-tenders-rfp.svg">
            <span style="font-weight: 600;font-size: 48px; line-height: 65px;">{{responseCount}}</span>
            <div style="font-weight: 600;font-size: 14px;line-height: 19px;padding-bottom: 16px;" >{{'responded-tenders' | translate }}</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxFlex.gt-sm="24" fxFlex.gt-xs="49" fxFlex="99">
        <mat-card appearance="outlined" class="cursor-pointer" [routerLink]="'/message-notifications'" >
          <mat-card-header class="f-s-12 f-w-100">{{'messages' | translate }}
            <mat-icon class="icon-right icon-18" >arrow_forward</mat-icon>
          </mat-card-header >
          <mat-card-content class="text-center">
              <img class="card-left-logo" alt="" src="assets/images/icons/messages.svg">
            <span style="font-weight: 600;font-size: 48px; line-height: 65px;">{{messageCount}}</span>
            <div style="font-weight: 600;font-size: 14px;line-height: 19px;padding-bottom: 16px;">{{'new-messages' | translate }}</div>
          </mat-card-content>
        </mat-card>
      </div>
</div>
<div fxLayout="row wrap" style="background: #ffffff; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); border-radius: 10px;padding-bottom: 24px;">
    
      <div fxFlex="50" fxFlex.lt-sm="100">
        <div class="f-s-14 f-w-600 m-24">{{'to-do-list' | translate }}</div>
      </div>
      <div fxFlex="50" fxFlex.lt-sm="100">
        <!-- <form class="search-form m-y-8 text-right" >
          <mat-form-field  appearance="outline" class="m-r-16" [style.width.px]=250> 
              <span matPrefix><mat-icon class="icon-18">search</mat-icon>&nbsp;</span>
              <input matInput placeholder="Search" #searchText (input)="applyFilter($event.target.value)" autocomplete="off" >
                <button *ngIf="searchText.value.length > 0" mat-icon-button matSuffix aria-label="Clear"
                   (click)="searchText.value = '';initToDo('')">
                <mat-icon class="icon-18" >close</mat-icon>
              </button> 
          </mat-form-field>
         
      </form> -->
      </div>
      <div fxFlex="100" fxFlex.lt-sm="100">
        <smart-table class="table-card p-l-24 p-r-24" 
              [data]="dashList"
              [columns]="dashColumns"
              [loading]="isLoading"
              [front]="true"
              [showPager]="true"
              [pageSize]="5"
              [pageSizeOptions]="[5,10,20,50]"
              (changeSort)="changeSort($event)">
        </smart-table>
        <div  class="text-center m-t-48" *ngIf="dashList.length === 0">
          <span class="no-data-text">No To-Do items...</span> 
       </div> 
      </div>
    </div>
   

</div>
import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { ServiceConfigs, HttpService } from '@core';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SecondarySupplierDetails, SecondarySupplierResponse } from '@core/interfaces/user';



@Component({
  selector: 'app-view-secondary',
  templateUrl: './view-secondary.component.html',
  styleUrls: ['./view-secondary.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class ViewSecondaryComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public service: SecondarySupplierDetails;
  public supplierId: any = '';
  public id: any = '';
  public supplierForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
    private toastr: ToastrServiceClass,
    private dialogRef: MatDialogRef<ViewSecondaryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  // life cycle hooks

  ngOnInit() {
    this.supplierId = this.data.userId;
    this.id = this.data.id;
    this.supplierForm = this.formBuilder.group({
      email: new UntypedFormControl('', [Validators.required]),
    })
    this.getServiceByID();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  public hasError = (controlName: string, errorName: string) => {
    return this.supplierForm.controls[controlName].hasError(errorName);
  }

  public getServiceByID() {
    const id = this.supplierId;
    if (id) {
      const request = '?userId=' + this.supplierId;
      this.toastr.loaderShow();
      this.httpService.invokeService(this.serviceConfigs.PROFILE_SERVICES.GETBYID_SECONDRY_DATA, request).then((data: SecondarySupplierResponse) => {
        try {
          if (data && data?.isError === false) {
            // succeeded
            this.service = data?.payload;
            this.supplierForm.setValue({ email: this.service.email })
          } else if (data && data?.isError === true) {
            this.toastr.error(data?.message);
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      });
    }
  }

  public rejectClick() {
    if (this.supplierForm.valid) {
      const request = {
        status: 'Rejected',
        id: this.id,
        userId: this.supplierId,
        companyId: '',
        email: this.supplierForm.value.email
      }
      this.toastr.loaderShow();
      this.httpService.invokePutBody(this.serviceConfigs.ADMIN_APP_CONFIG.SUPPLIER_STATUS, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        try {
          if (data && data?.body?.isError === false) {
            // succeeded
            this.toastr.success(data?.body?.message);
            this.dialogRef.close();
          } else if (data && data?.body?.isError === true) {
            this.toastr.error(data?.body?.message);
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      });
    }
  }
  
  public approveClick() {
    if (this.supplierForm.valid) {
      const request = {
        status: 'Approved',
        id: this.id,
        userId: this.supplierId,
        email: this.supplierForm.value.email
      }
      this.toastr.loaderShow();
      let apiUrl = '';

      if (this.data.isFrom == 'edit') {
        apiUrl = this.serviceConfigs.ADMIN_APP_CONFIG.SUPPLIER_EMAIL_UPDATE;
      } else {
        apiUrl = this.serviceConfigs.ADMIN_APP_CONFIG.SUPPLIER_STATUS;
      }

      this.httpService.invokePutBody(apiUrl, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        try {
          if (data && data?.body?.isError === false) {
            // succeeded
            this.toastr.success(data?.body?.message);
            this.dialogRef.close();
          } else if (data && data?.body?.isError === true) {
            this.toastr.error(data?.body?.message);
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      });
    }
  }

  public dialogclose() {
    this.dialogRef.close();
  }

}

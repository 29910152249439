<div>
    <h2 mat-dialog-title>Category list<span>
            <mat-icon (click)="dialogclose()"
                      class="float-right cursor-pointer icon-18">close</mat-icon>
        </span> </h2>
</div>
<mat-dialog-content class="mat-typography view-rfq-respond list-categories">
    <form novalidate class="app-form-horizontal">
        <div fxFlex="99">
            <div fxLayout="row wrap" fxLayoutGap="30px grid"  class="m-y-16">
                <div fxFlex="50" fxFlex.lt-sm="100">
                    <div fxFlex fxFlex.lt-sm="100" style="width: 100%;">
                        <label class="register-form-label font-bold">Category 1</label>
                        <mat-list *ngIf="popupData != undefined && popupData.length > 0" class="selectd-categories">
                            <mat-list-item *ngFor="let data of CategoryList1"> {{data.categoryName}} </mat-list-item>
                            <span *ngIf="CategoryList1.length == 0"> No Item Selected </span>                            
                        </mat-list>
                    </div>
                </div>
                <div fxFlex="50" fxFlex.lt-sm="100">
                    <div fxFlex fxFlex.lt-sm="100" style="width: 100%;">
                        <label class="register-form-label font-bold">Category 2</label>
                        <mat-list *ngIf="popupData != undefined &&  popupData.length > 0"  class="selectd-categories">
                            <mat-list-item *ngFor="let data of CategoryList2"> {{data.categoryName}} </mat-list-item>
                            <span *ngIf="CategoryList2.length == 0"> No Item Selected </span>                            
                        </mat-list>
                    </div>
                </div>
            </div>

        </div>
    </form>
</mat-dialog-content>
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionsService } from './../services/sessions.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  client_id: string = 'ste';
  client_secret: string = 'ste-secret';
  grant_type: string = 'password';
  credentials = `${this.client_id}` + ':' + `${this.client_secret}`;
  authRoutes = ['/auth/enter-otp', '/auth/login', '/admin-login'];

  constructor(private router: Router, private sessionsSer: SessionsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authRoutes.includes(this.router.url)) {
      req = req.clone({
        setHeaders: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      });
    } else {
      req = req.clone({
        setHeaders: {},
      });
    }
    if (this.sessionsSer.getAuthToken() && !this.router.url.includes('/home/')) {
      const token = this.sessionsSer.getAuthToken();
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      req = req.clone({
        setHeaders: {
          // Authorization: `Basic ${window.btoa(this.credentials)}`,
        },
      });
    }

    return next.handle(req).pipe(
      mergeMap((event: any) => {
        return of(event);
      }),
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  }
}

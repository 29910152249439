import { DatePipe } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ServiceConfigs, HttpService, SessionsService } from '@core';
import { AboutUsResponse, AnnouncementDetails, AnnouncementsListResponse, JoinUsDetails, JoinUsResponse } from '@core/interfaces/supplier-app-service-config';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss'],
  providers: [HttpService, ServiceConfigs, DatePipe]
})
export class HomeScreenComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public announceList: AnnouncementDetails[] = [];
  public joinList: JoinUsDetails[] = [];
  public aboutUs: any = '';
  public year: number = new Date().getFullYear();
  public sBrowser: string;
  public query: any = {
    tenderType: '',
    bidSubmissionDate: ''
  };
  public searchFilterCtrl: UntypedFormControl = new UntypedFormControl('');
  constructor(
    private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
    public datePipe: DatePipe,
    private sessionService: SessionsService) { }

  ngOnInit() {
    // if(this.sessionService.getAuthToken()) {
    //   this.sessionService.setStorage({});
    // }
    this.detectModeOfAccess();
    this.getAnnouncementList();
    this.getJoinList();
    this.getAbouUs();
    this.searchFilterHandler();
    this.getTendorDetails(this.query.tenderType, this.query.bidSubmissionDate); 
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  searchFilterHandler() {
    const openFilter = sessionStorage.getItem('rfqOpenFilter');
    this.searchFilterCtrl?.valueChanges
      .pipe(
        filter((val) => val !== null && val !== undefined),
        debounceTime(1000),
        distinctUntilChanged(), takeUntil(this.destroySubject))
      .subscribe(() => {
        let bidSubmissionDate = this.query.bidSubmissionDate !== '' ? this.datePipe.transform(this.query.bidSubmissionDate, 'yyyy-MM-dd') : '';
        this.getTendorDetails(this.query.tenderType, bidSubmissionDate);
      });
    if (openFilter) {
      this.query = JSON.parse(openFilter);
    }
  }

  detectModeOfAccess() {
    const sUsrAg = navigator.userAgent.toLowerCase();
    if (sUsrAg.indexOf('firefox') > -1) {
      this.sBrowser = 'Firefox';
    } else if (sUsrAg.indexOf('trident') > -1) {
      this.sBrowser = 'IE';
    } else if (sUsrAg.indexOf('chrome') > -1) {
      this.sBrowser = 'Chrome';
    } else if (sUsrAg.indexOf('safari') > -1) {
      this.sBrowser = 'Safari';
    } else {
      this.sBrowser = '';
    }
  }
  downloadTermsConditions() {
    let link = document.createElement("a");
    link.download = "stels_terms_of_use.pdf";
    link.href = "assets/stels_terms_of_use.pdf";
    link.click();
  }
  downloadPersonalPolicy() {
    let link = document.createElement("a");
    link.download = "pdpa_stels_Final.pdf";
    link.href = "assets/pdpa_stels_Final.pdf";
    link.click();
  }

  getAnnouncementList() {
    this.announceList = [];
    const requestObject = '';
    this.httpService.invokeService(this.serviceConfigs.HOME_MGMT_CONFIG.GET_ANNOUNCEMENT_LIST_ORDER, requestObject).then((response: AnnouncementsListResponse) => {
      if (response && response?.payload) {
        this.announceList = response?.payload;
      }
    });
  }

  getJoinList() {
    this.joinList = [];
    const requestObject = '';
    this.httpService.invokeService(this.serviceConfigs.HOME_MGMT_CONFIG.GET_JOIN_US_LIST_ORDER, requestObject).then((response: JoinUsResponse) => {
      if (response && response?.payload) {
        this.joinList = response?.payload;
      }
    });
  }

  public getAbouUs() {
    const requestObject = '';
    this.httpService.invokeService(this.serviceConfigs.HOME_MGMT_CONFIG.ABOUTUS_GET_BY_ID, requestObject).then((response: AboutUsResponse) => {
      if (response && response?.isError === false) {
        this.aboutUs = response?.payload[0].description;
      } else if (response && response?.isError === true) {
      }
    });
  }

  public getTendorDetails(tenderType: any, bidSubmissionDate: any) {
    const requestObject = '?tenderType=' + tenderType + '&procurementCategory=' + '' + '&bidSubmissionDate=' + bidSubmissionDate + '&userId=' + '779ae0fc-454e-43da-8ad9-f6ee72bb7e9a' + '&companyId=' + 'd2bd6afb-31fd-4b8e-a116-08c2bf65b0e6' + '&searchKey=' + encodeURIComponent(this.searchFilterCtrl.value);
    // this.httpService.invokeService(this.serviceConfigs.RFQ_TENDOR_SERVICES.GET_OPEN_TENDOR_LIST, requestObject).then((response: any) => {
    //   if (response && response?.payload && response?.payload !== '') {
    //     this.rfqTendorList = response?.payload;
    //     this.cdr.detectChanges();
    //   } else {
    //     this.rfqTendorList = [];
    //   }
    // });
  }

  searchFilter() {
    let searchDate = '';
    if (this.query.bidSubmissionDate) {
      sessionStorage.setItem('rfqOpenFilter', JSON.stringify(this.query));
      searchDate = this.datePipe.transform(this.query.bidSubmissionDate, 'yyyy-MM-dd');
    }
    this.getTendorDetails(this.query.tenderType, searchDate);
  }

  resetFilter() {
    this.query.tenderType = '';
    this.query.bidSubmissionDate = '';
    sessionStorage.removeItem('rfqOpenFilter');
    this.getTendorDetails(this.query.tenderType, this.query.bidSubmissionDate);
  }
}

import { Injectable } from '@angular/core';
import { SmartColumn } from '@shared';
import { DatePipe } from '@angular/common';

@Injectable()
export class DashboardService {
  dashColumns: SmartColumn[] = [
    {
      title: '',
      index: 'icon',
      type: 'icon',
      width: '10px'
    },
    {
      title: 'Task',
      index: 'taskName',
      width: '200px',
      type: 'text'
    },
    {
      title: 'Description',
      index: 'taskDescription',
      width: 'auto'
    },
    {
      title: 'Deadline',
      index: 'deadLine',
      width: '200px',
      type: 'format',
      format: (data: any) => {
        return this.datePipe.transform(data.deadLine,"d MMM y h:mm a");
      }
    }
  ];

  getColumns(fn1: (record: any) => void, fn2: (record: any) => void, fn3: (record: any) => void) {
    this.dashColumns.push({
      title: 'Action',
      index: 'action',
      width: '120px',
      disabled: true,
      type: 'button',
      buttons: [
        {
          icon: 'edit',
          tooltip: 'Edit',
          type: 'link',
          color: 'accent',
          click: fn2,
        },
        {
          icon: 'done',
          tooltip: 'Mark As Completed',
          type: 'link',
          color: 'accent',
          click: fn3,
        },
        {
          icon: 'delete',
          tooltip: 'Delete',
          color: 'accent',
          type: 'link',
          pop: false,
          popTitle: 'Confirm delete?',
          click: fn1,
        }
      ],
    });
    return this.dashColumns;
  }
    constructor(private datePipe: DatePipe) {}

}

import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  static toExportFileName(excelFileName: string): string {
    return `${excelFileName}_${new Date().getDate()}_${new Date().getMonth() + 1}_${new Date().getFullYear()}.xlsx`;
  }

  public exportAsExcelFile(json: any[], excelFileName: string, coloumnSize: number): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const wscols: any = [];
    if (json.length > 0) {

      for (let i: any = 0; i <= json.length; i++) {
        wscols.push({ wch: coloumnSize });
      }
    }
    if (json.length === 1) {

      for (let i: any = 0; i <= Object.keys(json[0]).length; i++) {
        wscols.push({ wch: coloumnSize });
      }
    }
    worksheet['!cols'] = wscols;
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, ExcelService.toExportFileName(excelFileName));

    // const workbookk = XLSX.readFile('Master.xlsx');
    const sheets = workbook.SheetNames;

    sheets.forEach(sheet => {

    });
  }

  public importExcelFileAsJson(file: any): void {
    const workbook: XLSX.WorkBook = XLSX.readFile(file);
    const sheets = workbook.SheetNames;
    sheets.forEach(sheet => {

    });
  }


  public exportToExcel(pageRowData, gridname, coloumnSize: number) {
      this.exportAsExcelFile(pageRowData, gridname, coloumnSize);
  }
}

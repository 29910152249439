import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { NgxPopperModule } from 'ngx-popper';
import { TranslateModule } from '@ngx-translate/core';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ErrorCodeComponent } from './components/error-code/error-code.component';
import { Text3dComponent } from './components/text3d/text3d.component';
import { SmartProgressComponent } from './components/smart-progress/smart-progress.component';
import { SmartDialogComponent } from './components/smart-dialog/smart-dialog.component';
import { SmartTableComponent } from './components/smart-table/smart-table.component';
import { SmartTableCellComponent } from './components/smart-table/smart-table-cell.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';

// add task
import { AddTaskDialogComponent } from './components/add-task-dialog/add-task-dialog.component';
import { ViewProductComponent } from 'app/pages/admin/supplier-mgmt/approved/view-product/view-product.component';
import { ViewServiceComponent } from 'app/pages/admin/supplier-mgmt/approved/view-service/view-service.component';
import { ViewSecondaryComponent } from 'app/pages/admin/supplier-mgmt/approved/view-secondary/view-secondary.component';
import { ViewRespondedComponent } from 'app/pages/buyer/rfq-tender/buyer-rfq/view-responded/view-responded.component';
import { ViewResponseComponent } from 'app/pages/home/new-rfq-tenders/view-response/view-response.component';
import { PricePopupComponent } from 'app/pages/buyer/rfq-tender/buyer-rfq/price-popup/price-popup.component';
import { PriceEditComponent } from 'app/pages/buyer/rfq-tender/buyer-rfq/price-edit/price-edit.component';
import { SendMessageComponent } from 'app/pages/buyer/rfq-tender/buyer-rfq/send-message/send-message.component';
import { ViewSecondarySupplierComponent } from './components/view-secondary-supplier/view-secondary-supplier.component';
import { EditProductComponent } from 'app/pages/admin/supplier-mgmt/approved/edit-product/edit-product.component';
import { EditServiceComponent } from 'app/pages/admin/supplier-mgmt/approved/edit-service/edit-service.component';

// directives
import { PreventDoubleClickDirective } from './directives/prevent-doubleClick.directive';
import { OnlyNumberOnPress, OnlyNumberOnDown } from './directives/only-number.directive';

// pipes
import { nl2brPipe } from './pipes/nl2br.pipe';
import { ViewArchivedRespondedComponent } from 'app/pages/admin/archived-tender-details/view-responded/view-responded.component';
import { BlockedViewProductComponent } from 'app/pages/admin/supplier-mgmt/blocked/blocked-view-product/view-product.component';
import { BlockedViewSecondaryComponent } from 'app/pages/admin//supplier-mgmt/blocked/blocked-view-secondary/view-secondary.component';
import { BlockedViewServiceComponent } from 'app/pages/admin//supplier-mgmt/blocked/blocked-view-service/view-service.component';

import { BlockedViewProductForAdminComponent } from 'app/pages/admin-role/supplier-details/blocked/blocked-view-product/view-product.component';
import { BlockedViewSecondaryForAdminComponent } from 'app/pages/admin-role/supplier-details/blocked/blocked-view-secondary/view-secondary.component';
import { BlockedViewServiceForAdminComponent } from 'app/pages/admin-role/supplier-details/blocked/blocked-view-service/view-service.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { CustomDateRemoverPipe } from './pipes/custom-date-remover.pipe'
import { DashIfEmptyPipe } from './pipes/dash-if-empty.pipe';


import { ProductRespondComponent } from 'app/pages/supplier/market-place/product-on-sale/product-respond/product-respond.component';
import { ViewSupplierResponseComponent } from 'app/pages/admin/market-place/product-sale/view-supplier-response/view-supplier-response.component';
import { ViewReportComponent } from 'app/pages/admin/market-place/product-sale/view-report/view-report.component';
import { ViewSoldReportComponent } from 'app/pages/admin/sold-market-place/product-sold/view-report/view-report.component';
import { ApprovePopupComponent } from 'app/pages/admin/new-request/request/approve-popup/approve-popup.component';
import { CategoryListViewPopupComponent } from 'app/pages/admin/supplier-mgmt/approved/category-view/category-view.component';
import { AddCategoryComponent } from 'app/pages/supplier/qa-documents/add-category/add-category.component';
import { RoundPipe } from './pipes/round.pipe';
import { FilterPipe, LazyLoadDataPipe } from './pipes/filter.pipe';
import { SubloaderComponent } from './components/subloader/subloader.component';
//import { MatSelect } from '@angular/material/select';

const THIRD_MODULES = [
  MaterialModule,
  FlexLayoutModule,
  NgProgressModule,
  NgProgressRouterModule,
  NgSelectModule,
  FormlyModule,
  FormlyMaterialModule,
  NgxPopperModule,
  TranslateModule,
];
const COMPONENTS = [
  BreadcrumbComponent,
  PageHeaderComponent,
  ErrorCodeComponent,
  Text3dComponent,
  SmartProgressComponent,
  SmartDialogComponent,
  SmartTableComponent,
  SmartTableCellComponent,
  StarRatingComponent,
  PriceEditComponent,
  ViewResponseComponent,
  SubloaderComponent
];

const COMPONENTS_DYNAMIC = [SmartDialogComponent, ViewArchivedRespondedComponent, ViewReportComponent, ViewSoldReportComponent, ViewSupplierResponseComponent, ViewSecondarySupplierComponent, AddCategoryComponent, AddTaskDialogComponent, EditServiceComponent, EditProductComponent, ViewProductComponent, ViewServiceComponent, ViewSecondaryComponent, ViewRespondedComponent, PricePopupComponent, SendMessageComponent, BlockedViewProductComponent, BlockedViewSecondaryComponent, BlockedViewServiceComponent, BlockedViewProductForAdminComponent, BlockedViewSecondaryForAdminComponent, BlockedViewServiceForAdminComponent, ProductRespondComponent, ApprovePopupComponent, CategoryListViewPopupComponent,SubloaderComponent];
const DIRECTIVES = [PreventDoubleClickDirective, OnlyNumberOnPress, OnlyNumberOnDown];
const PIPES = [nl2brPipe, ReplacePipe, CustomDateRemoverPipe, DashIfEmptyPipe, RoundPipe,LazyLoadDataPipe,FilterPipe];

@NgModule({
    declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES, ...COMPONENTS_DYNAMIC, SubloaderComponent],
    imports: [CommonModule, FormsModule, RouterModule, ReactiveFormsModule, ...THIRD_MODULES],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        ...THIRD_MODULES,
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES,
        
    ],
    providers: [
        CustomDateRemoverPipe,
        MaterialModule
    ]
})
export class SharedModule { }

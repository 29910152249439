import { Pipe, PipeTransform , OnDestroy } from '@angular/core';

@Pipe({
  name: 'nl2br'
})

export class nl2brPipe implements PipeTransform {
  transform(value: string): string {
    const data = value ? value.replace(/\n/g, '<br/>') : value; 
    return data;
 }

}
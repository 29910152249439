import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@env/environment';
import { AppLayoutComponent } from '../theme/app-layout/app-layout.component';
import { AuthLayoutComponent } from '../theme/auth-layout/auth-layout.component';
import { DashboardComponent } from './supplier/dashboard/dashboard.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { EnterOtpComponent } from './sessions/enter-otp/enter-otp.component';
import { OtpExpiredComponent } from './sessions/otp-expired/otp-expired.component';
import { HomeScreenComponent } from './home/home-screen.component';
import { MessageScreenComponent } from './sessions/message-screen/message-screen.component';
import { ForgotPasswordComponent } from './sessions/forgot-password/forgot-password.component';
import { GaurdService } from '../core/services/gaurd.service';
import { AdminLoginComponent } from './sessions/admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin/dashboard/dashboard.component';

const routes: Routes = [
        // {
        //         path: '',
        //         component: HomeScreenComponent,
        // },
        { path: '', redirectTo: 'home', pathMatch: 'full' },
        {
                path: 'home',
                component: HomeScreenComponent,
                children: [
                        {
                                path: '',
                                loadChildren: () => import('./home/home-screen.module').then(m => m.HomeScreenModule),
                        }
                ]
        },
        {
                path: '',
                component: AppLayoutComponent,
                children: [
                        { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
                        {
                                canActivate: [GaurdService],
                                path: 'dashboard',
                                component: DashboardComponent,
                                data: { title: 'Dashboard', titleI18n: 'dashboard', roles: 'supplier' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'products-services',
                                loadChildren: () => import('./supplier/products-services/products-services.module').then(m => m.ProductsServicesModule),
                                data: { title: 'Products Services', titleI18n: 'products-service', roles: 'supplier' }
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'qa-document',
                                loadChildren: () => import('./supplier/qa-documents/qa-document.module').then(m => m.QaDocumentModule),
                                data: { title: 'Products Services', titleI18n: 'products-service', roles: 'supplier' }
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'supplier-profile',
                                loadChildren: () => import('./supplier/supplier-profile/supplier-profile.module').then(m => m.SupplierProfileModule),
                                data: { title: 'Supplier Profile', titleI18n: 'supplier-profile', roles: 'supplier' }
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'rfq-tenders',
                                loadChildren: () => import('./supplier/rfq-tenders/rfq-tenders.module').then(m => m.RfqTendersModule),
                                data: { title: 'New Rfq Tenders', titleI18n: 'new-rfq-tenders', roles: 'supplier' }
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'message-notifications',
                                loadChildren: () => import('./supplier/message-notifications/message-notifications.module').then(m => m.MessageNotificationsModule),
                                data: { title: 'Message-centre', titleI18n: 'message-centre', roles: 'supplier' }
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'information',
                                loadChildren: () => import('./supplier/information/information.module').then(m => m.InformationModule),
                                data: { title: 'Information Center', titleI18n: 'information-center', roles: 'supplier' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'market-place',
                                loadChildren: () => import('./supplier/market-place/market-place.module').then(m => m.MarketPlaceModule),
                                data: { title: 'Market Place', titleI18n: 'market-place', roles: 'supplier' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'watch-list',
                                loadChildren: () => import('./supplier/watch-list/watch-list.module').then(m => m.WatchListModule),
                                data: { title: '', titleI18n: '', roles: 'supplier' },
                        }
                ],
        },
        {
                path: 'admin-role',
                component: AppLayoutComponent,
                children: [
                        {
                                canActivate: [GaurdService],
                                path: 'dashboard',
                                loadChildren: () => import('./admin-role/admin-role.module').then(m => m.AdminRoleModule),
                                data: { title: 'Admin', titleI18n: 'admin', roles: 'admin-role' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'resources-center',
                                loadChildren: () => import('./admin-role/resources-center/resources-center.module').then(m => m.ResourcesCenterModule),
                                data: { title: 'Resources Center', titleI18n: 'resources-center', roles: 'admin-role' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'supplier-details',
                                loadChildren: () => import('./admin-role/supplier-details/approved.module').then(m => m.ApprovedModule),
                                data: { title: 'Registered Suppliers', titleI18n: 'supplier-details', roles: 'admin-role' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'new-request',
                                loadChildren: () => import('./admin-role/new-request/new-request.module').then(m => m.NewRequestModule),
                                data: { title: 'New Users', titleI18n: 'new-request', roles: 'admin-role' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'information',
                                loadChildren: () => import('./admin-role/information/information.module').then(m => m.InformationModule),
                                data: { title: 'Information Center', titleI18n: 'information-center', roles: 'admin-role' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'tender-details',
                                loadChildren: () => import('./admin-role/tender-details/tender-details.module').then(m => m.TenderDetailsModule),
                                data: { title: 'Tender Details', titleI18n: 'tender-details', roles: 'admin-role' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'audit',
                                loadChildren: () => import('./admin-role/audit/audit.module').then(m => m.AuditModule),
                                data: { title: 'Audit', titleI18n: 'audit', roles: 'admin-role' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'archived-tender-details',
                                loadChildren: () => import('./admin-role/archived-tender-details/tender-details.module').then(m => m.TenderDetailsModule),
                                data: { title: 'Archived Tender Details', titleI18n: 'archived-tender-details', roles: 'admin-role' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'configure-heading',
                                loadChildren: () => import('./admin-role/upload-share-document/upload-share-document.module').then(m => m.UploadShareDocumentModule),
                                data: { title: 'Upload Share Document', titleI18n: 'upload-share-document', roles: 'admin-role' },
                        },
                ],
        },
        {
                path: 'buyer',
                component: AppLayoutComponent,
                children: [
                        {
                                canActivate: [GaurdService],
                                path: 'dashboard',
                                loadChildren: () => import('./buyer/buyer.module').then(m => m.BuyerModule),
                                data: { title: 'Buyer', titleI18n: 'buyer', roles: 'buyer' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'buyer-rfq-tenders',
                                loadChildren: () => import('./buyer/rfq-tender/rfq-tender.module').then(m => m.RfqTenderModule),
                                data: { title: 'Tender/RFQ', titleI18n: 'tender/rfq', roles: 'buyer' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'message-notifications',
                                loadChildren: () => import('./buyer/message-notifications/message-notifications.module').then(m => m.MessageNotificationsModule),
                                data: { title: 'My Messages', titleI18n: 'myMessages', roles: 'buyer' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'resources-center',
                                loadChildren: () => import('./buyer/resources-center/resources-center.module').then(m => m.ResourcesCenterModule),
                                data: { title: 'Resources Center', titleI18n: 'resources-center', roles: 'buyer' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'supplier-details',
                                loadChildren: () => import('./buyer/supplier-details/approved.module').then(m => m.ApprovedModule),
                                data: { title: 'Registered Suppliers', titleI18n: 'supplier-details', roles: 'buyer' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'review-ratings',
                                loadChildren: () => import('./buyer/review-ratings/review-ratings.module').then(m => m.ReviewRatingsModule),
                                data: { title: 'Review and Ratings', titleI18n: 'review-ratings', roles: 'buyer' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'market-place',
                                loadChildren: () => import('./buyer/market-place/market-place.module').then(m => m.MarketPlaceModule),
                                data: { title: 'Market Place', titleI18n: '', roles: 'buyer' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'sold-market-place',
                                loadChildren: () => import('./buyer/sold-market-place/sold-market-place.module').then(m => m.SoldMarketPlaceModule),
                                data: { title: 'Market Place', titleI18n: '', roles: 'buyer' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'closed-market-place',
                                loadChildren: () => import('./buyer/closed-market-place/closed-market-place.module').then(m => m.ClosedMarketPlaceModule),
                                data: { title: 'Market Place', titleI18n: '', roles: 'buyer' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'qa-document',
                                loadChildren: () => import('./supplier/qa-documents/qa-document.module').then(m => m.QaDocumentModule),
                                data: { title: 'Qa Document', titleI18n: 'archived-tender-details', roles: 'buyer' },
                        },
                ],
        },
        {
                path: 'sqa',
                component: AppLayoutComponent,
                data: { title: 'SQA', titleI18n: 'sqa' },
                children: [
                        {
                                canActivate: [GaurdService],
                                path: 'dashboard',
                                loadChildren: () => import('./sqa/sqa.module').then(m => m.SqaModule),
                                data: { title: 'SQA', titleI18n: 'sqa', roles: 'sqa' },
                        },
                        { path: '', redirectTo: 'supplier-details', pathMatch: 'full' },
                        {
                                canActivate: [GaurdService],
                                path: 'resource-center',
                                loadChildren: () => import('./sqa/resources-center/resources-center.module').then(m => m.ResourcesCenterModule),
                                data: { title: 'Resource Center', titleI18n: 'resource-center', roles: 'sqa' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'supplier-details',
                                loadChildren: () => import('./sqa/supplier-details/approved.module').then(m => m.ApprovedModule),
                                data: { title: 'Registered Suppliers', titleI18n: 'supplier-details', roles: 'sqa' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'review-ratings',
                                loadChildren: () => import('./sqa/review-ratings/review-ratings.module').then(m => m.ReviewRatingsModule),
                                data: { title: 'Review and Ratings', titleI18n: 'review-ratings', roles: 'sqa' },
                        },
                ],
        },
        {
                path: 'engineer',
                component: AppLayoutComponent,
                children: [
                        // {
                        //   canActivate: [GaurdService],
                        //   path: 'dashboard',
                        //   loadChildren: () => import('./engineer/engineer.module').then(m => m.EngineerModule),
                        //   data: { title: 'Engineer', titleI18n: 'engineer', roles: 'engineer'  },
                        // },
                        { path: '', redirectTo: 'supplier-details', pathMatch: 'full' },
                        {
                                canActivate: [GaurdService],
                                path: 'resource-center',
                                loadChildren: () => import('./engineer/resources-center/resources-center.module').then(m => m.ResourcesCenterModule),
                                data: { title: 'Resource Center', titleI18n: 'resource-center', roles: 'engineer' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'supplier-details',
                                loadChildren: () => import('./engineer/supplier-details/approved.module').then(m => m.ApprovedModule),
                                data: { title: 'Registered Suppliers', titleI18n: 'supplier-details', roles: 'engineer' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'review-ratings',
                                loadChildren: () => import('./engineer/review-ratings/review-ratings.module').then(m => m.ReviewRatingsModule),
                                data: { title: 'Review and Ratings', titleI18n: 'review-ratings', roles: 'engineer' },
                        },
                ],
        },
        {
                path: 'admin-login',
                component: AdminLoginComponent,
        },
        {
                path: 'enter-otp',
                component: EnterOtpComponent,
                data: { title: 'Enter OTP' }
        },
        {
                path: 'admin',
                component: AppLayoutComponent,
                children: [
                        { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
                        {
                                canActivate: [GaurdService],
                                path: 'dashboard',
                                component: AdminDashboardComponent,
                                data: { title: 'Dashboard', titleI18n: 'dashboard', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'supplier-mgmt',
                                loadChildren: () => import('./admin/supplier-mgmt/supplier-mgmt.module').then(m => m.SupplierMgmtModule),
                                data: { title: 'Suppliers Management', titleI18n: 'suppliers-management', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'new-request',
                                loadChildren: () => import('./admin/new-request/new-request.module').then(m => m.NewRequestModule),
                                data: { title: 'New Users', titleI18n: 'new-request', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'blocked',
                                loadChildren: () => import('./admin/supplier-mgmt/blocked/blocked.module').then(m => m.BlockedModule),
                                data: { title: 'Blocked Supplier', titleI18n: 'blocked', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'information-center',
                                loadChildren: () => import('./admin/information-center/information-center.module').then(m => m.InformationCenterModule),
                                data: { title: 'Information Center', titleI18n: 'information-center', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'information-center-user-view',
                                loadChildren: () => import('./admin/information-center-user-view/information-center-user-view.module').then(m => m.InformationCenterUserViewModule),
                                data: { title: 'Information Center User View', titleI18n: 'information-center-user-view', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'resources-center',
                                loadChildren: () => import('./admin/resources-center/resources-center.module').then(m => m.ResourcesCenterModule),
                                data: { title: 'Information Center', titleI18n: 'information-center', roles: 'admin' },
                        },

                        {
                                canActivate: [GaurdService],
                                path: 'resources-center-user-view',
                                loadChildren: () => import('./admin/resources-center-user-view/resources-center-user-view.module').then(m => m.ResourcesCenterUserViewModule),
                                data: { title: 'resources Center User View', titleI18n: 'resources-center-user-view', roles: 'admin' },
                        },

                        {
                                canActivate: [GaurdService],
                                path: 'tender-details',
                                loadChildren: () => import('./admin/tender-details/tender-details.module').then(m => m.TenderDetailsModule),
                                data: { title: 'Tender Details', titleI18n: 'tender-details', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'archived-tender-details',
                                loadChildren: () => import('./admin/archived-tender-details/tender-details.module').then(m => m.TenderDetailsModule),
                                data: { title: 'Archived Tender Details', titleI18n: 'archived-tender-details', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'user-management',
                                loadChildren: () => import('./admin/user-management/user-management.module').then(m => m.UserManagementModule),
                                data: { title: 'User Management', titleI18n: 'user-management', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'home-management',
                                loadChildren: () => import('./admin/home-management/home-management.module').then(m => m.HomeManagementModule),
                                data: { title: 'Home Screen Management', titleI18n: 'homeScreenManagement', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'notifications',
                                loadChildren: () => import('./admin/notifications/notifications.module').then(m => m.NotificationModule),
                                data: { title: 'Notifications', titleI18n: '', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'configuration',
                                loadChildren: () => import('./admin/configuration/configuration.module').then(m => m.ConfigurationModule),
                                data: { title: 'Configuration', titleI18n: '', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'review-ratings',
                                loadChildren: () => import('./admin/review-ratings/review-ratings.module').then(m => m.ReviewRatingsModule),
                                data: { title: 'Review and Ratings', titleI18n: 'review-ratings', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'market-place',
                                loadChildren: () => import('./admin/market-place/market-place.module').then(m => m.MarketPlaceModule),
                                data: { title: 'Market Place', titleI18n: '', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'closed-market-place',
                                loadChildren: () => import('./admin/closed-market-place/closed-market-place.module').then(m => m.ClosedMarketPlaceModule),
                                data: { title: 'Market Place', titleI18n: '', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'sold-market-place',
                                loadChildren: () => import('./admin/sold-market-place/sold-market-place.module').then(m => m.SoldMarketPlaceModule),
                                data: { title: 'Market Place', titleI18n: '', roles: 'admin' },
                        },
                        {
                                canActivate: [GaurdService],
                                path: 'qa-document',
                                loadChildren: () => import('./supplier/qa-documents/qa-document.module').then(m => m.QaDocumentModule),
                                data: { title: 'Qa Document', titleI18n: 'archived-tender-details', roles: 'admin' },
                        },
                ],
        },

        {
                path: 'auth',
                component: AuthLayoutComponent,
                children: [
                        { path: 'login', component: LoginComponent, data: { title: 'Login', titleI18n: 'login' } },
                        {
                                path: 'register',
                                component: RegisterComponent,
                                data: { title: 'Register', titleI18n: 'register' },
                        },
                        {
                                path: 'enter-otp',
                                component: EnterOtpComponent,
                                data: { title: 'Enter OTP' }
                        },
                        {
                                path: 'otp-expired',
                                component: OtpExpiredComponent,
                                data: { title: 'OTP Expired' }
                        },
                        {
                                path: 'message',
                                component: MessageScreenComponent,
                                data: { title: 'Message' }
                        },
                        {
                                path: 'forgot-password/:user/:isResetPassword',
                                component: ForgotPasswordComponent,
                                data: { title: 'Forgot' }
                        }
                ],
        },
        { path: '**', redirectTo: 'supplier' },
];

@NgModule({
        imports: [
                RouterModule.forRoot(routes, {
    useHash: environment.useHash
}),
        ],
        exports: [RouterModule],
})
export class PagesRoutingModule { }

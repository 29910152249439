import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PreloaderService, SessionsService } from '@core';

 
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet> <app-loading-screen></app-loading-screen>'

})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(private preloader: PreloaderService,
    private sessionsSrv: SessionsService  ) {}

  ngOnInit() {
    const meta = document.querySelector("meta[name='generator']");
    if (meta) { 
     meta.remove(); 
     }
  }
 
  ngAfterViewInit() {
    this.preloader.hide();
  }
}

<div class="add-task-container">
    <div fxLayout="row"
         class="panelHeader">
        <div fxFlex="50"
             fxLayoutAlign="start center"><img src="../../../../assets/images/shopping-list.svg"
                 class="header-icon-image"
                 alt="icon" />Add Category</div>
        <div fxFlex="50"
             fxLayoutAlign="end center">
            <mat-icon class="cursor-pointer"
                      (click)="dialogClose()">clear</mat-icon>
        </div>
    </div>
    <div class="add-task-content">
        <form novalidate
              [formGroup]="taskForm">
            <div fxLayout="row"
                 fxLayoutGap="20px"
                 fxLayout.lt-md="column">
                <div fxFlex="50"
                     fxFlex.lt-sm="100">
                    <label class="register-form-label">Category*</label>
                    <mat-form-field class="form-field-style"
                                    appearance="outline">
                        <input maxlength="200"
                               matInput
                               formControlName="categoryname">
                        <mat-error *ngIf="hasError('categoryname', 'required')">Category name is required</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayoutAlign="center"
                 fxLayout="row"
                 fxLayoutGap="16px">
                <div>
                    <button mat-flat-button
                            type="button"
                            (click)="dialogClose()"
                            class="cancel-button">Cancel</button>
                </div>
                <div>
                    <button mat-flat-button
                            type="submit"
                            (click)="AddCategory()"
                            class="add-button">Submit</button>
                </div>

            </div>
        </form>
    </div>
</div>

<div>
    <h2 mat-dialog-title>{{ productData.productName }} <span><mat-icon (click)="dialogclose()" class="float-right cursor-pointer icon-18">close</mat-icon> </span> </h2>
  </div>
  <mat-dialog-content class="mat-typography view-rfq-respond add-product">
    <form novalidate [formGroup]="productForm" class="app-form-horizontal">
    <div fxFlex="90"  >
            <div fxLayout="row wrap" fxLayoutGap="30px grid"  class="m-y-16" >
             

                <div fxFlex="50" fxFlex.lt-sm="100">
                    <div class="text-grey-500" >Available Quantity </div> 
                    <div class="text-grey-900" > {{productData.qtyavailable}} </div>
                </div>

                <div  fxFlex="50" fxFlex.lt-sm="100">
                    <label class="register-form-label">{{"interested-qty"|translate}}*</label>
                    <mat-form-field class="form-field-style" appearance="outline">
                    <input onlyNumberOnDown (blur)="checkInterestedQty()" maxlength="12" matInput formControlName="interestedQty" >
                    <mat-error *ngIf="hasError('interestedQty', 'required')">{{ 'interested-qty-required' |translate }}</mat-error>
                    <mat-error *ngIf="hasError('interestedQty', 'pattern')">{{ 'interested-qty-invalid' |translate }}</mat-error>

                    </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-sm="100">
                    <div class="text-grey-500" >Offer Price /Unit (in SGD)</div>
                    <div class="text-grey-900" > {{productData.offerPrice}} </div>
                </div>

                <div  fxFlex="50" *ngIf='productData.negotiable' fxFlex.lt-sm="100">
                    <label class="register-form-label">{{"make-offer"|translate}} /Unit (in SGD)*</label>
                    <mat-form-field class="form-field-style" appearance="outline">
                    <input onlyNumberOnDown maxlength="15" matInput formControlName="makeOffer" >
                    <mat-error *ngIf="hasError('makeOffer', 'required')">{{ 'make-offer-required' |translate }}</mat-error>
                    <mat-error *ngIf="!hasError('makeOffer', 'required') && hasError('makeOffer', 'makeOfferValidate')">{{ 'decimal-invalid' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>     
       </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
      <button class="m-r-8 cancel-button" (click)="dialogclose()" mat-stroked-button > Cancel </button>
      <button class="m-r-8 action-button" (click)="Respond()" mat-stroked-button > Respond </button>
  </mat-dialog-actions>
  
  
  
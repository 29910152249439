import { Injectable, EventEmitter, Output , OnDestroy } from '@angular/core';


 
@Injectable({
  providedIn: 'root',
})
export class SessionsService {

    @Output() refreshPrfileImage: EventEmitter<boolean> = new EventEmitter();
    @Output() refreshProductCounts: EventEmitter<boolean> = new EventEmitter();

    public setStorage(data) {
        localStorage.setItem('currentUser', JSON.stringify(data));
    }
    public getStorage() {
        return localStorage.getItem('currentUser');
    }

    public getAuthToken(): string {
    const currentUser = JSON.parse(this.getStorage());
    if (currentUser != null) {
        return currentUser['access_token'];
    }
    return '';

    }
    public getUserId(): string {
        const currentUser = JSON.parse(this.getStorage());
        if (currentUser != null) {
            return currentUser['userId'];
        }
        return '';
    }
    public getUserMailId(): string {
        const currentUser = JSON.parse(this.getStorage());
        if (currentUser != null) {
            return currentUser['email'];
        }
        return '';
    }
    public getCurrentUser(): string {
        const currentUser = JSON.parse(this.getStorage());
        if (currentUser != null) {
            return currentUser;
        }
        return '';
    }
    public getUserType(): string {
        const currentUser = JSON.parse(this.getStorage());
        if (currentUser != null) {
            return currentUser['userType'];
        }
        return '';
    }
    public getCompanyUniqueId(): string {
        const currentUser = JSON.parse(this.getStorage());
        if (currentUser != null) {
            return currentUser['companyId'];
        }
        return '';
    }
    public getSupplierType(): string {
        const currentUser = JSON.parse(this.getStorage());
        if (currentUser != null) {
            return currentUser['supplierType'];
        }
        return '';
    }
    public getRoleCategoryType(): string {
        const currentUser = JSON.parse(this.getStorage());
        if (currentUser != null) {
            return currentUser['roleCategoryType'];
        }
        return '';
    }
    changeProfileImage(val) {
        this.refreshPrfileImage.emit(val);
      }

    changeSupplierProductCounts(val) {
        this.refreshProductCounts.emit(val);
      }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ServiceConfigs, HttpService, SessionsService } from '@core';
import { Router } from '@angular/router';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-enter-otp',
  templateUrl: './enter-otp.component.html',
  styleUrls: ['./enter-otp.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class EnterOtpComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public otpForm: UntypedFormGroup;
  public hasDangerAlert: boolean = false;
  constructor(private fb: UntypedFormBuilder,
    private router: Router,
    private toastr: ToastrServiceClass,
    private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
    private sessionsSrv: SessionsService) { }

  // life cycle hooks

  ngOnInit() {
    this.otpForm = this.fb.group({
      otpInput: ['', [Validators.required, Validators.minLength(6)]]
    });
    const token = this.sessionsSrv.getAuthToken();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  public onSubmit() {
    if (this.otpForm.valid) {
      this.toastr.loaderShow();
      const request = '?otpCode=' + this.otpForm.value.otpInput;
      let isAdmin = (localStorage.getItem('isFrom') == 'adminLogin') ? true : false;
      let verificatinURL = '';
      if (isAdmin) {
        verificatinURL = this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.ADMIN_OTP_VERIFICATION;
      } else {
        verificatinURL = this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.OTP_VERIFICATION;
      }
      this.httpService.invokeGet(verificatinURL, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        try {
          if (data && data?.isError === false) {
            // Login succeeded
            if (data?.message === 'OTP Validated') {
              // this.toastr.success(data?.message);
              let isFrom = localStorage.getItem('isFrom')
              if (isFrom === 'adminLogin') {
                this.adminOauthInit(localStorage.getItem('supplier-temail'), localStorage.getItem('supplier-tpass'));
              } else {
                this.oauthInit();
              }
            }
          } else if (data && data?.isError === true) {
            this.hasDangerAlert = true;
            if (data?.message === 'OTP has expired') {
              this.router.navigate(['/auth/otp-expired']);
            } else {
              this.toastr.error(data?.message);
            }
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      });
    }
  }

  public onlyNumber(evt): boolean {
    evt = (evt) ? evt : '';
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.otpForm.controls[controlName].hasError(errorName);
  }

  public resendOTP() {
    this.toastr.loaderShow();
    const request = '?emailId=' + localStorage.getItem('supplier-temail');
    let isAdmin = (localStorage.getItem('isFrom') == 'adminLogin') ? true : false;
    let resendOTPURL = '';
    if (isAdmin) {
      resendOTPURL = this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.RESEND_OTP_ADMIN;
    } else {
      resendOTPURL = this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.RESEND_OTP;
    }
    this.httpService.invokeGet(resendOTPURL, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data?.message !== undefined && data?.message !== '') {
          // succeeded
          this.toastr.success(data?.message);
        } else {
          this.hasDangerAlert = true;
          if (data?.message !== undefined && data?.message !== '') {
            this.toastr.error(data?.message);
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    });
  }

  public removeAdminStorage(): void {
    localStorage.removeItem('supplier-tuser');
    localStorage.removeItem('supplier-tpass');
    localStorage.removeItem('supplier-temail');
    localStorage.removeItem('isFrom');
  }

  private oauthInit() {
    const request = 'username=' + localStorage.getItem('supplier-tuser') + '&password=' + localStorage.getItem('supplier-tpass') + '&grant_type=password';
    this.httpService.invokePost(this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.OAUTH_LOGIN, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data?.body !== undefined && data?.body !== '') {
          // Login succeeded
          if (data?.body?.userType === '1' || data?.body?.userType === '2') {
            this.sessionsSrv.setStorage(data?.body);

            setTimeout(() => {
              localStorage.removeItem('supplier-tuser');
              localStorage.removeItem('supplier-tpass');
              localStorage.removeItem('supplier-temail');
              this.router.navigate(['/dashboard']);
            }, 100);
          }
        } else {
          this.hasDangerAlert = true;
        }

      } catch (ex) {


      }
    }, (err) => {


    }, () => {


    });
  }

  private adminOauthInit(user, pass) {
    const formData = new FormData();
    formData.append('username', localStorage.getItem('supplier-tuser'));
    formData.append('password', localStorage.getItem('supplier-tpass'));
    formData.append('grant_type', 'password');
    this.httpService.invokePost(this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.OAUTH_LOGIN, formData).subscribe((data: any) => {
      try {
        if (data?.body !== undefined && data?.body !== '') {
          // Login succeeded
          switch(data?.body?.userType) {
            case '0':
              this.sessionsSrv.setStorage(data?.body);
              setTimeout(() => {
                this.router.navigate(['/admin/dashboard']);
              }, 100);
              break;
            case '4':
              this.sessionsSrv.setStorage(data?.body);
              setTimeout(() => {
                this.router.navigate(['/admin/dashboard']);
              }, 100);
              break;
            case '3':
              this.sessionsSrv.setStorage(data?.body);
              setTimeout(() => {
                this.router.navigate(['/buyer/dashboard']);
              }, 100);
              break;
            case '5':
              this.sessionsSrv.setStorage(data?.body);
              setTimeout(() => {
                this.router.navigate(['/sqa/dashboard']);
              }, 100);
              break;
            case '6':
              this.sessionsSrv.setStorage(data?.body);
              setTimeout(() => {
                this.router.navigate(['/engineer/supplier-details']);
              }, 100);
              break;
            case '7':
              this.sessionsSrv.setStorage(data?.body);
              setTimeout(() => {
                this.router.navigate(['/admin-role/dashboard']);
              }, 100);
              break;
          }
          // if (data?.body?.userType === '0') {
          //   this.sessionsSrv.setStorage(data?.body);
          //   setTimeout(() => {
          //     this.router.navigate(['/admin/dashboard']);
          //   }, 100);
          // } else if (data?.body?.userType === '4') {
          //   this.sessionsSrv.setStorage(data?.body);
          //   setTimeout(() => {
          //     this.router.navigate(['/admin/dashboard']);
          //   }, 100);
          // }
          // else if (data?.body?.userType === '3') {
          //   this.sessionsSrv.setStorage(data?.body);
          //   setTimeout(() => {
          //     this.router.navigate(['/buyer/dashboard']);
          //   }, 100);
          // } else if (data?.body?.userType === '5') {
          //   this.sessionsSrv.setStorage(data?.body);
          //   setTimeout(() => {
          //     this.router.navigate(['/sqa/dashboard']);
          //   }, 100);
          // } else if (data?.body?.userType === '6') {
          //   this.sessionsSrv.setStorage(data?.body);
          //   setTimeout(() => {
          //     this.router.navigate(['/engineer/supplier-details']);
          //   }, 100);
          // } else if (data?.body?.userType === '7') {
          //   this.sessionsSrv.setStorage(data?.body);
          //   setTimeout(() => {
          //     this.router.navigate(['/admin-role/dashboard']);
          //   }, 100);
          // }
        } else {
          this.hasDangerAlert = true;
        }
        this.removeAdminStorage();
      } catch (ex) {

      }
    }, (err) => {


    });
  }

}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ToastrServiceClass } from '@core/services/toastr.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private snackBar: MatSnackBar,
                private router: Router,
                private toastr: ToastrServiceClass
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (HttpResponse) {
        }
        return next.handle(request)
            .pipe(
                tap(event => {
                    if (event instanceof HttpResponse) { }
                }, error => {
                    let errorMressage = 'Service unavailable currently';
                    switch (error.status) {
                        case 500:
                            errorMressage = 'An unexpected error occurred. Please contact support.';
                            break;
                        case 401 || 403:
                            if (this.router.url === '/auth/login') {
                                errorMressage = 'Unable to Login';
                            } else {
                                errorMressage = 'Session has expired. Please login again.';
                            }
                            break;
                        case 400:
                            if (this.router.url === '/auth/login') {
                                errorMressage = 'Unable to Login';
                            } else {
                                errorMressage = 'Bad Request';
                            }
                            break;
                        case 404:
                            errorMressage = 'Not Found';
                            break;
                        case 409:
                            errorMressage = 'conflict';
                            break;
                        case 406:
                            errorMressage = 'Not Acceptable';
                            break;
                        case 405 :
                            errorMressage = 'Method Not Allowed';
                             break;
                        case 408 :
                            errorMressage = 'Database connection lost';
                            break;
                    }
                    
                    if (errorMressage !== '') {
                        this.toastr.error(errorMressage);
                    }
                }));
    }
}

<div>
  <h2 mat-dialog-title>
    View Supplier Response
    <span><mat-icon (click)="dialogclose()" class="float-right cursor-pointer icon-18">close</mat-icon>
    </span>
  </h2>
</div>
<mat-dialog-content class="mat-typography view-rfq-respond" id="print-content">
  <div style="padding-bottom: 32px;" class="p-l-16 w-full fixed-container" fxFlex="100" fxLayout="column">
    <div class="fixed-content" fxLayout="column">
      <div fxFlex="100" fxLayout="row wrap" fxLayoutGap="20px grid">
        <div fxFlex="100" fxFlex.lt-sm="100">
          <div style="padding: 10px 0px; font-weight: bold; font-size: 14px; line-height: 19px; color: #22181C;">
            Responded By {{ companyName }}
          </div>
        </div>

        <div fxFlex="50" fxFlex.lt-sm="100">
          <div class="text-grey-500">Incoterms</div>
          <div class="text-grey-900">{{ buyerDetails?.incotermsDescription }}</div>
        </div>
        <div fxFlex="50" fxFlex.lt-sm="100">
          <div class="text-grey-500">Address</div>
          <div class="text-grey-900">{{ buyerDetails?.address ? buyerDetails?.address : '-' }}</div>
        </div>
        <!-- <div fxFlex="50" fxFlex.lt-sm="100">
                <div class="text-grey-500" >Validity Date </div> 
                <div class="text-grey-900" > {{ buyerDetails?.validityDate | date: 'dd MMM yyyy h:mm a' }}</div>
              </div> -->
        <!-- <div fxFlex="50" fxFlex.lt-sm="100">
                <div class="text-grey-500" >Currency</div> 
                <div class="text-grey-900" >{{ buyerDetails?.currencyName }} </div>
              </div> -->
        <!-- <div fxFlex="50" fxFlex.lt-sm="100">
                <div class="text-grey-500" >Local Agent or Distributor </div> 
                <div class="text-grey-900" >{{ buyerDetails?.isDistributor == true ? 'Yes' : 'No' }} </div>
              </div> -->
      </div>
      <div fxFlex="100" fxLayout="row wrap" fxLayoutGap="20px grid">
        <div fxFlex="50" fxFlex.lt-sm="100">
          <div style="padding: 10px 0px; font-weight: bold; font-size: 14px; line-height: 19px; color: #22181C;">
            Products and Services
          </div>
        </div>
      </div>
    </div>
    <div class="scrollable-content" fxFlex="100" fxLayout="row wrap">
      <form [formGroup]="awardForm">
        <div formArrayName="items" fxLayout="column">
          <ng-container *ngFor="let data of buyerDetails?.tenderResponseDetailsDTO; let i= index" [formGroupName]="i">
            <div fxFlex="100" fxLayout="row wrap" class="w-full"
              style="border: 1px solid #DBDBDB; margin: 10px 0px;position: relative;">
              <div fxLayout="row wrap" fxFlex="100" fxLayoutGap="20px grid"
                style="background-color: #F9F9F9;padding:10px 10px 0px 10px">
                <div fxFlex="50" fxFlex.lt-sm="100">
                  <span class="key-style">Item No : </span>
                  <span class="value-style">{{ data?.itemNo }}</span>
                </div>
                <div fxFlex="50" fxFlex.lt-sm="100">
                  <span class="key-style">Part Number : </span>
                  <span class="value-style">{{ data?.partNo }}</span>
                </div>
                <div fxFlex="50" fxFlex.lt-sm="100">
                  <span class="key-style">Name : </span>
                  <span class="value-style">{{ data?.productName }}</span>
                </div>
                <div fxFlex="50" fxFlex.lt-sm="100">
                  <span class="key-style">Required Quantity : </span>
                  <span class="value-style">{{ data?.quantity }}</span>
                </div>
                <div fxFlex="50" fxFlex.lt-sm="100">
                  <span class="key-style">UOM : </span>
                  <span class="value-style">{{ data?.uom }}</span>
                </div>
                <div fxFlex="50" fxFlex.lt-sm="100">
                </div>
                <!-- <div id="no-print" *ngIf="data?.status != 'Awarded' && data?.status != 'NotAwarded' && screen !== 'adminView'" fxFlex="50" fxFlex.lt-sm="100">
                  <span class="key-style">Remaining Quantity : </span>
                  <span class="value-style">{{ data?.remainingQuantity }}</span>
                </div> -->
                <div fxFlex="50" fxLayout="row wrap">
                  <span class="key-style">Description :&nbsp;</span>
                  <span class="value-style">{{ data?.productDescription }}</span>
                </div>
                <div fxFlex="50" fxFlex.lt-sm="100">
                  <span class="key-style">Bid Quantity : </span>
                  <span class="value-style">{{ data?.notBid === 1 ? 'No Bid' : data?.bidQuantity }}</span>
                </div>
                <div id="no-print" *ngIf="data?.notBid === 0 && status !== 'NoAward' && screen !== 'adminView'" fxFlex="50" fxLayout="row">
                  <div *ngIf="screen !== 'adminView'" class="flex align-center">
                    <span class="key-style">Award Quantity :&nbsp;</span>
                    <mat-form-field style="width:100px" class="form-field-style" appearance="outline">
                      <input formControlName="awardingQuantity" onlyNumberOnDown maxlength="11" matInput
                        (input)="onTextBoxChange($event.target.value, data.unitPrice, i)"
                        (change)="validateAwardQuantity($event.target.value, i)">
                    </mat-form-field>
                    <mat-error *ngIf="awardForm.get('items.' + i + '.awardingQuantity').errors?.invalidQuantity">
                      Awarding quantity should not be '0'.
                    </mat-error>
                    <mat-error *ngIf="awardForm.get('items.' + i + '.awardingQuantity').errors?.exceedsBidQuantity">
                      Awarding quantity should not exceed bid quantity.
                    </mat-error>
                    <mat-error *ngIf="awardForm.get('items.' + i + '.awardingQuantity').errors?.exceedsRequiredQuantity">
                      Awarding quantity should not exceed remaining quantity.
                    </mat-error>
                  </div>
                  <!-- <div *ngIf="data?.status === 'Awarded'">
                    <span class="key-style">Awarded Quantity :&nbsp;</span>
                    <span class="value-style word-break">{{ data?.awardedQuantity ? data?.awardedQuantity : '-'}}</span>
                  </div> -->
                </div>
                <div id="no-print" *ngIf="screen !== 'adminView' && data?.notBid === 1" fxFlex="50" fxLayout="row wrap">
                  <span class="key-style">Awarded Quantity :&nbsp;</span>
                  <span class="value-style word-break">{{ data?.awardedQuantity ? data?.awardedQuantity : '-'}}</span>
                </div>
                <div *ngIf="screen === 'adminView'" fxFlex="50" fxFlex.lt-sm="100">
                  <span class="key-style">Awarded Quantity :&nbsp;</span>
                  <span class="value-style word-break">{{ data?.awardedQuantity ? data?.awardedQuantity : '-'}}</span>
                </div>
                <div id="print" fxFlex="50" fxLayout="row">
                  <span class="key-style">Awarded Quantity :&nbsp;</span>
                  <span class="value-style word-break">{{ data?.awardedQuantity ? data?.awardedQuantity : '-'}}</span>
                </div>
                <div *ngIf="data?.notBid === 0 && status !== 'NoAward'" fxFlex="50" fxLayout="row wrap" style="height:fit-content;">
                  <mat-button-toggle-group [value]="selectedStatus[i]" (change)="onStatusChange($event.value, i)" [disabled]="disableStatus[i]"
                    aria-label="Status Selection">
                    <mat-button-toggle value="Awarded">
                      <span *ngIf="selectedStatus[i] === 'Awarded'" class="checkmark">✔</span>
                      Award
                    </mat-button-toggle>
                    <mat-button-toggle value="Pending">
                      <span *ngIf="selectedStatus[i] === 'Pending'" class="checkmark">✔</span>
                      Pending
                    </mat-button-toggle>
                    <mat-button-toggle value="NoAward">
                      <span *ngIf="selectedStatus[i] === 'NoAward'" class="checkmark">✔</span>
                      No Award
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                  <span></span>
                  <mat-slide-toggle id="no-print" style="margin-top: 1em;" *ngIf="screen !== 'adminView' && responseCount > 1" #toggle
                    color="primary" (change)="toggleChanged($event, i)" [disabled]="disableNoAwardToggle[i]" [checked]="toggleValue[i] === 1">No Award to all
                  </mat-slide-toggle>
                </div>
                <!-- <div id="no-print" *ngIf="data?.notBid === 0" fxFlex="50" fxLayout="row">
                  <mat-slide-toggle *ngIf="screen !== 'adminView'" #toggle [checked]="awardToggle[i]"
                    [disabled]="disableToggle[i]" color="primary" (change)="toggleChanged($event, i)">Award
                  </mat-slide-toggle>&nbsp;&nbsp;
                  <div *ngIf="data?.status != null || ''">
                    <span class="key-style badge bg-red-500">{{ data?.status === 'Awarded' ? 'Awarded' : 'Not Awarded'
                      }}</span>
                  </div>
                </div> -->
                <div fxFlex="50" fxLayout="row wrap">
                  <span class="key-style">Bid Amount:&nbsp;</span>
                  <!-- <span class="value-style">
                    {{ data?.notBid === 0 ? (data.unitPrice * data.bidQuantity + ' ' + data.currencyName) : '-' }}
                  </span> -->
                  <span class="value-style">
                    {{ data?.notBid === 0 ? ((data.unitPrice * data.bidQuantity) | round ) + ' ' + data.currencyName :
                    '-' }}
                  </span>
                </div>
                <div *ngIf="status !== 'NoAward'" fxFlex="50" fxLayout="row wrap">
                  <span class="key-style">Award Amount:&nbsp;</span>
                  <span id="awardedAmount" [value]="awardedAmounts[i]" class="value-style">
                    {{ data?.awardedQuantity ? ((data?.unitPrice * data?.awardedQuantity) | round ) + ' ' +
                    data?.currencyName : awardedAmounts[i] !== '-' ? ( awardedAmounts[i] | round ) + ' ' +
                    data?.currencyName : ' - ' }}
                  </span>
                </div>
                <!-- <mat-icon (click)="enableEdit($event, data?.productId, data?.productName, data?.quantity, data?.unitPrice, buyerDetails?.id)" *ngIf="status !== 'NotAwarded' && status !== 'Awarded' && currentBuyer.userType == 3 && isSubmittedDateReached" class="icon-24 cursor-pointer"
                            >edit</mat-icon> -->
              </div>

              <div fxLayout="row wrap" fxFlex="100" fxLayoutGap="20px grid" style="padding:20px 10px 10px 10px;">
                <div fxFlex="50" fxFlex.lt-sm="100">
                  <div class="text-grey-500">Unit Price (Exclusive of Goods & Services Tax)</div>
                  <div class="text-grey-900">{{ data?.unitPrice }}</div>
                  <!-- <form [formGroup]="priceForm_{{data?.id}}" class="m-y-8">
                                      <mat-form-field  appearance="outline">
                                        <input matInput formControlName="{{unitP[rice]}}" maxlength="45" value="{{  data?.unitPrice  }}" />
                                      </mat-form-field>
                                    </form> -->
                </div>
                <div fxFlex="50" fxFlex.lt-sm="100">
                  <div class="text-grey-500">Currency</div>
                  <div class="text-grey-900">{{ data?.notBid === 1 ? '-' : data?.currencyName }}</div>
                </div>
                <!-- <div fxFlex="50" fxFlex.lt-sm="100">
                                  <div class="text-grey-500" >EUC required</div> 
                                  <div class="text-grey-900" > {{ data?.eucRequired == true ? 'Yes' : 'No' }}</div>
                                </div>
                                <div fxFlex="50" fxFlex.lt-sm="100">
                                  <div class="text-grey-500" >Country of Origin</div> 
                                  <div class="text-grey-900" >{{data?.countryName}} </div>
                              </div> -->
                <div fxFlex="50" fxFlex.lt-sm="100">
                  <div class="text-grey-500">Delivery Lead-Time (in calendar days)</div>
                  <div class="text-grey-900">{{ data?.leadTime }}</div>
                </div>
                <div fxFlex="50" fxFlex.lt-sm="100">
                  <div class="text-grey-500">Storage Shelf-Life (in calendar days)</div>
                  <div class="text-grey-900">{{ data?.storageShelfLife }}</div>
                </div>
                <!-- <div fxFlex="50" fxFlex.lt-sm="100">
                                <div class="text-grey-500" >Obsolence {{data?.obsolence ? ((data?.obsolenceMonths !== null && data?.obsolenceMonths !== 0 && data?.obsolenceMonths > 60) ? 'Beyond 5 years' : 'in Next 1-5 years') : ''}}</div>
                                <div class="text-grey-900" >{{ data?.obsolence ? 'Yes' : 'No' }} {{data?.obsolenceMonths !== null && data?.obsolenceMonths !== 0 ? data?.obsolenceMonths + ' Months': ''}}</div>
                              </div>
                              <div fxFlex="50" fxFlex.lt-sm="100">
                                <div class="text-grey-500" >Warranty (in months)</div> 
                                <div class="text-grey-900" >{{data?.warrentyMonths}} </div>
                              </div> -->
                <div fxFlex="100" fxFlex.lt-sm="100">
                  <div class="text-grey-500">Remarks</div>
                  <div class="text-grey-900">{{ data?.remarks }}</div>
                </div>
                <!-- <div *ngIf="data?.notBid !== 1" fxFlex="100" fxLayoutAlign="end">
                  <button
                    class="m-r-8 action-button"
                    mat-stroked-button
                    *ngIf="status == 'Closed' && data.status !== 'Awarded'"
                    (click)="awardTender(data?.productId)"
                  >
                    Award
                  </button>
                </div> -->
              </div>
            </div>
          </ng-container>
        </div>

      </form>
      <!-- <div fxFlex="100" fxLayout="row wrap" fxLayoutGap="20px grid">
            <div fxFlex="50" fxFlex.lt-sm="100">
              <div style="padding: 10px 0px; font-weight: bold; font-size: 14px; line-height: 19px; color: #22181C;" >Pricing Summary</div>
            </div>
          </div> -->
      <!-- <ng-container *ngFor="let data of buyerDetails?.tenderResponsePricingSummaryDTOList">
            <div fxFlex="100"
                  fxLayout="row wrap"
                  class="w-full"
                  style="border: 1px solid #DBDBDB; margin: 10px 0px;">
              <div fxLayout="row wrap"
                    fxFlex="100"
                    fxLayoutGap="20px grid"
                    style="background-color: #F9F9F9;padding:10px 10px 0px 10px">
                <div fxFlex="100"
                      fxLayout="row wrap">
                  <span class="key-style">Description :&nbsp;</span>
                  <span class="value-style word-break">{{data?.description}}</span>
                </div>
              </div>
              
              <div fxLayout="row wrap"
                    fxFlex="100"
                    fxLayoutGap="20px grid"
                    style="padding:20px 10px 10px 10px;">

                <div fxFlex="50"
                      fxFlex.lt-sm="100">
                  <div class="text-grey-500">Price</div>
                  <div class="text-grey-900"> {{  data?.price  }} </div>
                </div>
                <div fxFlex="50" fxFlex.lt-sm="100">
                  <div class="text-grey-500" >Currency</div> 
                  <div class="text-grey-900" > {{ data?.currencyName }}</div>
                </div>
                <div fxFlex="100"
                      fxFlex.lt-sm="100">
                  <div class="text-grey-500">Remarks</div>
                  <div class="text-grey-900">{{data?.pricingRemarks}} </div>
                </div>
              </div>
            </div>
          </ng-container> -->

      <div fxFlex="100" fxLayout="row wrap">
        <div class="text-view-heading m-t-32" fxFlex="100">Supplier Note to Buyer</div>
        <div class="text-grey-900" fxFlex="100">{{ buyerDetails?.note | dashIfEmpty }}</div>
      </div>

      <div class="m-t-32" fxFlex="100" fxLayout="row wrap" fxLayoutGap="30px grid">
        <div class="text-view-heading m-t-32" fxFlex="100">Document Details</div>
        <!-- <div fxFlex="50" fxFlex.lt-sm="100">
                <div class="text-grey-500" >File name of the product</div> 
                <div class="text-grey-900" > {{buyerDetails?.filename != undefined && buyerDetails?.filename != '' && buyerDetails?.filename != null ? buyerDetails?.filename : '-'}} </div>
            </div> -->
        <div *ngIf="
            buyerDetails?.listOfAttachments !== undefined &&
            buyerDetails?.listOfAttachments.length > 0
          " fxFlex="100" fxFlex.lt-sm="100">
          <div class="text-grey-500">Attachments <button id="no-print" *ngIf="hasResponseAttachments" class="mx-2"
              color="primary" mat-raised-button style="cursor: pointer; margin-left: 1em;"
              (click)="downloadAll()">Download all</button></div>
          <div class="value-name m-t-8" fxLayout="row wrap" fxLayoutGap="10px">
            <div *ngFor="let data of buyerDetails?.listOfAttachments">
              <div *ngIf="data != ''" class="text-grey-900 ">
                <span matPrefix><mat-icon class="icon-12">attach_file</mat-icon>&nbsp;</span>
                <span style="text-decoration: underline;cursor: pointer;" (click)="downloadFile(data)">{{ data |
                  customDateRemover }}</span>
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="50" *ngIf="buyerDetails?.link != null" fxFlex.lt-sm="100">
          <div class="text-grey-500">Website url</div>
          <div class="text-grey-900 ">
            <a style="color:red; text-decoration: underline;" href="https://{{ buyerDetails?.link }}" target="_blank">{{
              buyerDetails?.link | dashIfEmpty }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  *ngIf="data?.screen === 'award' || data?.screen === 'secondary-award' || data?.screen === 'superManager'"
  align="center">
  <button class="m-r-8 cancel-button" (click)="dialogclose()" mat-stroked-button>Cancel</button>
  <button class="m-r-8 action-button" mat-stroked-button (click)="print()">Print</button>
  <button class="m-r-8 action-button" mat-stroked-button (click)="sendMessage()">
    Send Message
  </button>
  <button class="m-r-8 action-button" mat-stroked-button
    *ngIf="['Closed','Awarded'].includes(status) && data?.screen !== 'secondary-award' && data?.screen !== 'superManager' && !editResponsebtn"
    (click)="editResponse()">
    Edit Response
  </button>
  <button class="m-r-8 action-button" mat-stroked-button *ngIf="['Closed','Awarded'].includes(status)"
    (click)="awardTender()">
    Submit
  </button>
</mat-dialog-actions>
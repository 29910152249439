import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';

@Injectable()
export class WebsocketService {

    // Open connection with the back-end socket
    WS_BASE_URI: string = environment.websocketBaseUrl;
    private stompClient = null;
    public wsConnected: boolean;
    public responsestompData: any;
    callback: any;
    userID: string;
    constructor() {
        this.wsConnected = false;
    }
    /*
       * WebSocket connection establishment
       * for auto refresh the data on UI, whenever
       * data was updated.
       */
    initializeWebSocket(subscriberName: any, messageName: any, userID, callback: any) {
        const socket = new SockJS(this.WS_BASE_URI);
        this.stompClient = Stomp.over(socket);
        this.stompClient.heartbeat.outgoing = 10000;
        this.stompClient.heartbeat.incoming  = 10000;
        const _this = this;
        this.stompClient.connect({}, function (frame) {
            _this.userID = userID;
             _this.stompClient.send(messageName, {"content-type": "application/json"}, JSON.stringify({"userId": _this.userID}));
            _this.stompClient.subscribe(subscriberName, function (data) {
                _this.callback = callback;
                if (_this.callback != null && typeof _this.callback === 'function') {
                    return _this.callback(JSON.parse(data?.body));
                }
            });
        });

    }

    sendData(topicName, data) {
        this.stompClient.send(
            topicName,
            {},
            JSON.stringify({ 'data': data })
        );
    }

    sendUserID(topicName, userID) {
        this.stompClient.send(
            topicName,
            {"content-type": "application/json"},
            JSON.stringify({ 'userId': userID })
        );
    }

    disconnect() {
        if (this.stompClient != null) {
            this.stompClient.unsubscribe();
            this.stompClient.disconnect();
        }
    }

}

<div>
    <h2 mat-dialog-title>Please enter conversion rates <span><mat-icon (click)="dialogclose()" class="float-right cursor-pointer icon-18">close</mat-icon> </span> </h2>
</div>
<mat-dialog-content class="mat-typography price-conversion">
    <div *ngIf="currencyForm !== undefined" class="p-b-32" fxFlex="100"  fxLayout="row wrap">
        <div fxFlex="100" class="m-t-8" fxLayout="row wrap" >
            <div class="heading" fxLayoutAlign="start center" fxFlex="43">
              Currencies in the responses
            </div>
            <div class="heading" fxLayoutAlign="start center" fxFlex="47" >
               Corresponding Singapore Dollar
            </div >
         </div>
        <form novalidate [formGroup]="currencyForm" class="w-full">
            <div fxFlex="100"  class="m-t-8" *ngIf="currencyList.length > 0" >
                    <div formArrayName="currency" *ngFor="let item of currencyList;let i = index"  fxLayout="row" >
                        <div [formGroupName]="i" fxLayout="row "  class="m-t-8"  fxFlex="100" >
                            <div fxFlex="43" fxFlex.xs="100">
                                <mat-form-field class="form-field-style" appearance="outline">
                                    <input matInput value="{{item.currency}}"  formControlName="currencyName" style="cursor : default; font-weight: bold;"
                                        readonly>
                                </mat-form-field>
                            </div>
                            <div fxFlex="47" fxFlex.xs="100">
                                <mat-form-field class="form-field-style" appearance="outline">
                                    <input matInput formControlName="currencyRate"  >
                                    <mat-error *ngIf="hasError('currencyRate', 'pattern', i)">{{ 'decimal-invalid1' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
            </div>   
        </form>
    </div>  
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button *ngIf="openFor == 'products-services'" class="m-r-8 action-button" (click)="exportToExcel()" mat-stroked-button >Convert and Export</button>
    <button *ngIf="openFor == 'pricing-summary'" class="m-r-8 action-button" (click)="exportPricingSummaryToExcel()" mat-stroked-button >Convert and Export</button>
</mat-dialog-actions>

<div fxLayout="row wrap" fxLayout.lt-sm="column" class="login" >
  <div fxFlex.lt-sm="100" fxFlex="70" >
      <div class="m-24" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- <img src="assets/images/session/STE Land Systems logo.png" class="app-logo" [routerLink]="['/']" alt="STE"> -->
        <img src="../../../../assets/images/session/STE Land Systems logos.png" [ngStyle.gt-lg]="{'width.px': 240}"  [ngStyle.lt-lg]="{'width.px': 180}" class="app-logo"
        [routerLink]="['/']"  alt="STE">
      </div>
      <div class="m-y-8" fxLayout="row" fxLayoutAlign="center center">
        <h2 class="welcome">Hi, Welcome!</h2>
      </div>
      <div class="" fxLayout="row" fxLayoutAlign="center center">
        <div class="alert alert-danger" role="alert" *ngIf="hasDangerAlert">
          <span class="iconmoon-info"></span>&nbsp;{{errorMsg}}
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px"  style="max-width: 380px;">
        <form novalidate class="app-form-horizontal" [formGroup]="loginForm">
          <mat-form-field  appearance="outline">
            <span matPrefix >
              <img src="assets/images/session/user-icon.png"  alt="username">
            </span>
            <input tabindex="1" matInput formControlName="username" class="label-input" placeholder="{{'email-id' | translate}}">
            <mat-error *ngIf="hasError('username', 'pattern')">{{ 'email-address-invalid' |translate }}</mat-error>
            <mat-error *ngIf="hasError('username', 'required')">{{ 'login-emailRequired' | translate}}</mat-error>
          </mat-form-field>
          <mat-form-field  appearance="outline">
            <span matPrefix >
              <img src="assets/images/session/password-icon.png"  alt="password">
            </span>
            <input tabindex="2" matInput formControlName="password"  class="label-input"  placeholder='{{"password" | translate}}' type="password">
            <mat-error *ngIf="hasError('password', 'required')">{{ 'login-userPasswordRequired' | translate}}</mat-error>
          </mat-form-field>
          <div class="p-y-16 f-w-600" fxLayout="row" fxLayoutAlign="center center" >
            <!-- <a routerLink="/auth/forgot-password" style="color:#E30613;" >Forgot Password?</a> -->
            <a  href="javascript:void(0);" style="color:#E30613;" (click)="forgotPassword()" >Forgot Password?</a>

          </div>
          <div class="m-y-16" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
            <button class="cancel-button" type="button" (click)="cancelClick()" mat-stroked-button >{{"cancel-btn" | translate}}</button>
            <button class="submit-button" type="submit" tabindex="3" [ngStyle]="{'opacity':!loginForm.valid ? '0.7' : '1' }" [disabled]="!loginForm.valid" (click)="onSubmit()"  mat-stroked-button >{{"submit-btn" | translate}}</button>
          </div>
        </form>
      </div>
      <div fxLayout="row" *ngIf="sBrowser === 'IE'">
        <div class="alert alert-warning" role="alert">
          This page is best viewed on Desktop with Chrome.
        </div>
      </div>
    </div>
    <div fxFlex.lt-sm="100" fxFlex="30" >
      <div  class="register-intro p-x-48 text-center"   fxLayout="row wrap"  fxLayoutAlign="space-around center" >
          <h1 fxHide.lt-sm="true" > Join Us</h1>
          <p fxHide.lt-sm="true">Grow you business by joining hands with us.
            Upload your Product Information.
          </p>
          <p fxHide.lt-sm="true" class="f-w-600" > One simple platform to view our requirements and to partner with us.</p>
          <p fxHide.lt-sm="true"> For more information visit our website <a href="https://www.stengg.com/" target="_blank" >https://www.stengg.com/</a></p>
          <div class="m-t-16" >
            <button class="register-intor-button" [routerLink]="['/auth/register']" mat-stroked-button>{{"register-btn" | translate}}</button>
          </div>
      </div>
    </div>
  </div>


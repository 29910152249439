import { Component, OnInit, Optional, Inject, ViewChild, ElementRef , OnDestroy } from '@angular/core';
import { ServiceConfigs, HttpService, SessionsService } from '@core';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { SmartDialog } from 'app/shared/components/smart-dialog/smart-dialog';
import { takeUntil } from 'rxjs/operators';
import { CustomDateRemoverPipe } from '@shared/pipes/custom-date-remover.pipe';
import { Regex } from '@core/regex';
import { Helper } from '@core/helper';
import { CategoryBySubCategory, ProductViewResponse } from '@core/interfaces/supplier-app-service-config';
import { ProcurementCategory } from '@core/interfaces/market-place';

 
 
@Component({
  selector: 'app-edit-view-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class EditProductComponent implements OnInit, OnDestroy {

  @ViewChild('fileUpload') fileUpload: ElementRef;
  public productForm: UntypedFormGroup;
  public categoryList: ProcurementCategory[];
  public subcategoryList: CategoryBySubCategory[];
  public formDataList: any = [];
  public productID: any;
  public companyID: any;
  public userID: any;
  public attachments: any;
  public editFileList: any;
  public deleteFileList: any = [];
  public fileNameList: any = [];
  public fileSetList: any = [];
  public isExistAttachDelete: boolean = false;
  public categoryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public subCategoryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredCategoryTypes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredSubCategoryTypes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();


  constructor(
    private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
    private toastr: ToastrServiceClass,
    private sessionService: SessionsService,
    private dialogRef: MatDialogRef<EditProductComponent>,
    private smartDialog: SmartDialog,
    private dateRemover: CustomDateRemoverPipe,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  // life cycle hooks

  ngOnInit() {
    this.productID = this.data.id;
    this.companyID = this.data.companyId;
    this.userID = this.data.userId;
    this.buildProductForm();
    this.getcategoryList();
    this.getByID();
    this.categoryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCategoryType();
      });
    this.subCategoryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSubCategoryType();
      });
    this.isExistAttachDelete = false;
    this.deleteFileList = [];
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }  

  // functions

  public buildProductForm() {
    this.productForm = new UntypedFormGroup({
      productName: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.alphaNumericPatternProduct)]),
      category: new UntypedFormControl('', [Validators.required]),
      subcategory: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl('', [Validators.required]),
      uploadAttachements: new UntypedFormControl('', [Validators.required]),
      hyperLink: new UntypedFormControl('', [Validators.pattern(Regex.websiteURLPatternMarketPlace)]),
      partNumber: new UntypedFormControl('', [Validators.pattern(Regex.alphaNumericPatternProduct)]),
      fileData: new UntypedFormControl('')
    });
  }

  public getByID() {
    const id = this.productID;
    if (id) {
      const requestObject = '?productId=' + id;
      this.httpService.invokeService(this.serviceConfigs.SUPPLIER_APP_SERVICES_CONFIG.GETBYID_PRODUCT_DATA, requestObject).then((response: ProductViewResponse) => {
        if (response && response?.isError === false) {

          if (response?.payload?.categoryId !== undefined) {
            this.getsubCategoryListEdit(response?.payload?.categoryId);
          }
          this.attachments = response?.payload?.listOfAttachments;
          this.editFileList = response?.payload?.listOfAttachments;
          this.productForm.patchValue({ productName: response?.payload?.productName });
          this.productForm.patchValue({ category: response?.payload?.categoryId });
          this.productForm.patchValue({ subcategory: response?.payload?.subCategoryId });
          this.productForm.patchValue({ description: response?.payload?.productDescription });
          this.productForm.patchValue({ uploadAttachements: this.attachments[0] });
          this.productForm.patchValue({ hyperLink: response?.payload?.url });
          this.productForm.patchValue({ partNumber: response?.payload?.partNo });

        } else if (response && response?.isError === true) {
          
        }
      });
    }
  }

  public getcategoryList() {
    const requestObject = '';
    this.httpService.invokeService(this.serviceConfigs.ADD_PRODUCT_SERVICES_CONFIG.GET_PRODUCTS_CATEGORY, requestObject).then((response: any) => {
      if (response) {
        this.categoryList = response?.payload;
        this.filteredCategoryTypes.next(this.categoryList.slice());
      }
    });
  }

  public getsubCategoryList(event: any) {
    const requestObject = '?categoryId=' + event.value;
    this.httpService.invokeService(this.serviceConfigs.ADD_PRODUCT_SERVICES_CONFIG.GET_PRODUCTS_SUBCATEGORY, requestObject).then((response: any) => {
      if (response) {
        this.subcategoryList = response?.payload;
        this.filteredSubCategoryTypes.next(this.subcategoryList.slice());
        this.productForm.get('subcategory').setValue('');
      }
    });
  }

  public getsubCategoryListEdit(id: any) {
    const requestObject = '?categoryId=' + id;
    this.httpService.invokeService(this.serviceConfigs.ADD_PRODUCT_SERVICES_CONFIG.GET_PRODUCTS_SUBCATEGORY, requestObject).then((response: any) => {
      if (response) {
        this.subcategoryList = response?.payload;
        this.filteredSubCategoryTypes.next(this.subcategoryList.slice());
      }
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.productForm.controls[controlName].hasError(errorName);
  }

  public UpdateProduct() {
    if (this.productForm.valid === true) {
      this.toastr.loaderShow();
      if (this.isExistAttachDelete === true) {
        this.hardDeleteFile(this.deleteFileList);
      }
      // if (this.formDataList.length > 0) {
      //   const formData = new FormData();
      //   this.formDataList.forEach(element => {
      //       formData.append('file', element.file, element.name );
      //   });
      //   this.UploadFile(formData);
      // }
      
      const request = {
        categoryId: this.productForm.value.category,
        subCategoryId: this.productForm.value.subcategory,
        productName: this.productForm.value.productName,
        url: this.productForm.value.hyperLink,
        partNo: this.productForm.value.partNumber,
        listOfAttachments: Helper.removeStringEmptySpace(this.attachments, true),
        productDescription: this.productForm.value.description,
        isActive: true,
        userId: this.userID,
        companyId: this.companyID,
        id: this.productID
      };
       
      this.httpService.invokePutBody(this.serviceConfigs.SUPPLIER_APP_SERVICES_CONFIG.UPDATE_PRODUCT_DATA, request).pipe(takeUntil(this._onDestroy)).subscribe((data: any) => {
        try {
          if (data) {
            if (data.body.isError === false) {
              this.toastr.success(data?.body?.message);
              this.dialogRef.close(true);
              // this.router.navigate(['/products-services/products']);
            } else {
              this.toastr.error(data?.body?.message);
            }
          }
          this.toastr.loaderHide();
        } catch (ex) {
           
          this.toastr.loaderHide();
        }
      }, (err) => {
          
        this.toastr.loaderHide();
      }, () => {
         
        this.toastr.loaderHide();
      });
    } else {
      this.validateAllFormFields(this.productForm);
    }
  }

  public validateAllFormFields(formGroup: UntypedFormGroup) {
    this.toastr.info('Please fill all the Required fields.');
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public AttachmentUplaod(event: any) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    const allowedExtensions = /(\.pdf|\.xls|\.xlsx)$/i;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        if (!allowedExtensions.exec(files[i].name)) {
          this.toastr.error('Only .pdf , .xls and .xlsx supported !');
          input.value = '';
          return;
        }
      }
    }
    let i = 0;
    const file = event.target.files[0];
    if (!Helper.validateFilename(file.name)) {
      this.toastr.error("File name should not contain these special characters @$%&\/:?\"'<>|~`#^={}[];!");
      return;
    } else {
      if (file && file != null && file.size > 10615705) {
        this.toastr.info('The attachment file should not be more than 10 MB size');
        this.toastr.loaderHide();
        return false;
      }
      this.fileNameList = [];
      if (event.target.files.length > 5 || this.attachments.length > 3) {
        this.toastr.info('You can upload only Three files');
      } else if ((event.target.files.length + this.attachments.length) > 3) {
        this.toastr.info('You can upload only Three files');
      } else {
        const files = event.target.files;
        setTimeout(() => {
          this.toastr.loaderShow('Scanning in progress...');
        }, 250);

        for (i = 0; i < files.length; i++) {
          const file = files[i];
          const formData = new FormData();
          formData.append('file', file, file.name);
          const requestFile = formData;
          this.httpService.invokeScanFileUpload(this.serviceConfigs.FILE_SCAN_SERVICES_CONFIG.UPLOAD, requestFile).pipe(takeUntil(this._onDestroy)).subscribe((data: any) => {
            try {
              if (data && data?.type !== 0) {
                if (data.body.isError === false) {
                  this.fileNameList.push(file.name);
                  if (this.formDataList.length > 0) {
                    const tempList = this.formDataList.filter(data => data.name === file.name);
                    if (tempList !== undefined && tempList.length === 0) {
                      this.formDataList.push({ name: file.name, file: file });
                    }
                  } else {
                    this.formDataList.push({ name: file.name, file: file });
                  }
                  this.fileSetList = [...new Set(this.fileNameList)];
                  this.attachments = [...this.attachments, ...new Set(this.fileSetList)];
                  this.attachments = [...new Set(this.attachments)];
                  this.productForm.controls.fileData.setValue(file);
                  this.productForm.controls.uploadAttachements.setValue(file.name);
                  this.saveScanAduit(data?.body?.message, file.name, 'File Scan');
                } else {
                  this.toastr.error(data?.body?.message);
                  this.saveScanAduit(data?.body?.message, file.name, 'File Scan');
                }
              }
              setTimeout(() => {
                this.toastr.loaderHide();
              }, 500);
            } catch (ex) {
              
              setTimeout(() => {
                this.toastr.loaderHide();
              }, 500);
            }
          }, (err) => {
              
            setTimeout(() => {
              this.toastr.loaderHide();
            }, 500);
          }, () => {
            
            setTimeout(() => {
              this.toastr.loaderHide();
            }, 500);
          });
          setTimeout(() => {
            this.toastr.loaderHide();
          }, 500);
        }
      }
    }
  }

  public confirmDelete(data) {
    this.smartDialog.open({
      title: 'Confirm delete',
      description: 'Are you sure want to delete the File?',
      buttons: [
        {
          type: '',
          text: 'Cancel',
          onClick: () => {
            return;
          },
        },
        {
          type: 'primary',
          text: 'Delete',
          onClick: () => {
            this.isExistAttachDelete = true;
            this.editFileList.forEach(element => {
              if (element === data) {
                this.deleteFileList.push(data);
              }
            });
            this.attachments = this.attachments.filter((element) => (data !== element));
            if (this.attachments.length === 0) {
              this.productForm.controls.uploadAttachements.patchValue('');
            }
            let msg = "The following file deleted successfully!";
            this.saveScanAduit(msg, data, 'File Delete');
          },
        }
      ],
    });
  }

  public hardDeleteFile(attachmentList: any) {
    attachmentList.forEach(element => {
      element.trim();
      if (element.indexOf('000+0000/') > -1) {
        const requestObject = '?filename=' + encodeURIComponent(element) + '&companyId=' + this.companyID;
        this.httpService.invokeDelete(this.serviceConfigs.SUPPLIER_APP_SERVICES_CONFIG.DELETE_PRODUCT_DATA, requestObject).pipe(takeUntil(this._onDestroy)).subscribe((data: any) => {
          try {
            if (data && data?.isError === false) {
              // this.toastr.success(data?.message);
              // this.attachment1 = '';
            } else if (data?.message === true) {
              this.toastr.error(data?.message);
            }
          } catch (ex) {
            this.toastr.error(ex);
          }
        });
      }
    });
  }

  public downloadFile(fileName: any) {
    fileName.trim();
     
    this.toastr.loaderShow();
    const request = '?filename=' + encodeURIComponent(fileName) + '&companyId=' + this.companyID;
    this.httpService.invokePostWithParams(this.serviceConfigs.ADD_PRODUCT_SERVICES_CONFIG.DOWNLOAD_FILE, request).pipe(takeUntil(this._onDestroy)).subscribe((data: any) => {
      try {
        if (data) {
          if (data?.isError === false) {
            if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
              Helper.downloadIE(data?.payload, this.dateRemover.transform(fileName));
            } else {
              Helper.download(data?.payload, this.dateRemover.transform(fileName));
            }

          } else {
            this.toastr.error(data?.message);
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {
         
        this.toastr.loaderHide();
      }
    }, (err) => {
        
      this.toastr.loaderHide();
    });
  }
   
  public UploadFile(formData: any) {
    const requestFile = formData;
    this.httpService.invokeFileUploadForRegisterSupplierManager(this.serviceConfigs.ADD_PRODUCT_SERVICES_CONFIG.PRODUCT_UPLOAD, requestFile, this.companyID).subscribe((data: any) => {
      try {
        if (data && data?.type !== 0) {
          if (data.body.isError === false) {
             
          } else {
            this.toastr.error(data?.body?.message);
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {
         
        this.toastr.loaderHide();
      }
    }, (err) => {
        
      this.toastr.loaderHide();
    });
  }

  public dialogclose() {
    this.dialogRef.close(true);
  }

  public saveScanAduit(msg: any, fileName: any, type: any) {
    const request = {
      tableKey: this.sessionService.getUserId(),
      email: this.sessionService.getUserMailId(),
      description: msg,
      fileName: fileName,
      action: type
    }
    this.httpService.invokePost(this.serviceConfigs.FILE_SCAN_SERVICES_CONFIG.SCAN_AUDIT, request).pipe(takeUntil(this._onDestroy)).subscribe((data: any) => {
      try {
        if (data) {
          if (data.body.isError === false) {
            this.toastr.success(data?.body?.message);
          } else {
            this.toastr.error(data?.body?.message);
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {
         
        this.toastr.loaderHide();
      }
    }, (err) => {
        
      this.toastr.loaderHide();
    }, () => {
       
      this.toastr.loaderHide();
    });

  }

  protected filterCategoryType() {
    if (!this.categoryList) {
      return;
    }
    // get the search keyword
    let search = this.categoryFilterCtrl.value;
    if (!search) {
      this.filteredCategoryTypes.next(this.categoryList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCategoryTypes.next(
      this.categoryList.filter(data => data.categoryName.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterSubCategoryType() {
    if (!this.subcategoryList) {
      return;
    }
    // get the search keyword
    let search = this.subCategoryFilterCtrl.value;
    if (!search) {
      this.filteredSubCategoryTypes.next(this.subcategoryList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredSubCategoryTypes.next(
      this.subcategoryList.filter(data => data.subCategoryName.toLowerCase().indexOf(search) > -1)
    );
  }
}

import { Component, OnInit, ViewEncapsulation, Input, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy , OnDestroy } from '@angular/core';
import { MenuService } from '@core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

 
 
@Component({
  selector: 'page-header',
  host: {
    class: 'app-page-header',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title: any = '';
  @Input() subtitle: any = '';
  @Input() nav: string[] = [];
  @Input() showBreadCrumb: boolean = true;
  public routeTitle: any = '';
  public routerEventSub: Subscription;
  constructor(private router: Router, private menuService: MenuService, private cd: ChangeDetectorRef) {}

  // life cycle hooks

  ngOnChanges() {
    this.routerEventSub = this.router.events
    .pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
            const states = this.router.url.slice(1).split('/');
            this.nav = this.menuService.getMenuLevel(states);
            this.title = this.nav[this.nav.length - 1];
            this.routeTitle = this.nav[this.nav.length - 1];
            this.cd.markForCheck();
     });
  }

  ngOnInit() {
    this.nav = Array.isArray(this.nav) ? this.nav : [];
    if (this.nav.length === 0) {
      this.genBreadcrumb();
    }
    this.title = this.nav[this.nav.length - 1];
    this.routeTitle = this.nav[this.nav.length - 1];
  }

  ngOnDestroy() {
    this.routerEventSub.unsubscribe();
  }

  // functions

  public genBreadcrumb() {
    const states = this.router.url.slice(1).split('/');
    this.nav = this.menuService.getMenuLevel(states);
    this.nav.unshift('home');
  }
}

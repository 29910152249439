import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, Validators, FormGroup, FormControl } from '@angular/forms';
import { HttpService, ServiceConfigs } from '@core';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-price-edit',
  templateUrl: './price-edit.component.html',
  styleUrls: ['./price-edit.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class PriceEditComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public priceForm: UntypedFormGroup;
  public prodName: String;
  public prodQty: Number;
  public prodId: String;
  public unitPrice: String;
  public responseId: String;
  public tenderId: String;
  constructor(
    private dialogRef: MatDialogRef<PriceEditComponent>,
    private httpService: HttpService,
    private toastr: ToastrServiceClass,
    private serviceConfigs: ServiceConfigs,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  // life cycle hooks

  ngOnInit() {
    this.buildForm();
    this.prodId = this.data.productId;
    this.prodName = this.data.productName;
    this.prodQty = this.data.productQty;
    this.unitPrice = this.data.unitPrice;
    this.responseId = this.data.responseId;
    this.tenderId = this.data.tenderId;
    this.priceForm.patchValue({
      unitPrice: this.unitPrice,
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions 
  
  public hasError = (controlName: string, errorName: string, formArrayIndex?: number) => {
    if (this.priceForm !== undefined) {
      // const formArray = this.currencyForm.get('currency') as UntypedFormArray;
      // const formGroup = formArray.controls[formArrayIndex] as UntypedFormGroup;
      if (this.priceForm.controls[controlName] !== undefined && errorName !== undefined) {
        return this.priceForm.controls[controlName].hasError(errorName);
      }
    }
  }

  public buildForm() {
    this.priceForm = new FormGroup({
      unitPrice: new FormControl('', Validators.required),
    })
  }

  public editProductPrice() {
    if (this.priceForm.valid) {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      const request = {
        id: user.userId,
        tenderResponseId: this.responseId,
        productId: this.prodId,
        unitPrice: this.priceForm.value.unitPrice,
        tenderId: this.tenderId,
      }
      this.httpService.invokePost(this.serviceConfigs.BUYER_APP_SERVICES_CONFIG.EDIT_UNIT_PRICE, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        if (data.body.isError === false) {
          this.toastr.success(data?.body?.message);
          this.dialogclose('submit');
        } else {
          this.toastr.error(data?.body?.message);
        }
      });

    }
  }

  public dialogclose($event: any) {
    this.dialogRef.close({
      event: $event
    });
  }

}

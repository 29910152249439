import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round'
})

export class RoundPipe implements PipeTransform {
  transform(value: any, decimalPlaces: number = 2): string | null {
    return roundToDecimalPlaces(value, decimalPlaces);
  }
}

export function roundToDecimalPlaces(value: number, decimalPlaces: number = 2): string | null {
  const numericValue = parseFloat(value as any);
  if (isNaN(numericValue)) {
    return null;  // Handle non-numeric values as necessary
  }
  // Use toFixed to return a string with the required decimal places, even for integers
  return numericValue.toFixed(decimalPlaces);
}
import { NgModule, Optional, SkipSelf , OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { DefaultInterceptor, ErrorInterceptor } from '@core';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [],
  imports: [CommonModule,
    HttpClientModule,
  // 3rd party
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [HttpClient]
    }
 })],
 providers: [
  { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  exports: [TranslateModule]

})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, translate: TranslateService) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
    translate.setDefaultLang('en');
    translate.use('en');
  }


}

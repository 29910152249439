<div class="w-full h-full home" style="background-color: #fff;">
<div fxLayout="row wrap" class="home-header" >
  <div fxFlex fxFlex.lt-sm="100" >
      <img src="../../../assets/images/session/STE Land Systems logos.png" [ngStyle.gt-lg]="{'width.px': 240}"  [ngStyle.lt-lg]="{'width.px': 180}" class="ste-logo" alt="STE">
  </div>
  <div class="text-right" fxFlex="50" fxFlex.lt-sm="100" >
    <button class="login-form-button cursor-pointer m-r-8" [routerLink]="['/auth/login']" mat-stroked-button>{{"login-space-btn" | translate}}</button>
    <button class="register-form-button cursor-pointer"  mat-raised-button color="primary" [routerLink]="['/auth/register']" >{{"register-as-supplier" | translate}}</button>
  </div>
</div>
<div fxLayout="row wrap" fxLayoutAlign="space-around start" class="background-gradient" >
  <div fxFlex="90" fxFlex.lt-sm="100" >
    <mat-card appearance="outlined" style="box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);">
    <mat-tab-group [selectedIndex]="0" mat-align-tabs="center">
      <mat-tab label="ANNOUNCEMENTS">
           <div class="p-8" class="body-height">
             <!-- <div >
                <div fxLayout="row" >
                  <div fxLayoutAlign="center"> <img src="assets/images/icons/announce-icon.svg" class="announce-icon" ></div>
                  <div fxLayoutAlign="center" fxLayout="column" class="link-box">
                      <div>ST Engineering Transfers Balance Stake in LeeBoy India</div>
                      <div><a href="https://www.stengg.com/en/newsroom/news-releases/st-engineering-transfers-balance-stake-in-leeboy-india/"
                        target="_blank" >https://www.stengg.com/en/newsroom/news-releases/</a></div>
                  </div>
                </div>
                <div fxLayout="row" >
                    <div fxLayoutAlign="center"> <img src="assets/images/icons/announce-icon.svg" class="announce-icon"></div>
                    <div fxLayoutAlign="center" fxLayout="column" class="link-box">
                        <div>ST Engineering Divests Takata CPI Singapore Pte Ltd</div>
                        <div><a href="https://www.stengg.com/en/newsroom/news-releases/st-engineering-divests-takata-cpi-singapore-pte-ltd/"
                          target="_blank" >https://www.stengg.com/en/newsroom/news-releases/</a></div>
                    </div>
                </div>
                <div fxLayout="row" >
                    <div fxLayoutAlign="center"> <img src="assets/images/icons/announce-icon.svg" class="announce-icon"></div>
                    <div fxLayoutAlign="center" fxLayout="column" class="link-box">
                        <div>ST Engineering Sets Up Entity in Israel</div>
                        <div><a href="https://www.stengg.com/en/newsroom/news-releases/st-engineering-sets-up-entity-in-israel/"
                          target="_blank" >https://www.stengg.com/en/newsroom/news-releases/</a></div>
                    </div>
                </div>
                <div fxLayout="row" >
                  <div fxLayoutAlign="center"> <img src="assets/images/icons/announce-icon.svg" class="announce-icon"></div>
                  <div fxLayoutAlign="center" fxLayout="column" class="link-box">
                      <div>ST Engineering Sets Up Entity in Israel</div>
                      <div><a href="https://www.stengg.com/en/newsroom/news-releases/st-engineering-sets-up-entity-in-israel/"
                        target="_blank" >https://www.stengg.com/en/newsroom/news-releases/</a></div>
                  </div>
                </div>
                <div fxLayout="row" >
                  <div fxLayoutAlign="center"> <img src="assets/images/icons/announce-icon.svg" class="announce-icon"></div>
                  <div fxLayoutAlign="center" fxLayout="column" class="link-box">
                      <div>ST Engineering Sets Up Entity in Israel</div>
                      <div><a href="https://www.stengg.com/en/newsroom/news-releases/st-engineering-sets-up-entity-in-israel/"
                        target="_blank" >https://www.stengg.com/en/newsroom/news-releases/</a></div>
                  </div>
                </div>
             </div> -->
             <div *ngFor="let data of announceList">
              <div fxLayout="row" >
                <div fxLayoutAlign="center"> <img src="assets/images/icons/announce-icon.svg" class="announce-icon" ></div>
                <div fxLayoutAlign="center" fxLayout="column" class="link-box">
                    <div>{{data.title}}</div>
                    <div><a href="https://{{data.url}}" target="_blank" >{{data.url}}</a></div>
                    <div>{{data.description}}</div>
                </div>
              </div>
             </div>
             <div class="heading-style-watch margin-top-68" *ngIf="announceList.length === 0">
                 Watch this place...
             </div>
            <!-- <div class="m-t-16" fxLayout="row" fxLayoutAlign="end" style="color:#E30613;">
                 <div fxLayoutAlign="center">
                  <a href="https://www.stengg.com/en/newsroom/news-releases/"
                  target="_blank" style="color:#E30613;"  >View More </a>
                 </div>
                 <div fxLayoutAlign="center"   >
                  <mat-icon class="icon-18"  >play_arrow</mat-icon>
                 </div>
            </div> -->
          </div>
      </mat-tab>
      <mat-tab label="ABOUT US">
        <div class="about-us-content body-height">
          <p [innerHtml]= "aboutUs | nl2br"></p>
          <div class="heading-style-watch" fxLayoutAlign="space-around center" *ngIf="aboutUs === undefined || aboutUs === ''">
               Watch this place...
          </div>
        <!-- ST Engineering's Land Systems sector works with partners and customers to deliver customised land systems and security solutions that meet the stringent operational requirements of defence, homeland security and commercial customers. -->
      </div>
    </mat-tab>
    </mat-tab-group>
  </mat-card>
    <div fxLayout="row" fxLayoutAlign="start end" *ngIf="sBrowser === 'IE'">
      <div class="alert alert-warning" role="alert">
        This page is best viewed on Desktop with Chrome.
      </div>
    </div>
  </div>
  <!-- <div fxFlex="45" fxFlex.lt-sm="100" >
    <mat-card appearance="outlined" style="box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);">
      <div fxFlex="100" fxLayout="row wrap" fxLayoutGap="20px" fxLayoutAlign="start" >
        <div fxFlex="100" fxLayoutAlign="center" class="heading-style">REGISTER</div> -->
           <!-- <div class="line-item-style" fxLayoutGap="10px" fxLayout="row">
           <div><img src="assets/images/icons/home-tick.svg" alt="tick"></div>
           <div> Grow your business by becoming our supplier.</div>
           </div>
           <div class="line-item-style" fxLayoutGap="10px" fxLayout="row">
               <div><img src="assets/images/icons/home-tick.svg" alt="tick"></div>
               <div>Promote your company profile and upload categorized Products & Services e-catalogs.</div>
           </div>
           <div class="line-item-style" fxLayoutGap="10px" fxLayout="row">
             <div><img src="assets/images/icons/home-tick.svg" alt="tick"></div>
             <div>One simple platform to view our requirements and to partner with us.</div>
           </div> -->
           <!-- <div fxFlex="100" class="p-t-32" class="join-height" *ngIf="joinList.length !== 0" >
              <div *ngFor="let response of joinList" >
                 <div class="line-item-style" fxFlex="100" fxLayout="row" fxLayoutGap="10px" >
                    <div><img src="assets/images/icons/home-tick.svg" alt="tick"></div>
                    <div>{{response.title}}</div>
                 </div>
              </div>
           </div>
           <div fxLayoutAlign="space-around center" class="heading-style-watch join-height" fxFlex="80" *ngIf="joinList.length === 0">
              Watch this place...
           </div>
        <div fxFlex="100" fxLayoutAlign="center" class="p-y-16">
           <button class="register cursor-pointer" mat-stroked-button [routerLink]="['/auth/register']"> REGISTER NOW</button>
        </div>
     </div>
    </mat-card>
  </div> -->
</div>

<router-outlet></router-outlet>
<!-- <div class="app-content-inner" 
style="background: #ffffff; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); border-radius: 10px;padding: 16px;">
    <div fxLayout="row wrap" >
        <div fxFlex="100" >
            <nav class="page-nav"> 
                <ul>
                  <li routerLinkActive="active"> 
                    <a  routerLink="/" > Open (55)</a>
                  </li>
                  <li routerLinkActive="active"> 
                    <a  routerLink="rfq-tenders/closed" > Closed (100)</a>
                  </li>
                  <li routerLinkActive="active">
                     <a routerLink="/rfq-tenders/awarded"> Awarded (20)</a>
                  </li>
                </ul>
              </nav>
        </div>
        
    </div>
    
    <div fxLayout="row wrap" >   
        <div  fxFlex="100" >
          
          <div fxLayout="row wrap">
            <div fxFlex.gt-sm="100"
                 fxFlex.gt-xs="100"
                 fxFlex="100">
                <div class="search-form m-y-8 text-right">
                    <button class="filter-button"
                            mat-stroked-button
                            color=""
                            [popperTrigger]="'click'"
                            [popperHideOnClickOutside]="false"
                            [popperApplyClass]="'popper-filter-container-supplier'"
                            [popperPositionFixed]="false"
                            [popperPlacement]="'left'"
                            [popperShowOnStart]="false"
                            [popper]="filter"
                            [popperPreventOverflow]="false"><img src="assets/images/icons/funnel.svg"
                             class="svg"></button>
                    <popper-content #filter>
                        <div class="m-b-8 f-s-16 f-w-600 text-left">Filter By</div>
                        <div fxLayout="row wrap"
                             fxLayoutAlign="space-around center">
                            <div fxLayout="row wrap"
                                 fxLayoutAlign="space-around center">
        
                                <div fxFlex="100"
                                     fxFlex.lt-sm="100"
                                     class="text-left">
                                    <label>Bid Submission Date</label>
                                    <mat-form-field class="form-field-style"
                                                    appearance="outline">
                                        <div fxFlex="100"
                                             fxLayout="row">
                                            <div fxFlex="80"
                                                 fxLayoutAlign="start center">
                                                <input matInput
                                                       [readonly]="true"
                                                       [owlDateTime]="dt2"
                                                       placeholder="DD/MM/YYYY"
                                                       [(ngModel)]="query.bidSubmissionDate"
                                                       name="bidSubmissionDate">
                                            </div>
                                            <div fxFlex="20"
                                                 fxLayoutAlign="end center">
                                                <span [owlDateTimeTrigger]="dt2">
                                                    <mat-icon class="picker-icon cursor-pointer"
                                                              matDatepickerToggleIcon>date_range</mat-icon>
                                                </span>
                                            </div>
                                        </div>
                                        <owl-date-time #dt2
                                                       [pickerType]="'calendar'"></owl-date-time>
                                    </mat-form-field>
                                </div>
        
                            </div>
                            <div fxLayout="row wrap"
                                 fxLayoutAlign="center  center">
                                <button mat-raised-button
                                        class="m-r-8"
                                        (click)="resetFilter(); filter.hide()">Reset</button>
                                <button mat-raised-button
                                        color="primary"
                                        (click)="searchFilter(); filter.hide()">Search</button>
                            </div>
                        </div>
                    </popper-content>
                </div>
            </div>
        
            <div class="dash-card"
                 fxLayout="column"
                 fxLayoutGap="15px">
                <div *ngFor="let data of rfqTendorList">
                    <mat-card appearance="outlined" class="">
                        <mat-card-header class="f-s-14 f-w-600">
                            <div fxLayout="row wrap"
                                 fxLayoutAlign="start start"
                                 fxLayout.lt-sm="column"
                                 fxLayoutGap="10px">
                                <div>
                                    <mat-icon class="icon-12 tender-open ">fiber_manual_record</mat-icon> {{data.tenderName}}
                                </div>
                                <div *ngIf="data.status === 'inDraft'"
                                     class="draft-font">
                                    <span>
                                        <mat-icon class="icon-12 ">insert_drive_file</mat-icon>Saved draft of response
                                    </span>
                                </div>
                            </div>
                        </mat-card-header >
                        <mat-card-content>
                            <div fxLayout="row wrap">
                                <div fxFlex="80"
                                     fxFlex.lt-sm="100">
                                    <div fxLayout="row wrap"
                                         fxLayoutGap="8px grid">
                                        <div fxFlex="50"
                                             fxFlex.lt-sm="100">
                                            <span class="text-grey-500">Published Date :</span>
                                            <span class="text-grey-900"> {{data.publishedDate | date: 'dd MMM yyyy h:mm a'}}
                                            </span>
                                        </div>
        
                                        <div fxFlex="50"
                                             fxFlex.lt-sm="100">
                                            <span class="text-grey-500">Bid Submission End Date :</span> <span
                                                  class="text-grey-900">
                                                {{data.bidSubmissionDate | date: 'dd MMM yyyy h:mm a'}} </span>
                                        </div>
                                        <div fxFlex="50"
                                             fxFlex.lt-sm="100">
                                            <span class="text-grey-500">Procurement Category :</span>
                                            <span class="text-grey-900"> {{data.procurementCategory}} </span>
                                        </div>
                                        <div fxFlex="50"
                                             fxFlex.lt-sm="100">
                                            <span class="text-grey-500">Procurement Sub-Category :</span>
                                            <span class="text-grey-900"> {{data.procurementSubCategory}} </span>
                                        </div>
                                        <div fxFlex="50"
                                             fxFlex.lt-sm="100">
                                            <span class="text-grey-500">Tender / RFQ Status :</span>
                                            <span class="text-grey-900"> {{data.status}}</span>
                                        </div>
                                        <div fxFlex="50"
                                             fxFlex.lt-sm="100">
                                            <span class="text-grey-500">Buyer Name :</span>
                                            <span class="text-grey-900 "> {{data.primaryBuyerName}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div fxFlex="20"
                                     fxFlex.lt-sm="100"
                                     class="text-center p-t-16">
                                    <button (click)="view(data.id)"
                                            mat-raised-button
                                            color="primary">
                                        View Details
                                    </button>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div fxFlex="100"
                 fxLayoutAlign="space-around center"
                 style="text-align: center;"
                 *ngIf="rfqTendorList.length === 0">
                <span class="no-data-text">No Tender / RFQ items...</span>
            </div>
        </div> 
        </div>
    </div>

</div> -->
<div fxLayout="row wrap" class="footer-box"  fxLayoutAlign="end center" >
  <div  fxFlex="50" fxFlex.lt-sm="100">
    Copyright © {{ year }} ST Engineering
  </div>
  <div class="text-right footerlink" fxFlex="50" fxFlex.lt-sm="100"  >
    <a href="javascript:void(0)"  (click)="downloadTermsConditions()"> Terms & Conditions of Use </a> &nbsp; | &nbsp;
    <a href="javascript:void(0)" (click)="downloadPersonalPolicy()"> Personal Data Policy </a>
  </div>
</div>
</div>

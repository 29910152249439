 <!-- View Loader -->
 <div class="view-loader">
    <!-- <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div> -->
    <mat-spinner
    color="primary"
    strokeWidth="5" 
    [diameter]="50"
    ></mat-spinner>
    <!-- <h5 class="text-center" ></h5> -->
  </div>
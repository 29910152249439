import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { HttpService, ServiceConfigs } from '@core';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { ExcelService } from 'app/shared/services/excel.service';
import { Subject } from 'rxjs';
import { Regex } from '@core/regex';
import { PriceConversion } from '@core/interfaces/rfq-tender';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-price-popup',
  templateUrl: './price-popup.component.html',
  styleUrls: ['./price-popup.component.scss'],
  providers: [HttpService, ServiceConfigs, DatePipe]
})
export class PricePopupComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public currencyForm: UntypedFormGroup;
  public currencyList: any;
  public openFor: any;
  constructor(
    private dialogRef: MatDialogRef<PricePopupComponent>,
    private formBuilder: UntypedFormBuilder,
    private httpService: HttpService,
    private toastr: ToastrServiceClass,
    private xls: ExcelService,
    private serviceConfigs: ServiceConfigs,
    private datePipe: DatePipe,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  // life cycle hooks

  ngOnInit() {
    this.currencyList = this.data.currency;
    this.openFor = this.data.openFor;
    this.buildForm();
  }
  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions 

  public hasError = (controlName: string, errorName: string, formArrayIndex?: number) => {
    if (this.currencyForm) {
      const formArray = this.currencyForm.get('currency') as UntypedFormArray;
      const formGroup = formArray.controls[formArrayIndex] as UntypedFormGroup;
      if (formGroup.controls[controlName] && errorName) {
        return formGroup.controls[controlName].hasError(errorName);
      }
    }
  }

  public buildForm() {
    this.currencyForm = new UntypedFormGroup({});
    for (let task = 0; task < this.currencyList.length; task++) {
      if (task === 0) {
        this.currencyForm.addControl('currency', this.formBuilder.array([
          new UntypedFormGroup({
            countryName: new UntypedFormControl(this.currencyList[task].countryName ? this.currencyList[task].countryName : '', []),
            currencyName: new UntypedFormControl(this.currencyList[task].currency ? this.currencyList[task].currency : '', []),
            currencyRate: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.currencyRatePattern1)])
          })
        ]));
      } else {
        const currencyArrayForm: UntypedFormArray = this.currencyForm.get('currency') as UntypedFormArray;
        currencyArrayForm.push(new UntypedFormGroup({
          countryName: new UntypedFormControl(this.currencyList[task].countryName ? this.currencyList[task].countryName : '', []),
          currencyName: new UntypedFormControl(this.currencyList[task].currency ? this.currencyList[task].currency : '', []),
          currencyRate: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.currencyRatePattern1)])
        }));
      }
    }
  }

  public exportToExcel() {
    if (this.currencyForm.valid === true) {
      console.log("enters price popup component");
      const requestObject = '?tenderId=' + this.data.tenderId;
      const bodyContent = this.currencyForm.value.currency;
      let comparisonData = [];
      console.log("API request init");
      this.httpService.invokePostWithParamsAndBody(this.serviceConfigs.BUYER_APP_SERVICES_CONFIG.EXPORT_TO_EXCEL, requestObject, bodyContent).subscribe((data: any) => {
        try {
          if (data.body.isError === false) {
            // succeeded
            console.log("API success");
            this.dialogRef.close();

            if (data?.body && data?.body?.payload) {
              const excelDataForFile = data?.body?.payload
              // const excelDataForFile = [{"serialNumber":"1",
              //                   "companyName":"AOG Supply And Engineering Pte Ltd",
              //                   "partNumber":"0218497846",
              //                   "partName":"TAPE,LUMINOUS:ORANGE-RED COLOR 8W/R0:",
              //                   "qty":"18",
              //                   "currency":"USD",
              //                   "unitPrice":"310.0",
              //                   "totalPrice":"5580.0",
              //                   "unitPriceInSGD":"419.461",
              //                   "totalPriceInSGD":"7550.298",
              //                   "tenderName":"Tender/SYN/2024/000001009 1001863810-SUPPLY OF TAPE,LUMINOUS:ORANGE-RED"},
              //                 ];
              let tempData = [];
              let closingDate: string | null = null;
              for (const item of excelDataForFile) {
                  if (item.closingDate && item.closingDate !== '-') {
                      closingDate = item.closingDate;
                      break; // Exit the loop once we find the first valid closingDate
                  }
              }
              // Format the closing date if found
              const formattedClosingDate = closingDate 
                  ? this.datePipe.transform(closingDate, 'dd MMM yyyy h:mm a') 
                  : 'No closing date available'; // Handle the case when no valid closing date is available
              
              console.log(formattedClosingDate);
              // const formattedClosingDate = this.datePipe.transform(excelDataForFile[0]['closingDate'] || excelDataForFile[1]['closingDate'], 'dd MMM yyyy h:mm a');
              excelDataForFile.forEach((data: any, index) => {
                console.log(formattedClosingDate, "date");
                tempData.push({
                  'Serial Number': data['serialNumber'],
                  'Item No': data['itemNo'],
                  'Company Name': data['companyName'],
                  'Part Number': data['partNumber'],
                  'Part Name': data['partName'],
                  'Required Quantity': data['qty'],
                  'Bid Quantity': data['bidQuantity'],
                  'Currency': data['currency'],
                  'Unit Price': data['unitPrice'],
                  'Total Price': data['totalPrice'],
                  'Exchange Rate': data['currencyRate'],
                  'Unit Price (in SGD)': data['unitPriceInSGD'],
                  'Total Price (in SGD)': data['totalPriceInSGD'],
                  'Delivery Lead-Time(in calendar days)': data['leadTime'],
                  'Incoterms': data['incoterms'],
                  'Address': data['address'],
                  'Storage Shelf-life (in calendar days)': data['storageShelfLife'],
                  'Remarks': data['remarks'],
                  'Tender/RFQ Closing Date': formattedClosingDate,
                  'Tender/RFQ Name': data['tenderName']
                });
              });
              comparisonData = tempData;
              // const ppData: PriceConversion[] = data?.body?.payload.priceComparision;
              // ppData.forEach((data: any, index) => {
              //   var jsonStr = data.replace(/(\w+:)|(\w+ :)/g, function (matchedStr) {
              //     // Commented since it affects data with colan
              //     // return '"' + matchedStr.substring(0, matchedStr.length - 1) + '":';
              //     return matchedStr.substring(0, matchedStr.length - 1) + ':';
              //   });

              //   comparisonData.push(JSON.parse(jsonStr));
              // });
              // comparisonData.push(excelData);
              // for (const row of excelData) {
              //   comparisonData.push(row);
              // }
              console.log(comparisonData, "c data");
              if (comparisonData && comparisonData.length > 0) {
                console.log("Price comparison data", comparisonData);
                console.log("Excel init");
                console.log(comparisonData, "comparison data here");
                this.xls.exportToExcel(comparisonData, 'Price Comparison', 20);
                console.log("Excel created")
              }
            }
          }
          this.toastr.loaderHide();
        } catch (ex) {
          console.log("API failure");
          this.toastr.loaderHide();
        }
      }, (err) => {
        console.log("API failure");
        this.toastr.loaderHide();
      });
    } else {
      this.toastr.info('Please fill All the Currency Values.');
    }
  }

  public exportPricingSummaryToExcel() {
    if (this.currencyForm.valid === true) {
      const requestObject = '?tenderId=' + this.data.tenderId;
      const bodyContent = this.currencyForm.value.currency;
      const comparisonData = [];
      this.httpService.invokePostWithParamsAndBody(this.serviceConfigs.BUYER_APP_SERVICES_CONFIG.EXPORT_PRICING_SUMMARY_TO_EXCEL, requestObject, bodyContent).subscribe((data: any) => {
        try {
          if (data.body.isError === false) {
            // succeeded
            this.dialogRef.close();
            if (data?.body && data?.body?.payload) {
              const ppData = data?.body?.payload.pricingSummaryPriceComparision;
              ppData.forEach((data: any, index) => {
                var jsonStr = data.replace(/(\w+:)|(\w+ :)/g, function (matchedStr) {
                  return '"' + matchedStr.substring(0, matchedStr.length - 1) + '":';
                });
                comparisonData.push(JSON.parse(jsonStr));
              });
              if (comparisonData && comparisonData.length > 0) {
                this.xls.exportToExcel(comparisonData, 'Pricing Summary Price Comparison', 20);
              }
            }
          }
          this.toastr.loaderHide();
        } catch (ex) {
          this.toastr.loaderHide();
        }
      }, (err) => {
        this.toastr.loaderHide();
      });
    } else {
      this.toastr.info('Please fill All the Currency Values.');
    }
  }

  public dialogclose() {
    this.dialogRef.close();
  }

}

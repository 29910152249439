import { Component, OnInit, Input, ContentChild, TemplateRef,  Directive } from '@angular/core';

 
 
@Component({
  selector: 'text3d',
  templateUrl: './text3d.component.html',
  styleUrls: ['./text3d.component.scss'],
})
export class Text3dComponent implements OnInit {
  @ContentChild(TemplateRef) template: TemplateRef<any>;

  @Input() text: any = '';
  @Input() num: number = 20;

  arr: any[] = [];

  constructor() {
    for (let i = 1; i <= this.num; i++) {
      this.arr.push(i);
    }
  }

  ngOnInit() {}
}

<div fxLayout="column" class="password-page">
    <div>
        <div class="header-box" fxLayout="row" fxFlex="100">
            <div fxFlex fxLayoutAlign="start center">
            <!-- <img src="assets/images/session/STE Land Systems logo.png" class="ste-logo" alt="STE"> -->
            <img src="../../../../assets/images/session/STE Land Systems logos.png" class="ste-logo" [ngStyle.gt-lg]="{'width.px': 240}"  [ngStyle.lt-lg]="{'width.px': 180}"  alt="STE">
          </div>
        </div>
    </div>
    <!-- Form to Enter Email ID -->
    <div *ngIf="enterEmailIDFlag">
      <div fxLayout="column" fxLayoutAlign="space-around center" class="inner-box" >
          <div class="login-style">{{"forgot-password-text" | translate}}</div>
          <div class="enter-otp-style">{{"email-enter-request" | translate}}</div>
          <form novalidate class="app-form-horizontal" [formGroup]="emailForm">
          <div class="form-field" fxLayout="column">
              <mat-form-field class="form-field-style" appearance="outline">
                <input tabindex="1"  class="label-input" matInput formControlName="email" placeholder="{{'email-address' | translate}}" email>
                <mat-error *ngIf="hasError('email', 'email')">{{ 'email-address-invalid' |translate }}</mat-error>
                <mat-error *ngIf="hasError('email', 'required')">{{ 'email-address-req' | translate}}</mat-error>
            </mat-form-field>
          </div>
          <div class="f-w-600 error-align" fxLayout="row" fxLayoutAlign="center">
            <a routerLink="/" style="color:#E30613;" >{{"back-to-home" | translate}}</a>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center" class="button-box">
              <div><button class="cancel-button" type="button" (click)="cancelClick()" mat-stroked-button >{{"cancel-btn" | translate}}</button></div>
              <div><button tabindex="2" class="submit-button" type="submit" [ngStyle]="{'opacity':!emailForm.valid ? '0.7' : '1' }" [disabled]="!emailForm.valid" (click)="EmailSubmitButtonClick($event)" mat-button mat-stroked-button >{{"submit-btn" | translate}}</button></div>
          </div>
        </form>
        </div>
  </div>

  <!-- Form to enter OTP -->
  <div *ngIf="enterOTPFlag">
      <div fxLayout="column" fxLayoutAlign="space-around center" class="inner-box" >
        <form class="app-form-horizontal" [formGroup]="otpForm">
        <div class="login-style">{{"forgot-password-text" | translate}}</div>
        <div class="enter-otp-style">{{"otp-enter-request" | translate}}</div>
        <div class="form-field">
          <div>
            <mat-form-field class="form-field-style" appearance="outline">
                <span class="text-icon"><img src="assets/images/icons/opt-icon.svg"  alt="password"></span>
                <input tabindex="1" class="label-input" matInput formControlName="otpInput" class="label-font-style" placeholder='{{"otp-label" | translate}}' maxLength="6" (keypress)="onlyNumber($event)">
                <mat-error *ngIf="hasErrorOtp('otpInput', 'required')" >{{"otp-req" | translate}}</mat-error>
                <mat-error *ngIf="hasErrorOtp('otpInput', 'minlength')" > must be at least 6 digits long </mat-error>
            </mat-form-field>
            </div>
        </div>
        <div class="f-w-600" fxLayout="row" fxLayoutAlign="center" >
          <div class="red-font link" (click)="resendOTP()">{{"resend-otp" | translate}}</div>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center" class="button-box">
            <div><button class="cancel-button" type="button" (click)="cancelClick()" mat-stroked-button >{{"cancel-btn" | translate}}</button></div>
            <div><button class="submit-button" tabindex="2" type="submit" [ngStyle]="{'opacity':!otpForm.valid ? '0.7' : '1' }" [disabled]="!otpForm.valid" (click)="OTPSubmitButtonClick($event)" mat-stroked-button >{{"submit-btn" | translate}}</button></div>
        </div>
        </form>
    </div>
  </div>

    <!-- Form to RESET PASSWORD-->
    <div *ngIf="resetPasswordFlag">
        <div fxLayout="column" fxLayoutAlign="space-around center" class="inner-box">
            <div class="login-style">{{"reset-password" | translate}}</div>
            <div class="form-field" fxLayout="column">
              <form class="app-form-horizontal" [formGroup]="resetForm">
                <div>
                  <div  fxLayout="row">
                    <div fxFlex="100" fxLayoutAlign="end center">
                        <span class="iconmoon-info hyperlink-info-style"
                            [popperTrigger]="'hover'"
                            [popperPlacement]="'right'"
                            [popperApplyClass]="'popper-container'"
                            [popper]="hyperLinkInfo">
                       </span>
                    </div>
                       <popper-content #hyperLinkInfo  >
                        <p>Password should contain a minimum of 12 Alphanumeric characters with at least one Uppercase, one Lowercase and one Symbol(@,#,$,%,^,&,+,=,!)</p>
                    </popper-content>
                </div>
                  <mat-form-field  class="form-field-style"  appearance="outline">
                      <span matPrefix >
                        <img src="assets/images/session/password-icon.png"  alt="password">
                      </span>
                      <input matInput formControlName="password"  class="label-input"  placeholder='{{"password" | translate}}' type="password">
                         <mat-error *ngIf="hasErrorPassword('password', 'pattern')">{{ 'password-invalid' |translate }}</mat-error>
                         <mat-error *ngIf="hasErrorPassword('password', 'required')">{{ 'login-userPasswordRequired' | translate}}</mat-error>
                         <!-- <mat-icon class="text-grey-400"  matSuffix matTooltip="Password should contain a minimum of 8 Alphanumeric characters with at least one Uppercase, one Lowercase and one Symbol." >info</mat-icon> -->
                    </mat-form-field>
                  </div>
                  <div class="m-t-8">
                      <mat-form-field  class="form-field-style"  appearance="outline">
                          <span matPrefix >
                            <img src="assets/images/session/password-icon.png"  alt="password">
                          </span>
                          <input matInput formControlName="confirmPassword"  class="label-input"  placeholder='{{"confirm-password" | translate}}' type="password">
                          <mat-error *ngIf="hasErrorPassword('confirmPassword', 'required')">{{ 'confirm-password-req' | translate}}</mat-error>
                          <mat-error *ngIf="hasErrorPassword('confirmPassword' ,'mustMatch')">{{ 'match-password' | translate}}</mat-error>
                      </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px" class="button-box">
                      <div><button class="cancel-button" type="button" (click)="cancelClick()" mat-stroked-button >{{"cancel-btn" | translate}}</button></div>
                      <div><button class="submit-button" type="submit" [ngStyle]="{'opacity':!resetForm.valid ? '0.7' : '1' }" [disabled]="!resetForm.valid" (click)="ResetButtonClick($event)" mat-stroked-button >{{"submit-btn" | translate}}</button></div>
                  </div>
               </form>
            </div>
          </div>
    </div>
</div>

<mat-toolbar class="app-toolbar">

    <button mat-button class="app-hamburger" (click)="toggleSidenav.emit(); toggleCollapsedExpanded();" *ngIf="showToggle">
      <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" style="color: #fff" >
        arrow_back
      </mat-icon>
  </button>  
  <app-branding *ngIf="showBranding"></app-branding>
 

  <page-header [showBreadCrumb]="false" ></page-header>
  <span fxFlex></span>
  <!-- <button mat-icon-button>
    <mat-icon>search</mat-icon>
  </button>

  <button mat-icon-button (click)="toggleFullscreen()" fxHide.lt-sm>
    <mat-icon>fullscreen</mat-icon>
  </button>

  <app-notification fxHide.lt-sm></app-notification>

  <app-user></app-user>

  <button mat-button class="app-hamburger" (click)="toggleSidenavNotice.emit()" fxHide.lt-sm>
    <mat-icon>list</mat-icon>
  </button> -->
</mat-toolbar>
<!-- <app-user-panel *ngIf="showUser"></app-user-panel> -->
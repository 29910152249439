import { Component, OnInit, Input } from '@angular/core';
import { SmartColumn } from './smart-table.interface';
import { SmartDialog } from '../smart-dialog/smart-dialog';
import PhotoViewer from 'photoviewer';

 
 
@Component({
  selector: 'smart-table-cell',
  templateUrl: './smart-table-cell.component.html',
})
export class SmartTableCellComponent implements OnInit {
  @Input() data: any = {}; // cell data
  @Input() cell: SmartColumn; // td

  public cellValue: string = '';

  constructor(private smartDialog: SmartDialog) {}

  private str2arr(str: string) {
    return str.replace(/[\r\n\s]/g, '').split(',');
  }

  private getObjValue(obj: {}, keyArr: string[]) {
    let tmp = '';
    keyArr.forEach((key, i) => {
      if (i === 0) {
        tmp = obj[key];
      } else {
        tmp = tmp && tmp[key];
      }
    });
    return tmp;
  }

  ngOnInit() {
    this.cellValue = this.getObjValue(this.data, this.cell.index.split('.'));
  }

  fnCall(fn?: (p: any) => void, data?: any) {
    return fn(data);
  }

  confirm(title: string, fn?: (p: any) => void, data?: any) {
    this.smartDialog.confirm(title, () => {
      fn(data);
    });
  }

  // Preview the image
  preview(urlStr: string, multi = false) {
    const imgs = [];

    let options: PhotoViewer.Options = {};

    if (multi) {
      this.str2arr(urlStr).forEach((url, index) => {
        imgs.push({
          title: index + 1,
          src: url,
        });
      });
    } else {
      this.str2arr(urlStr).forEach((url, index) => {
        imgs.push({
          src: url,
        });
      });

      options = {
        title: false,
        footerToolbar: ['zoomIn', 'zoomOut', 'rotateRight', 'rotateLeft', 'actualSize'],
      };
    }

    const viewer = new PhotoViewer(imgs, options);
  }
}

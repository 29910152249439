import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HttpService } from '@core/services/http.service';
import { ServiceConfigs, SessionsService } from '@core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class AddCategoryComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public taskForm: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<AddCategoryComponent>,
    private toastr: ToastrServiceClass,
    private httpService: HttpService,
    private serviceConfigs: ServiceConfigs,
    private sessionService: SessionsService,
  ) { }

  // life cycle hooks

  ngOnInit() {
    this.taskForm = new UntypedFormGroup({
      categoryname: new UntypedFormControl('', [Validators.required])
    })
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  public dialogClose() {
    this.dialogRef.close();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.taskForm.controls[controlName].hasError(errorName);
  }

  public AddCategory(): void {
    if (this.taskForm.valid) {
      const request: any = {
        name: this.taskForm.value.categoryname,
        userId: this.sessionService.getUserId(),
      }
      this.httpService.invokePost(this.serviceConfigs.QA_DOCUMENT_SERVICES_CONFIG.CREATE_CATEGORY, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        try {
          if (data) {
            if (data.body.isError === false) {
              this.toastr.success(data?.body?.message);
              this.dialogRef.close(true);
            } else {
              this.toastr.error(data?.body?.message);
            }
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      })
    }

  }

}

<div>
  <h2 mat-dialog-title>Message to {{data.companyName}} <span><mat-icon (click)="dialogclose()" class="float-right cursor-pointer icon-18">close</mat-icon> </span> </h2>
</div>
<mat-dialog-content class="mat-typography send-message">
    <div  fxFlex="100"  fxLayout="row wrap">
        <form novalidate [formGroup]="messageForm" class="app-form-horizontal w-full">
              <div fxFlex="100" fxLayout="row wrap">
              
                <div fxLayout="row" fxFlex="100" fxLayoutGap="20px" fxLayout.lt-md="column">
                      <div fxFlex="100" fxLayout="row wrap">
                        <div fxFlex="100">
                            <div fxFlex="50" fxLayoutAlign="start center"> <label class="register-form-label">To</label></div>
                            <div fxFlex="50" fxLayoutAlign="end center">
                                <label class="register-form-label">{{date| date: 'dd MMM yyyy h:mm a'}}</label>
                            </div>
                          </div>
                        <mat-form-field class="form-field-style" appearance="outline">
                            <input readonly matInput value="{{data.userName}}" >
                        </mat-form-field>
                      </div>
                  </div>
                  <div *ngIf="data.screen === 'rfq'" fxLayout="row" fxFlex="100" fxLayoutGap="20px" fxLayout.lt-md="column">
                    <div fxFlex="100" fxLayout="row wrap" > 
                       <div fxFlex="100">
                            <div fxFlex="60" fxLayoutAlign="start center"><label class="register-form-label">Subject*</label></div>
                        </div>
                      <mat-form-field class="form-field-style" appearance="outline">
                          <input matInput  maxlength="65" value="{{data.tenderName}}" required formControlName="subject" >
                          <mat-error *ngIf="hasError('subject', 'required')">{{ 'subject-required' | translate}}</mat-error>
                      </mat-form-field>
                    </div>
                 </div>
                  <div fxLayout="row" fxFlex="100" fxLayoutGap="20px" fxLayout.lt-md="column">
                      <div fxFlex="100" fxLayout="row wrap" > 
                          <div fxFlex="100" fxLayout.lt-md="column">
                              <div fxFlex="60" fxFlex.lt-md="100" fxLayoutAlign="start center"><label class="register-form-label">{{"email-content"|translate}}*</label></div>
                              <div *ngIf="data.screen === 'rfq'" fxFlex="40" fxFlex.lt-md="100" fxLayoutAlign="end center">
                                <mat-checkbox color="primary" formControlName="sendMailStatus">Send Notification to Supplier Email</mat-checkbox>
                              </div>
                            </div>
                          
                          <mat-form-field class="form-field-text-area-message" appearance="outline">
                              <textarea maxlength="1450" matInput style="resize: none;height: -webkit-fill-available;" formControlName="description">
                              </textarea>
                              <mat-error *ngIf="hasError('description', 'required')">{{ 'email-content-required' | translate}}</mat-error>
                          </mat-form-field> 
                      </div>
                  </div>  
                  <div fxLayout="row" fxFlex="100" fxLayoutGap="20px" fxLayout.lt-md="column">
                    <div fxFlex="100" fxFlex.lt-sm="100">
                        <div  fxLayout="row">
                              <div fxFlex="90" fxLayoutAlign="start center"> 
                                  <label class="register-form-label">{{"Upload Attachments"|translate}}</label>
                              </div>  
                              <div fxFlex="10" fxLayoutAlign="end center">
                              <span class="iconmoon-info hyperlink-info-style"
                                  [popperTrigger]="'hover'"
                                  [popperPlacement]="'top'"
                                  [popperApplyClass]="'popper-container'"
                                  [popper]="hyperLinkInfo">
                              </span>
                              </div>
                          <popper-content #hyperLinkInfo  >
                              <p>You can upload maximum five files.</p>
                          </popper-content>
                          </div>
                          <mat-form-field class="upload-file-field" appearance="outline">
                              <div fxFlex="100" fxLayout="row">
                                  <div fxFlex="50" fxLayoutAlign="start center">
                                    <input matInput formControlName="uploadAttachements" readonly>
                                  </div>
                                  <div fxFlex="50" fxLayoutAlign="end center">
                                  <button mat-flat-button type="button" class="choose-file-button-style" (click)="fileUpload.click()">{{'choose-file' | translate}}</button>
                                  </div>
                              </div>
                            
                              <input type="file" multiple accept=".pdf, .xls, .xlsx" style="display:none;" #fileUpload (click)="fileUpload.value = null" (change)="AttachmentUplaod($event)" value="" />
                              <mat-hint>Note:Each file uploaded should be less than or equal to 10MB</mat-hint>
                              <input type="file" accept=".pdf, .xls, .xlsx"  style="display:none;" #fileUpload (click)="fileUpload.value = null" (change)="AttachmentUplaod($event)" value="" />  
                              <mat-error *ngIf="hasError('uploadAttachements', 'required')">{{ 'company-profile-file-req' | translate}}</mat-error>
                              <!-- <mat-hint>Note:Each file uploaded should be less than or equal to 10MB</mat-hint> -->
                          </mat-form-field>
                      </div>
                  </div> 
                  <div fxLayout="row wrap" fxLayout.lt-sm="column" fxFlex="100" fxLayoutGap="20px" fxLayoutGap.lt-sm="20px">
                      <div *ngFor="let data of fileSetList"  style="display: flex;">
                          <span><mat-icon (click)="deleteFile(data)" class="attach-icon-style">delete</mat-icon>&nbsp;</span>
                          <span class="dowload-link-style">{{data  | customDateRemover}}</span>
                      </div>
                  </div>
              </div>
        </form>     
      </div>  
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="m-r-8 cancel-button" (click)="dialogclose()" mat-stroked-button > Discard </button>
    <button class="m-r-8 action-button" appPreventDoubleClick (throttledClick)="sendMail()" mat-stroked-button > Send  </button>
</mat-dialog-actions>

import { Component, OnInit, Optional, Inject, Directive, OnDestroy } from '@angular/core';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService, ServiceConfigs } from '@core';
import { UntypedFormGroup, Validators, UntypedFormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { CustomDateRemoverPipe } from '@shared/pipes/custom-date-remover.pipe';
import { Subject } from 'rxjs';
import { Regex } from '@core/regex';
import { takeUntil } from 'rxjs/operators';
import { Helper } from '@core/helper';




@Component({
  selector: 'app-view-supplier-response',
  templateUrl: './view-supplier-response.component.html',
  styleUrls: ['./view-supplier-response.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class ViewSupplierResponseComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public viewList: any;
  public attachmentDetails: any = [];
  public supplierDetails: any = {};
  public companyName: any = '';
  public primaryBuyerId: any;
  public remarks: any;
  public productData: any;
  //public decimalNumberPattern = '^[0-9]+(\.([0-9]{1,4})?)?$';
  public decimalNumberPattern = '^([1-9][0-9]{1,9})(\.([0-9]{1,4})?)?$';
  public productForm: UntypedFormGroup;
  public list: any[] = [];
  public isLoading: boolean = true;
  public status: any;
  public isValidSellingQty: boolean = false;
  constructor(
    private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
    private dialogRef: MatDialogRef<ViewSupplierResponseComponent>,
    private toastr: ToastrServiceClass,
    private dateRemover: CustomDateRemoverPipe,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  // life cycle hooks

  ngOnInit() {
    this.supplierDetails = this.data.value;
    this.companyName = this.data.companyName;
    this.buildProductForm();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  public awardPriceValidate(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      let number = /^([1-9][0-9]{0,9})(\.([0-9]{1,4})?)?$/.test(c.value) ? +c.value : NaN;
      if (number !== number) {
        return { 'awardPriceValidate': true };
      }

      return null;
    };
  }

  public buildProductForm() {
    this.productForm = new UntypedFormGroup({
      awardPrice: new UntypedFormControl('', [Validators.required, this.awardPriceValidate()]),
      qtyAwarded: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.wholeNumberPattern)]),
    });
  }
   
  public hasError = (controlName: string, errorName: string) => {
    return this.productForm.controls[controlName].hasError(errorName);
  }

  public checkInterestedQty() {
    const qty = this.productForm.value.qtyAwarded;
    if (qty >= 1 && qty <= this.supplierDetails.qtyAvailable) {
      this.isValidSellingQty = true;
    } else {
      this.isValidSellingQty = false;
    }
  }

  public Award() {
    if (this.productForm.valid === true && this.isValidSellingQty) {
      const request = this.productForm.value;
      request.marketPlaceId = this.supplierDetails.marketPlaceId;
      request.id = this.supplierDetails.id;
      // request.companyId = this.supplierDetails.supplierId;

      this.toastr.loaderShow();
      this.httpService.invokePutBody(this.serviceConfigs.MARKET_PLACE_CONFIG.PRODUCT_AWARD_MANAGER, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        try {
          if (data) {
            if (data.body.isError === false) {
              this.toastr.success(data?.body?.message);
              this.dialogRef.close();
            } else {
              this.toastr.error(data?.body?.message);
            }
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      }, () => {

        this.toastr.loaderHide();
      });
    } else if (this.productForm.valid === true && !this.isValidSellingQty) {
      this.toastr.info('Please enter a valid Selling Quantity less than or equal to Available Quantity');
    } else if (this.productForm.valid === false) {
      this.validateAllFormFields(this.productForm);
    }
  }

  public validateAllFormFields(formGroup: UntypedFormGroup) {
    this.toastr.info('Please fill all the Required fields.');
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public downloadFile(fileName: any) {

    this.toastr.loaderShow();
    const request = '?filename=' + encodeURIComponent(fileName) + '&companyId=' + this.data.companyId + '&tenderId=' + this.data.tenderId;
    this.httpService.invokePostWithParams(this.serviceConfigs.RFQ_TENDOR_SERVICES.DOWNLOAD_RESPONSE, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data) {
          if (data?.isError === false) {
            if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
              Helper.downloadIE(data?.payload, this.dateRemover.transform(fileName));
            } else {
              Helper.download(data?.payload, this.dateRemover.transform(fileName));
            }

          } else {
            this.toastr.error(data?.message);
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    });
  }
  
  public dialogclose() {
    this.dialogRef.close();
  }
}

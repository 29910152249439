<div class="app-container-wrap" [dir]="options.dir" [ngClass]="{
  'app-sidenav-collapsed': options.sidenavCollapsed && options.navPos!=='top',
  'app-navbar-top': options.navPos==='top',
  'app-header-above': options.headerPos==='above',
  'app-header-fixed': options.headerPos==='fixed',
  'app-header-white': options.theme==='light'}">
  <ng-progress></ng-progress>

  <!-- Header Above -->
  <app-header #header class="app-header"
              *ngIf="options.showHeader && options.headerPos==='above'"
              (toggleSidenav)="sidenav.toggle()"
              (toggleSidenavNotice)="sidenavNotice.toggle()"
              [showBranding]="true">
  </app-header>

  <mat-sidenav-container class="app-container" autosize autoFocus>
    <mat-sidenav #sidenav class="app-sidenav"
                 [mode]="isOver ? 'over' : 'side'"
                 [opened]="options.navPos==='side' && options.sidenavOpened && !isOver"
                 (openedChange)="sidenavOpenedChange($event)"
                 (closedStart)="sidenavCloseStart()">
      <app-sidebar (toggleCollapsed)="toggleCollapsed()"
                   [toggleChecked]="options.sidenavCollapsed"
                   [showToggle]="!isOver"
                   [showUser]="options.showUserPanel"
                   [showHeader]="options.headerPos!=='above'">
      </app-sidebar>
    </mat-sidenav>

    <mat-sidenav #sidenavNotice position="end" mode="over" opened="false">
      <app-sidebar-notice></app-sidebar-notice>
    </mat-sidenav>

    <mat-sidenav-content class="app-content-wrap" #content>
      <app-header #header class="app-header"
                  *ngIf="options.showHeader && options.headerPos!=='above'"
                  (toggleSidenav)="sidenav.toggle()"
                  (toggleSidenavNotice)="sidenavNotice.toggle()"
                  [showToggle]="!options.sidenavCollapsed && options.navPos!=='top'"
                  [showBranding]="options.navPos==='top'">
      </app-header>

      <app-topmenu class="app-topmenu" *ngIf="options.navPos==='top'"></app-topmenu>

      <div class="app-content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  
  
</div>

import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService, ServiceConfigs, SessionsService } from '@core';
import { NavigationExtras, Router } from '@angular/router';
import { SmartDialog } from 'app/shared/components/smart-dialog/smart-dialog';
import { SendMessageComponent } from '../send-message/send-message.component';
import { CustomDateRemoverPipe } from '@shared/pipes/custom-date-remover.pipe';
import { PriceEditComponent } from '../price-edit/price-edit.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Helper } from '@core/helper';
import { TenderResponseView, TenderView } from '@core/interfaces/rfq-tender';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-view-responded',
  templateUrl: './view-responded.component.html',
  styleUrls: ['./view-responded.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class ViewRespondedComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public buyerDetails: TenderView;
  public companyName: any = '';
  public tenderId: any;
  public currentBuyerJson: any;
  public currentBuyer: any;
  public isSubmittedDateReached: any = false;
  public tenderResponseId: any;
  public awardForm: FormGroup;
  public awardToggle: boolean[] = [];
  public disableToggle: boolean[] = [];
  public disableStatus: boolean[] = [];
  public isInputValid: boolean;
  // public isAwardButton: boolean;
  public responseAttachmentDetails: any = [];
  public hasResponseAttachments: boolean = false;
  public screen: string;
  public awardedQuantity: number;
  public awardedAmount: any = '';
  awardedAmounts: string[] = [];
  public unitValue:any;
  public editResponsebtn: boolean = true;
  public columns: any[] = [
    {
      title: 'Item No.',
      index: 'sNo',
      width: 'auto',
      sort: false
    },
    {
      title: 'Part Number',
      index: 'partNo',
      width: 'auto'
    },
    {
      title: 'Name',
      index: 'productName',
      width: 'auto'
    },
    {
      title: 'Description',
      index: 'productDescription',
      type: 'format',
      format: (data: any) => {
        const shortText = data.productDescription.length > 100 ? data.productDescription.substr(0, 100) + '...' : data.productDescription;
        return `<span matTooltip="${data.productDescription}" >${shortText}</span>`;
      }
    },
    {
      title: 'UOM',
      index: 'uom',
      width: 'auto'
    },
    {
      title: 'Required Quantity',
      index: 'quantity',
      width: 'auto'
    }
  ];
  public list: any[] = [];
  public isLoading: boolean = true;
  public status: any;
  public selectedStatus: any[] = [];
  public toggleValue: any[] = [];
  public disableNoAwardToggle: boolean[] = [];
  public responseCount: number;
  public noAwardAllvalue: number;
  constructor(
    private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
    private smartDialog: SmartDialog,
    private matDialog: MatDialog,
    private dateRemover: CustomDateRemoverPipe,
    private dialogRef: MatDialogRef<ViewRespondedComponent>,
    private toastr: ToastrServiceClass,
    private router: Router,
    private fb: FormBuilder,
    private sessionService: SessionsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.status = data.tenderStatus;
    this.screen = data.screen;
    this.responseCount = data.responseCount;
  }

  // life cycle hooks

  ngOnInit() {
    this.companyName = this.data.companyName;
    this.tenderId = this.data.tenderId;
    this.isSubmittedDateReached = this.data.isSubmittedDateReached;
    this.getBuyerRFQDetails();
    this.currentBuyerJson = localStorage.getItem('currentUser');
    this.currentBuyer = JSON.parse(this.currentBuyerJson);
    this.awardForm = this.fb.group({
      items: this.fb.array([])
    });
  }

  get items(): FormArray {
    return this.awardForm.get('items') as FormArray;
  }

  onTextBoxChange(value: any, unitValue: number, index: number): void {    
    this.awardedAmounts[index] = this.calculateAwardAmount(value,unitValue);
  }

  calculateAwardAmount(value: any, unitValue: number): string {
    if (!isNaN(value)) {
      return (value * unitValue).toString();
    }
    return '';
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  public getBuyerRFQDetails() {
    const requestObject = '?tenderId=' + this.data.tenderId + '&userId=' + this.data.userId + '&companyId=' + this.data.companyId;
    this.httpService.invokeService(this.serviceConfigs.RFQ_TENDOR_SERVICES.GET_PRODUCT_RESPONSE, requestObject).then((response: TenderResponseView) => {
      if (response) {
        // this.isAwardButton = true;
        this.buyerDetails = response?.payload;
        this.tenderResponseId = this.buyerDetails?.tenderResponseDetailsDTO[0]?.tenderResponseId;
        this.buyerDetails?.tenderResponseDetailsDTO.map((item: any, index: any) => {
          item.itemNo = index + 1;
        });
        this.responseAttachmentDetails = this.buyerDetails?.listOfAttachments || [];
        if(this.responseAttachmentDetails && this.responseAttachmentDetails.some(detail => detail !== '')) {
          this.hasResponseAttachments = true;
        }
        // this.isAwardButton = this.buyerDetails?.tenderResponseDetailsDTO.some(e => e.remainingQuantity > 0 && e.notBid == 0);
        this.editResponsebtn = this.buyerDetails?.tenderResponseDetailsDTO.every(e => e.status === 'Awarded' || e.status === 'NoAward');            
        this.buyerDetails?.tenderResponseDetailsDTO.forEach((data, index) => { 
          const unitValue = data.unitPrice; // Local variable for each item
          this.noAwardAllvalue = data.noAwardAll;
          if(this.noAwardAllvalue === 0) {
            this.disableNoAwardToggle[index] = false;
          } else if(this.noAwardAllvalue === 1) {
            this.disableNoAwardToggle[index] = true;
            this.toggleValue[index] = 1;
          } else {
            this.disableNoAwardToggle[index] = true;
          }
          const awardedQuantity = data.bidQuantity;
          console.log(this.screen, "screen");
           
          if ((data.status === null || data.status === 'Awarded' || data.status === 'Pending') && this.screen !== 'adminView' && data.notBid==0) {            
            this.awardedAmounts[index] = this.calculateAwardAmount(awardedQuantity, unitValue);
          } else {
            this.awardedAmounts[index] = '-';
          }

          // this.awardToggle[index] = false;
          if (data?.status === 'Awarded') {
            this.selectedStatus[index] = 'Awarded';
            this.disableStatus[index] = true;
            // this.awardToggle[index] = true;
            this.disableToggle[index] = true;
            this.items.push(this.fb.group({
              awardingQuantity: [{ value: data.awardedQuantity || '', disabled: true }]
            }));
          } else if (data?.status === 'NoAward') {
            this.selectedStatus[index] = 'NoAward';
            this.disableStatus[index] = true;
            // this.awardToggle[index] = false;
            this.disableToggle[index] = false;
            this.items.push(this.fb.group({
              awardingQuantity: [{ value: '', disabled: true }]
            }));
          } else {
            this.selectedStatus[index] = 'Pending';
            this.disableStatus[index] = false;
            // this.awardToggle[index] = false;
            this.disableToggle[index] = false;
            this.items.push(this.fb.group({
              awardingQuantity: [{ value: data.bidQuantity || '', disabled: true }]
            }));
            // this.validateAwardQuantity(data.awardedQuantity, index);
          }
          // this.validateAwardQuantity(data.awardedQuantity, index);
        });
        // this.selectedStatus = this.buyerDetails?.tenderResponseDetailsDTO.map(() => 'pending');
        // this.buyerDetails?.tenderResponseDetailsDTO.forEach((data, index) => {
        //   if (data?.remainingQuantity === 0 && data?.status !== 'Awarded') {
        //     const control = this.items.at(index).get('awardingQuantity');
        //     this.disableToggle[index] = true;
        //     this.awardToggle[index] = false;
        //     control.disable();
        //     control.reset();
        //   }
        // });
      }
    });
  }

  // public toggleChanged(event: any, index: number) {
  //   this.awardToggle[index] = event.checked;
  //   console.log(this.awardToggle[index], "toggle index");
  //   const control = this.items.at(index).get('awardingQuantity');
  //   if (this.awardToggle[index]) {
  //     console.log("enters here");
      
  //     control.enable();
  //   } else {
  //     console.log("comes else");
      
  //     control.disable();
  //     // control.reset();
  //   }
  // }

  public toggleChanged(event: any, index: number) {
    const value = event.checked ? 1 : 0;
    const control = this.items.at(index).get('awardingQuantity');
    if(value) {
      control.disable();
      control.reset();
    } else {
      control.setValue(this.buyerDetails.tenderResponseDetailsDTO[index].bidQuantity);
    }
    this.toggleValue[index] = value;
    this.selectedStatus[index] = value ? 'NoAward' : 'Pending';
  }

  public validateAwardQuantity(value: any, index: number) {        
    const bidQuantity = this.buyerDetails.tenderResponseDetailsDTO[index].bidQuantity;
    const requiredQuantity = this.buyerDetails.tenderResponseDetailsDTO[index].remainingQuantity;
    const awardingQuantityControl = this.items.at(index).get('awardingQuantity');
    const awardingQuantity = value;
    if (this.selectedStatus[index] !== 'NoAward' && awardingQuantity <= 0) {
      awardingQuantityControl.setErrors({ invalidQuantity: true });
      this.isInputValid = false;
    } else {
      awardingQuantityControl.setErrors(null);
      this.isInputValid = true;
    }
  }

  public downloadFileView(fileName: any) {
    this.toastr.loaderShow();
    const request = '?filename=' + encodeURIComponent(fileName) + '&userId=' + this.data.userId + '&tenderId=' + this.data.tenderId;
    this.httpService.invokePostWithParams(this.serviceConfigs.RFQ_TENDOR_SERVICES.DOWNLOAD_FILE, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data) {
          if (data?.isError === false) {
            if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
              Helper.downloadIE(data?.payload, this.dateRemover.transform(fileName));
            } else {
              Helper.download(data?.payload, this.dateRemover.transform(fileName));
            }

          } else {
            this.toastr.error(data?.message);
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    });
  }

  public downloadFile(fileName: any) {

    this.toastr.loaderShow();
    const request = '?filename=' + encodeURIComponent(fileName) + '&companyId=' + this.data.companyId + '&tenderId=' + this.data.tenderId;
    this.httpService.invokePostWithParams(this.serviceConfigs.RFQ_TENDOR_SERVICES.DOWNLOAD_RESPONSE, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data) {
          if (data?.isError === false) {
            if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
              Helper.downloadIE(data?.payload, this.dateRemover.transform(fileName));
            } else {
              Helper.download(data?.payload, this.dateRemover.transform(fileName));
            }

          } else {
            this.toastr.error(data?.message);
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    });
  }

  public dialogclose() {
    this.dialogRef.close();
  }

  public sendMessage() {
    this.dialogRef.close();
    const dialogRefMessage = this.matDialog.open(SendMessageComponent, {
      panelClass: 'send-message-popup-container',
      data: { sequence: '', screen: 'rfq', tenderName: this.data.tenderName, userName: this.data.userName, tenderId: this.data.tenderId, userId: this.data.userId, parentId: this.data.parentId, supplierMailId: this.data.supplierMailId, companyName: this.data.companyName }
    });
    dialogRefMessage.afterClosed().subscribe(result => {

    });
  }

  public print() {
    const pageTitle = `<div fxFlex="100" fxFlex.lt-sm="100" style="text-align:right"> 
        ${this.data.tenderRefNumber}
      </div>`
    const title = `<title>STE - ${this.data.tenderName} - ${this.data.tenderRefNumber} </title>`;
    const contents = document.getElementById('print-content').innerHTML;
    let frame1: any = document.createElement('iframe');
    frame1.name = "frame3";
    frame1.style.position = "absolute";
    frame1.style.top = "-1000000px";
    document.body.appendChild(frame1);
    let frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
    frameDoc.document.open();
    frameDoc.document.write('<html><head> ' + title + '<style>#print-header { font-family: sans-serif, Arial, Helvetica; border-collapse: collapse;    width: 100%;	padding: 10px;}h1{  font-size: 18px;  color: #263237;  font-weight: 600;  text-align: right;}#print-content {    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;  border-collapse: collapse; width: 100%;	padding: 10px;	font-size:12px;	table-layout: fixed;} .text-grey-500, .key-style {font-weight: 600;}</style>');
    frameDoc.document.write('</head><body>');
    frameDoc.document.write(pageTitle + contents);
    frameDoc.document.write('</body></html>');
    frameDoc.document.write('<style>@media print { #no-print { display: none !important; } }');
    frameDoc.document.write('<style>@media print { #print { display: block !important; } }');
    frameDoc.document.close();
    setTimeout(function () {
      window.frames["frame3"].focus();
      window.frames["frame3"].print();
      document.body.removeChild(frame1);
    }, 500);
    return false;
  }

  public awardTender() {
    console.log("enters 5");
    
    let hasAwardQuantity = false;
    let allProductsAwarded = false;  // Assume all products are awarded initially

    // this.items.controls.forEach((control, index) => {
    //     const awardingQuantity = control.get('awardingQuantity').value;              
    //     if (awardingQuantity) {
    //       hasAwardQuantity = true;
    //       this.validateAwardQuantity(awardingQuantity, index);
    //     }
    // });

    allProductsAwarded = this.buyerDetails.tenderResponseDetailsDTO.every((e)=>{
      if(e.notBid === 1) return true;
      return  e.status === 'Awarded' || e.status === 'NoAward'
    });
    console.log(allProductsAwarded, "result allProductsAwarded");

    let awardedById = this.sessionService.getUserId();
    const awardPayload = this.items.controls
        .map((control, index) => {
            const awardingQuantity = control.get('awardingQuantity').value;
            let noAwardAllValue = this.toggleValue[index] ? this.toggleValue[index] : 0;
            if(this.selectedStatus[index] === 'Awarded') {
              noAwardAllValue = -1;
            }
            if (this.buyerDetails.tenderResponseDetailsDTO[index].status === 'Pending' && this.selectedStatus[index] !== 'Pending') {
                hasAwardQuantity = true;
                this.validateAwardQuantity(awardingQuantity, index);
                return {
                    productId: this.buyerDetails.tenderResponseDetailsDTO[index].productId,
                    awardingQuantity: awardingQuantity ? awardingQuantity : 0,
                    status: this.selectedStatus[index],
                    noAwardAll: this.responseCount >= 1 ? noAwardAllValue : null,
                };
            }
            return null;
        })
        .filter(item => item !== null);        
    if (allProductsAwarded) {
        this.toastr.info("There is no Pending Products");
        return;
    }
    if (!hasAwardQuantity) {
        this.toastr.error("At least one awarding quantity is required for Pending Products");
        return;
    }    
    if (this.isInputValid && hasAwardQuantity) {
        this.smartDialog.open({
            title: '',
            description: 'Are you sure to submit?',
            buttons: [
                {
                    type: '',
                    text: 'Cancel',
                    onClick: () => {
                        return;
                    },
                },
                {
                    type: 'primary',
                    text: 'Confirm',
                    onClick: () => {
                        const payload = {
                            tenderResponseId: this.tenderResponseId,
                            awardingProducts: awardPayload
                        };
                        console.log(payload, "payload");
                        const requestObject = '?tenderId=' + this.data.tenderId + '&status=' + 'Awarded' + '&userId=' + this.data.userId + '&awardedById=' + awardedById;
                        this.httpService.invokePostWithParamsAndBody(this.serviceConfigs.RFQ_TENDOR_SERVICES.AWARD_TENDER, requestObject, payload).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
                            try {
                                if (data !== null && data.body.isError === false) {
                                    this.toastr.success(data.body.message);
                                    this.dialogRef.close("award-success");
                                } else if (data !== null && data.isError === true) {
                                    this.toastr.error(data.body.message);
                                    this.dialogRef.close();
                                }
                            } catch (ex) {
                                this.toastr.error(ex);
                            }
                        }, (err: any) => {});
                    },
                }
            ],
        });
    } else {
        this.toastr.error("Please fill the valid award Quantity");
    }
  }

  public editResponse() {
    this.dialogRef.close();
    const navigationExtras: NavigationExtras = {
      queryParams: { companyId: this.data.companyId, userId: this.data.userId, tenderResponseId: this.tenderResponseId, tenderType: this.data.tenderType, screen: this.data.screen }
    };
    this.router.navigate(['buyer/buyer-rfq-tenders/edit-tender-response/' + this.tenderId], navigationExtras);
  }

  private enableEdit($e: any, productId: any, productName: any, productQty: any, unitPrice: any, responseId: any) {

    const dialogRef = this.matDialog.open(PriceEditComponent, {
      panelClass: 'price-popup-container',
      data: { productId: productId, productName: productName, productQty: productQty, unitPrice: unitPrice, responseId: responseId, tenderId: this.tenderId }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      if (result.event == "submit") {
        this.getBuyerRFQDetails();
      }
    });
  }

  public downloadAll(){
    this.toastr.loaderShow();
    const request = '?&tenderId=' + this.tenderId + '&companyId=' + this.data.companyId;
    this.httpService.invokePostWithParamsAndBody(this.serviceConfigs.RFQ_TENDOR_SERVICES.DOWNLOAD_RESPONSE_ZIP,request,{filenames: this.buyerDetails?.listOfAttachments})
    .pipe(takeUntil(this.destroySubject))
    .subscribe((data: any) => {
      if(data){
        const body = data.body
        if(body?.isError === false){
          if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            Helper.downloadIE(body?.payload, this.data.tenderName+"_documents.zip");
          } else {
            Helper.download(body?.payload, this.data.tenderName+"_documents.zip");
          }
        } else {
          this.toastr.error(data?.message);
        }
      }
    },error => console.log(error), () => this.toastr.loaderHide());
  }

  onStatusChange(value: string, i: number): void {
    this.selectedStatus[i] = value; // Update the selected value when it changes
    const control = this.items.at(i).get('awardingQuantity');
    if(this.selectedStatus[i] === 'Awarded') {
      control.enable();
      control.setValue(this.buyerDetails.tenderResponseDetailsDTO[i].bidQuantity);
      this.disableNoAwardToggle[i] = true;
      this.toggleValue[i] = 0;      
    } else if (this.selectedStatus[i] === 'NoAward') {
      control.disable();
      control.reset();
      if(this.buyerDetails.tenderResponseDetailsDTO[i].noAwardAll===-1) {
        this.disableNoAwardToggle[i] = true;
      }else{
        this.disableNoAwardToggle[i] = false;
      }
    } else {
      control.disable();
      control.setValue(this.buyerDetails.tenderResponseDetailsDTO[i].bidQuantity);
      if(this.buyerDetails.tenderResponseDetailsDTO[i].noAwardAll===-1) {
        this.disableNoAwardToggle[i] = true;
      }else{
        this.disableNoAwardToggle[i] = false;
      }
      this.toggleValue[i] = 0;
    }
  }
}

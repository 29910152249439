import { Pipe, PipeTransform , OnDestroy } from '@angular/core';

@Pipe({
  name: 'customDateRemover'
})
export class CustomDateRemoverPipe implements PipeTransform {

  transform(value: string): any {
    let searchstr: any = '000+0000/';
    
  if(value) {
    let  index = value.indexOf(searchstr);
    if (index === -1) {
      return value;
      }
      return value.slice(index + searchstr.length);
    }
  } 

}
import { Component, Input, ViewChild, Renderer2 , OnDestroy, OnInit } from '@angular/core';
import { MenuService, SessionsService } from '@core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
})
export class SidemenuComponent implements OnInit, OnDestroy {
  @ViewChild('childMenu') public childMenu: MatMenuTrigger;
   // NOTE: Ripple effect make page flashing on mobile
  @Input() ripple: boolean = false;
  private destroySubject: Subject<void> = new Subject();
  public enteredButton: boolean = false;
  public isMatMenuOpen: boolean = false;
  public selectedMenu: any;
  public userType: any;
  public menus = this.menuService.getAll();

  constructor(private menuService: MenuService,
    private ren: Renderer2,
    public router: Router,
    public sessionsService: SessionsService) {
    this.router.events.pipe(takeUntil(this.destroySubject)).subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.selectedMenu = event.urlAfterRedirects;
      }
    });
    this.userType = sessionsService.getUserType();
  }
  
  // life cycle hooks
 
  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  // Delete empty value in array
  public filterStates(states: string[]) {
    return states.filter(item => item && item.trim());
  }


  public menuCardEnter() {
    this.isMatMenuOpen = true;
  }

  public menuCardLeave(trigger, button) {
    setTimeout(() => {
      this.isMatMenuOpen = false;
      trigger.closeMenu();
      this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
      this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
    }, 80);
  }

  public isActiveMenu(item) {
    if (this.selectedMenu.toLowerCase().indexOf(('/' + item).toLowerCase()) > -1) {
      return true;
    } else {
      return false;
    }
  }

  public changeMenu() {
    // sessionStorage.removeItem('BuyerTenderFilter');
    // sessionStorage.removeItem('BuyerInvitedTenderFilter');
    // sessionStorage.removeItem('tenderFilter');
    // sessionStorage.removeItem('invitedTenderFilter');
    // sessionStorage.removeItem('BuyerSecondaryTenderFilter');
    // sessionStorage.removeItem('BuyerSecondaryInvitedTenderFilter');
  }

  public isGaurd(role) {
    let userRole: string;
    switch (this.userType) {
      case '0':
        userRole = 'admin';
        break;
      case '1':
        userRole = 'supplier';
        break;
      case '2':
        userRole = 'supplier';
        break;
      case '3':
        userRole = 'buyer';
        break;
      case '4':
        userRole = 'admin';
        break;
      case '5':
        userRole = 'sqa';
        break;
      case '6':
        userRole = 'engineer';
        break;
      case '7':
        userRole = 'admin-role';
        break;
    }
    return role === userRole;
  }

}

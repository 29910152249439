<div class="add-task-container">
    <div fxLayout="row" class="panelHeader">
        <div fxFlex="50" fxLayoutAlign="start center"><img src="../../../../assets/images/shopping-list.svg" class="header-icon-image" alt="icon"/>{{isEdit === true ? 'Edit' : 'Add'}} Task</div>
        <div fxFlex="50" fxLayoutAlign="end center">
            <mat-icon class="cursor-pointer" (click)="dialogClose()">clear</mat-icon>
      </div>
    </div> 
    <div class="add-task-content" >
            <form novalidate [formGroup]="taskForm">
                <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
                    <div fxFlex="50" fxFlex.lt-sm="100">
                        <label class="register-form-label">{{"task-name"|translate}}*</label>
                        <mat-form-field class="form-field-style" appearance="outline">
                            <input maxlength="20" matInput formControlName="taskName" >
                            <mat-error *ngIf="hasError('taskName', 'required')">{{ 'taskName-required' | translate}}</mat-error>
                            <mat-error *ngIf="hasError('taskName', 'pattern')">{{ 'taskName-invalid' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxFlex.lt-sm="100">
                        <label class="register-form-label">{{"task-deadline"|translate}}*</label>
                          <mat-form-field  class="form-field-style" appearance="outline">
                            <div fxFlex="100" fxLayout="row">
                                <div fxFlex="80" fxLayoutAlign="start center">
                                    <input matInput [readonly]="true" [min]="startTime" [owlDateTime]="dt2" formControlName="deadLine" 
                                    type="text" value="{{ deadLineValue !== '' ? deadLineValue : ''}}" placeholder="DD/MM/YYYY">
                                </div>
                                <div fxFlex="20" fxLayoutAlign="end center">
                                    <span [owlDateTimeTrigger]="dt2">
                                        <mat-icon class="picker-icon" matDatepickerToggleIcon>date_range</mat-icon>
                                    </span>
                                </div>
                             </div>
                            <owl-date-time [hour12Timer]="true" [startAt]="startTime" #dt2></owl-date-time>
                             
                            <mat-error *ngIf="hasError('deadLine', 'required')">{{ 'taskDeadline-required' | translate}}</mat-error>
                        </mat-form-field>
                        
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
                    <div fxFlex="100"fxLayout="column"  > 
                        <label class="register-form-label">{{"description"|translate}}*</label>
                        <mat-form-field class="form-field-text-area-style" appearance="outline">
                            <textarea maxlength="200" matInput style="resize: none;" formControlName="taskDescription">
                            </textarea>
                            <mat-error *ngIf="hasError('taskDescription', 'required')">{{ 'description-required' | translate}}</mat-error>
                        </mat-form-field> 
                    </div>
                </div>   
                <div fxLayoutAlign="center" fxLayout="row" fxLayoutGap="16px">
                    <div>
                        <button mat-flat-button type="button" (click)="dialogClose()" class="cancel-button">Cancel</button>
                    </div>
                    <div *ngIf="!isEdit">
                        <button mat-flat-button type="submit" (click)="AddTask()" class="add-button">Submit</button>
                    </div>
                    <div *ngIf="isEdit">
                        <button mat-flat-button type="submit" (click)="UpdateTask()" class="add-button">Submit</button>
                    </div>
                </div>
        </form>
    </div>
</div>

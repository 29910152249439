import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {
  
  private _loading = false;
  private messageSource = new BehaviorSubject('Loading...');
  public currentMessage = this.messageSource.asObservable();
  public loadingStatus: Subject<boolean> = new Subject();

  // getters setters

  get loading() {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
    this.loadingStatus.next(value);
  }

  // functions

  startLoading(msg?: string) {
    this.messageSource.next(msg);
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }
}

import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { ServiceConfigs, HttpService } from '@core';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomDateRemoverPipe } from '@shared/pipes/custom-date-remover.pipe';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Helper } from '@core/helper';
import { ProductViewDetails, ProductViewResponse } from '@core/interfaces/supplier-app-service-config';



@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class ViewProductComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public product: ProductViewDetails;
  public productId: any;
  constructor(
    private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
    private toastr: ToastrServiceClass,
    private dateRemover: CustomDateRemoverPipe,
    private dialogRef: MatDialogRef<ViewProductComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  // life cycle hooks

  ngOnInit() {
    this.productId = this.data.id;
    this.getProductByID();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  public getProductByID() {
    const id = this.productId;
    if (id) {
      const request = '?productId=' + id;
      this.toastr.loaderShow();
      this.httpService.invokeGet(this.serviceConfigs.SUPPLIER_APP_SERVICES_CONFIG.GETBYID_PRODUCT_DATA, request).pipe(takeUntil(this.destroySubject)).subscribe((data: ProductViewResponse) => {
        try {
          if (data && data?.isError === false) {
            // succeeded
            this.product = data?.payload;
          } else if (data && data?.isError === true) {
            this.toastr.error(data?.message);
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      });
    }
  }

  public downloadFile(fileName: any) {

    this.toastr.loaderShow();
    const request = '?filename=' + encodeURIComponent(fileName) + '&companyId=' + this.data.companyId;
    this.httpService.invokePostWithParams(this.serviceConfigs.ADD_PRODUCT_SERVICES_CONFIG.DOWNLOAD_FILE, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data) {
          if (data?.isError === false) {
            if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
              Helper.downloadIE(data?.payload, this.dateRemover.transform(fileName));
            } else {
              Helper.download(data?.payload, this.dateRemover.transform(fileName));
            }
          } else {
            this.toastr.error(data?.message);
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    });
  }
   
  public dialogclose() {
    this.dialogRef.close();
  }

}

import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { HttpService, ServiceConfigs, SessionsService } from '@core';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { Regex } from '@core/regex';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-task-dialog',
  templateUrl: './add-task-dialog.component.html',
  styleUrls: ['./add-task-dialog.component.scss'],
  providers: [HttpService, ServiceConfigs, DatePipe]
})
export class AddTaskDialogComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public taskForm: UntypedFormGroup;
  public startTime = new Date();
  public screenName: any;
  public isEdit: any;
  public taskId: any = '';
  public deadLineValue: any = '';
  constructor(
    private dialogRef: MatDialogRef<AddTaskDialogComponent>,
    private toastr: ToastrServiceClass, private httpService: HttpService,
    private serviceConfigs: ServiceConfigs,
    private sessionService: SessionsService,
    public datePipe: DatePipe,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.screenName = data.screenName;
    this.isEdit = data.isEdit;
    this.taskId = data.taskId;
    this.buildTaskForm();
  }

  // life cycle hooks

  ngOnInit() {
    if (this.isEdit) {
      this.getTaskByID();
    }
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  public buildTaskForm() {
    this.taskForm = new UntypedFormGroup({
      taskName: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.alphaNumericPatternAddTask)]),
      deadLine: new UntypedFormControl('', [Validators.required]),
      taskDescription: new UntypedFormControl('', [Validators.required])
    });
  }

  public dialogClose() {
    this.dialogRef.close();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.taskForm.controls[controlName].hasError(errorName);
  }

  public getTaskByID() {
    if (this.taskId) {
      const requestObject = '?taskId=' + this.taskId;
      this.httpService.invokeService(this.serviceConfigs.DASHBOARD_SERVICES_CONFIG.GETBYID_TASK_DATA, requestObject).then((response: any) => {
        if (response && response?.isError === false) {
          this.taskForm.patchValue({ taskName: response?.payload?.taskName });
          const date = new Date(response?.payload?.deadLine);
          this.deadLineValue = this.datePipe.transform(response?.payload?.deadLine, "MM/dd/yyyy, h:mm a");
          this.taskForm.patchValue({ deadLine: date });
          this.taskForm.patchValue({ taskDescription: response?.payload?.taskDescription });
        } else if (response && response?.isError === true) {

        }
      });
    }
  }

  public AddTask() {
    if (this.taskForm.valid === true) {
      this.toastr.loaderShow();
      const request = this.taskForm.value;
      request.screen = this.screenName;
      request.userId = this.sessionService.getUserId();

      this.httpService.invokePost(this.serviceConfigs.RFQ_TENDOR_SERVICES.ADD_TASK_DATA, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        try {
          if (data) {
            if (data.body.isError === false) {
              this.toastr.success(data?.body?.message);
              this.dialogClose();
            } else {
              this.toastr.error(data?.body?.message);
            }
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      });
    } else {
      this.validateAllFormFields(this.taskForm);
    }
  }

  public validateAllFormFields(formGroup: UntypedFormGroup) {
    this.toastr.info('Please fill all the Required fields.');
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  UpdateTask() {
    if (this.taskForm.valid === true) {
      const request = this.taskForm.value;
      request.screen = this.screenName;
      request.userId = this.sessionService.getUserId();
      request.id = this.taskId;

      this.toastr.loaderShow();
      this.httpService.invokePutBody(this.serviceConfigs.DASHBOARD_SERVICES_CONFIG.UPDATE_TASK_DATA, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        try {
          if (data) {
            if (data.body.isError === false) {
              this.toastr.success(data?.body?.message);
              this.dialogClose();
            } else {
              this.toastr.error(data?.body?.message);
            }
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      });
    } else {
      this.validateAllFormFields(this.taskForm);
    }
  }

}

import { Injectable } from '@angular/core';

@Injectable()
export class ServiceConfigs {
  private CONFIG_KEYS: any = {
    AUTH_BASE_URI_KEY: 'authBaseUri',
    SERVICE_REST_URI: 'ste/',
    SECURE_USER_URI: 'secure/user/',
    SECURE_VENDOR_URI: 'secure/vendor/',
    SECURE_VENDOR_REP_URI: 'secure/vendorrep/',
    SECURE_CUSTOMERS_URI: 'secure/customers/',
    SECURE_DOC_REPO_URI: 'secure/documentrepository/',
    SECURE_REGISTRATION_URI: 'secure/registration/',
    SECURE_ACL_URI: 'secure/acl/',
    SECURE_COMMON_URI: 'secure/common/',
    REQ_TYPE_GET: 'get',
    REQ_TYPE_POST: 'post',
    REQ_TYPE_DELETE: 'delete',
    MOCK_DIR: 'assets/data/',
    DEFAULT_PARENT_API_NODE: 'VisionResponse',
    SECURE_LOOKUP_URI: 'secure/lookup/',
    PLEASE_WAIT: 'Please wait...',
  };
  public FILE_SCAN_SERVICES_CONFIG: any = {
    UPLOAD: {
      url: 'https://www.landscmportal.com/steScan/scan',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    SCAN_AUDIT: {
      url: 'ste/audit/scanAudit',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
  };
  public AUTH_LOGIN_SERVICES_CONFIG: any = {
    STE_LOGIN: {
      url: 'ste/login',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    OAUTH_LOGIN: {
      url: 'ste/oauth/token',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    ADMIN_LOGIN: {
      url: 'ste/login/admin',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    FORGOT_PASSWORD: {
      url: 'api/user/forgotPassword',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/forgotPassword/forgotPassword.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },

    USER_PROFILE: {
      url:
        this.CONFIG_KEYS.SERVICE_REST_URI + this.CONFIG_KEYS.SECURE_MOBILE_URI + 'getUserProfile',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/httpCtrl/userProfile.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      responseProperty: 'data',
    },
    GET_INDUSTRY_TYPE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'industryType',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/industryType.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    GET_SUB_INDUSTRY_TYPE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'subIndustryType',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/businessType.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    GET_COUNTRY_TYPE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'country/getCountries',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'country.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    REGISTER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'country.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    REGISTER_UPDATE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/updateSupplier',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'country.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    SUPPLIER_GET_BY_ID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getSupplierDetails',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/getToDo.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    OTP_VERIFICATION: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'password/validateOtp',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    ADMIN_OTP_VERIFICATION: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'password/validateOtpAdmin',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    OTP_VERIFICATION_ADMIN: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'password/validateOtpAdmin',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    EMAIL_VERIFICATION: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'password/forgotPassword',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    EMAIL_VERIFICATION_ADMIN: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'password/forgetPasswordAdmin',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    RESET_PASSWORD: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'password/resetPassword',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'country.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    RESET_PASSWORD_ADMIN: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'password/resetPasswordAdmin',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'country.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    RESEND_OTP: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'password/resendOtp',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    RESEND_OTP_ADMIN: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'password/resendOtpAdmin',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    VALIDATE_USERNAME: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/usernameCheck',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    VALIDATE_EMAILID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/emailCheck',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    VALIDATE_BUSINESS_REGISTRATION_NUMBER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/businessRegistrationNumberCheck',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
  };

  public PROFILE_SERVICES: any = {
    SUBMIT_SECONDARY_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/secondaryBuyer',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    COMPANY_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/companyInformation',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    SECONDARY_USER_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getSecondaryBuyer',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    GETBYID_SECONDRY_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getSecondarySupplierDetails',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    DELETE_SECONDRY_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/secondarySupplierDelete',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
  };
  public SUPPLIER_APP_MESSAGE_CONFIG: any = {
    GET_MESSAGE_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'message/viewMessageById',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getMessage.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_MESSAGE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'message/getAllSupplierrMessageById',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getMessage.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    SEND_REPLY_SUPPLIER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'message/reply',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
  };
  public RFQ_TENDOR_SERVICES: any = {
    GET_RESPONSE_FILES: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/downloads',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    CHANGE_SECONDARY_BUYER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/changeSecondaryBuyer',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DOWNLOAD_ARCHIVE_RESPONSE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/archiveDownload',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DOWNLOAD_ARCHIVE_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/archiveDownload',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    AWARD_TENDER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/changeTenderStatus',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DELETE_TENDER_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/delete',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
    },
    SUBMIT_RESPONDED_TENDOR: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    SUPPLIER_RESPONDED_TENDER_INVITED: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/respondedInvitedTenderInfo',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    DOWNLOAD_RESPONSE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/download',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DOWNLOAD_RESPONSE_ZIP: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/downloadZip',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_OPEN_TENDOR_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/openTenderInfo',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/getToDo.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_CLOSED_TENDOR_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/closedTenderInfo',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/getToDo.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_AWARDED_TENDOR_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/awardedTenderInfo',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/getToDo.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_TENDOR_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/allTenderInfo',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    GET_INVITED_TENDOR_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/invitedTenderInfo',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/getToDo.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_TENDOR_VIEW_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/tenderDetails',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getPricingSummaryTenderDetails.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_RESPONDED_OPEN_TENDOR_VIEW_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/tenderOpenDetails',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getPricingSummaryTenderDetails.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_BUYER_OPEN_TENDOR_VIEW_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/tenderBuyerOpenDetails',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getPricingSummaryTenderDetails.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DOWNLOAD_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/download',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DOWNLOAD_FILES: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/downloads',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DOWNLOAD_ZIP: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/downloadZip',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DELETE_ATTACHMENTS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/delete',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    ADD_TASK_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'task',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_INCOTERMS_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'incoterms/getIncoterms',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    GET_PRODUCT_RESPONSE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getResponsePricingSummary.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    SUPPLIER_RESPONDED_TENDER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/respondedOpenTenderInfo',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    TENDER_RESPOND_UPLOAD: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/upload',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    TENDER_CREATE_UPLOAD: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/upload',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_TENDER_RESPONSES_RECEIVED: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/tenderOpenInviteSupplierDetails',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_PRICE_COMPARISON_COLUMNS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/getHeaderPriceConversion',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getHeaderPriceConversion.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_PRICE_COMPARISON_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/getPriceConversion',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getPriceConversion.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_PRICING_PRICE_COMPARISON_COLUMNS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/getHeaderPricingPriceConversion',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getHeaderPriceConversion.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_SUMMARY_PRICE_COMPARISON_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/getPricingSummaryConversion',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getPriceConversion.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
  };

  public DASHBOARD_SERVICES_CONFIG: any = {
    ADMIN_ROLE_DASHBOARD_COUNT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'dashboard/dashboardCountAdmin',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    ADMIN_DASHBOARD_COUNT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'dashboard/dashboardCountManager',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    GET_TODO_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'task/getAllTasks',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/getToDo.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
      responseProperty: 'data',
    },
    GET_TODO_LIST_BUYER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'task/getAllBuyerTasks',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/getToDo.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
      responseProperty: 'data',
    },
    GETBYID_TASK_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'task/viewTask',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    UPDATE_TASK_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'task/updateTask',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
      isMock: false,
    },
    DELETE_TASK_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'task/deleteTask',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
    },
    GET_DASHBOARD_TENDER_COUNTS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'dashboard/dashboardCount',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/getToDo.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
      responseProperty: 'data',
    },
  };

  public SUPPLIER_APP_SERVICES_CONFIG: any = {
    GET_DESK_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'informationDesk/getInformationSupplier',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'admin/getAllGuides.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_PRODUCTS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'product/getAllProductDetails',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getProducts.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
      responseProperty: 'data',
    },
    UPDATE_PRODUCT_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'product/updateProduct',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    GETBYID_PRODUCT_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'product/viewProduct',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/getToDo.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    ADD_PRODUCT_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'product/addProduct',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    DELETE_PRODUCT_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'product/delete',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
      isMock: false,
    },
    DELETE_PRODUCT_DATA1: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'product/deleteProduct',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
      isMock: false,
    },
    SUPPLISER_UPLOAD: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/upload',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_BUYER_PRODUCTS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'product/getAllProductDetails',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getProducts.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: true,
      responseProperty: 'data',
    },
    GET_SUPPLIER_PROFILE_IMAGE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/viewDisplayPicture',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getviewDisplayPicture.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DELETE_SUPPLIER_PROFILE_IMAGE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/deletePicture',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
    },
    UPLOAD_SUPPLIER_PROFILE_IMAGE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/pictureUpload',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    GET_SUPPLIER_PROFILE_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/userInformation',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getProducts.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    SUPPLIER_UPLOAD_ID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/uploadUserId',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },

    GET_PRODUCT_COUNT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/getProductCountSupplier',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'admin/getAllGuides.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
  };
  public ADD_PRODUCT_SERVICES_CONFIG: any = {
    GET_PRODUCTS_CATEGORY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_MANAGER_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyerAdminUser/getAllManagersList',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_PRODUCTS_SUBCATEGORY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'subCategory/categoryBySubCategory',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/subcategory.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    PRODUCT_UPLOAD: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'product/upload',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DOWNLOAD_ALL_ZIP: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'product/downloadZip',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DOWNLOAD_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'product/download',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
  };
  public ADMIN_APP_CONFIG: any = {
    GET_ALL_TENDER_DETAILS: { 
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/adminOpenTender',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_INVITED_TENDER_DETAILS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/adminByInviteTender',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    EXPORT_SUPPLLIER_DETAILS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getAllStatusBySupplierForExcel',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: true,
    },
    DELETE_INFORMATION: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'informationDesk/deleteInformation',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    DELETE_FILE_INFORMATION: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'informationDesk/delete',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
    },
    ADD_INFORMATION: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'informationDesk',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    UPDATE_INFORMATION: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'informationDesk/updateInformation',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    VIEW_INFORMATION: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'informationDesk/getInformation',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    INFORMATION_UPLOAD: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'informationDesk/upload',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    INFORMATION_GET_BY_ID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'informationDesk/getInformationById',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    INFORMATION_DOWNLOAD: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'informationDesk/download',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    LEVEL2_UPGRADE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/levelUpgrade',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
      isMock: true,
    },
    SUPPLIERS_NEW: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: true,
    },
    SUPPLIERS_NEW_WITH_SORT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: true,
    },
    SUPPLIERS_NEW_WITH_SORT_LEVEL: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getAllStatusBySupplierByLevel',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: true,
    },
    SUPPLIER_DETAILS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/supplierDetails',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: true,
    },
    SUPPLIER_GET_BY_ID_AND_CATEGORIES: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getSupplierCategoryList',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/getToDo.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    SUPPLIER_STATUS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/supplierStatus',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    SUPPLIER_EMAIL_UPDATE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/updateSecondarySupplier',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    DOWNLOAD_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/download',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DELETE_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/delete',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
    },
    GET_ALL_GUIDES: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'guide/getAllGuides',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'admin/getAllGuides.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_GUIDES_FOR_SUPPLIER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'guide/getAllGuidesSupplier',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'admin/getAllGuides.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GUIDE_UPLOAD: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'guide/upload',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GUIDE_UPDATE_UPLOAD: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'guide/uploadFile',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    ADD_GUIDE_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'guide',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DELETE_GUIDE_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'guide/deleteGuide',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
      isMock: false,
    },
    GETBYID_GUIDE_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'guide/getGuide',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'admin/getAllGuides.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DOWNLOAD_GUIDE_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'guide/download',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DELETE_GUIDE_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'guide/delete',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
    },
    GET_ALL_HELPS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'help/getAllHelps',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'admin/getAllGuides.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_HELPS_FOR_SUPPLIER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'help/getAllHelpsSupplier',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'admin/getAllGuides.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    HELPS_UPLOAD: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'help/upload',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    HELPS_UPDATE_UPLOAD: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'help/uploadFile',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    ADD_HELPS_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'help',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DELETE_HELPS_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'help/deleteHelp',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
      isMock: false,
    },
    GETBYID_HELPS_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'help/getHelp',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'admin/getAllGuides.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DOWNLOAD_HELPS_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'help/download',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DELETE_HELPS_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'help/delete',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
    },
    BLOCK_OR_REVOKE_SUPPLIER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'blockAndRevoke',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    GET_ALL_CATEGORY1_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getAllCategory1',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'admin/getAllGuides.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_CATEGORY2_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getAllCategory2',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'admin/getAllGuides.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    BLOCK_SUPPLIER_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getBlockedSupplier',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    BLOCK_SUPLLIER_LEVEL_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getBlockedSupplierByLevel',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_BLOCK_RESUME_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'blockAndRevoke/getBlockReport',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
  };
  public SERVICE_TAB_SERVICES_CONFIG: any = {
    GET_SERVICE_CATEGORY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category/serviceCategory',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_SERVICES: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'service/getAllServiceDetails',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getProducts.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
      responseProperty: 'data',
    },
    UPDATE_SERVICE_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'service/updateService',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    GETBYID_SERVICE_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'service/viewService',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/getToDo.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    ADD_SERVICE_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'service/addService',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    DELETE_SERVICE_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'service/delete',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
      isMock: false,
    },
    DELETE_SERVICE_DATA1: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'service/deleteService',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/token.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
      isMock: false,
    },
    SERVICE_UPLOAD: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'service/upload',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DOWNLOAD_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'service/download',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
  };
  public QA_DOCUMENT_SERVICES_CONFIG: any = {
    QA_DOCUMENT_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'uploadDocuments',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    SUPPLIER_QA_DOCUMENT_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'uploadDocuments/getUploadDocumentsByDocs',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    SAVE_QA_DOCUMENT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'uploadDocuments',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    UPDATE_QA_DOCUMENT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'uploadDocuments/updateVersion',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    CREATE_CATEGORY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'uploadCategory',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    ACKNOWLEDGE_DOCUMENT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'uploadDocuments',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
      isMock: false,
    },
    QA_DOCUMENT_VIEW: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'uploadDocuments/getUploadDocumentsByUser',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_VERSION_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'uploadDocuments/documentVersion',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_DOC_VERSION_DETAIL: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'uploadDocuments/getUploadDocumentsByVersion',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DOWNLOAD_QA_DOCUMENT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'uploadDocuments/download',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    SHARED_USER_LISR: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'uploadDocuments/getUploadDocumentsMap',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DOCUMENT_STATUS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'uploadDocuments/ack',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_L1_SUPPLIERS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getApprovedLevel1Suppliers',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_L2_SUPPLIERS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getApprovedLevel2Suppliers',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_CATEGORY_ONE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getAllCategory1',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'admin/getAllGuides.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_CATEGORY_TWO: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getAllCategory2',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_APPROVED_CATEGORY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getAllSupplierWithCategory',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_DOCUMENT_TITLE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'uploadDocuments/documentTitle',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_DOCUMENT_REPORT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'uploadDocuments/fetchUploadDocuments',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DOWNLOAD_DOCUMENT_REPORT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'export/document',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
  };
  public BUYER_APP_SERVICES_CONFIG: any = {
    SECONDARY_OPEN_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyer/secondaryOpenTenderInfo',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'buyer/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    SECONDARY_INVITED_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyer/secondaryInvitedTenderInfo',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'buyer/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    PRICE_LIST_RESPONSE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/getPriceList',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'buyer/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    PRICING_SUMMARY_PRICE_LIST_RESPONSE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/getPricingSummaryList',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'buyer/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_PROCUREMENT_SUB_CATEGORY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'subCategory/categoryBySubCategory',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'buyer/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_DASHBOARD_COUNTS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'dashboard/buyerDashBoard',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    DOWNLOAD_FILE_MESSAGE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'message/download',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    UPLOAD_MESSAGE_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'message/upload',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_MESSAGE_GRID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'message/getAllBuyerMessageById',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    SEND_MAIL_POPUP: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'message',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_SUPPLIERS_FOR_MESSAGE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'message/getApprovedSuppliers',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getApprovedSuppliers.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    SEND_MAIL_BUYER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'message/reply',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    SEND_BUYER_REPLY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'message/replyBuyer',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_MESSAGE_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'message/viewMessageById',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    EXPORT_TO_EXCEL: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/getPriceConversionExcel',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    EXPORT_PRICING_SUMMARY_TO_EXCEL: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/getPricingSummaryExcel',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    GET_OPEN_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyer/openTenderInfo',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    GET_INVITE_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyer/invitedTenderInfo',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    GET_SUPPLIER_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getApprovedSuppliers',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    GET_SUPPLIER_LIST_L2: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getApprovedLevel2Suppliers',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    GET_SECONDRY_BUYER_DETAILS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyer/getBuyerDetails',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    GET_PROCUREMENT_CATEGORY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category/getAllTenderCategories',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'buyer/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_BUYER_PAYMENTS_TERMS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'paymentTerms/getAllpayments',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'buyer/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_BUYER_DEPARTMENTS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'departments/getDepartments',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'buyer/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_BUYER_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyer/getBuyer',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'buyer/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_BUYER_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyer/getAllBuyer',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'buyer/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_BUYER_LIST_BY_ID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyer/getBuyer',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'buyer/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    CREATE_BUYER_TENDOR: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    EDIT_UNIT_PRICE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/updateTenderResponseDetails',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DELETE_PRODUCTS_AND_SERVICES: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/deleteProductAndService',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
    },
    DELETE_PRICING_SUMMARY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/deletePricingSummary',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
    },
    TENDER_STATUS_CANCEL: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/awardCancelStatus',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'buyer/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    EDIT_TENDER_RESPONSE_STATUS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/publishResponse',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'buyer/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
  };
  public ADMIN_ARTICLES_CONFIG: any = {
    GET_ARTICLE_LIST_IN_BUYER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'article/getArticlesBuyer',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ARTICLE_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'article/getArticles',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DELETE_ARTICLE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'article/deleteArticle',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    DELETE_FILE_ARTICLE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'article/delete',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
    },
    ADD_ARTICLE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'article',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    UPDATE_ARTICLE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'article/updateArticle',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    ARTICLE_GET_BY_ID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'article/getArticleById',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    ARTICLE_DOWNLOAD: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'article/download',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
  };
  public USER_MANAGEMENT_CONFIG: any = {
    GET_ALL_USERS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyerAdminUser/getAllBuyerAdminUser',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_USERS_SORT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyerAdminUser/getAllBuyerAdminUserWithSort',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DELETE_USER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyerAdminUser/deleteBuyerAdminUser',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    ADD_USER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyerAdminUser',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    UPDATE_USER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyerAdminUser',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    GET_ROLE_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyerAdminUser/getAllRoles',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_BY_ID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyerAdminUser/getBuyerAdminUserById',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
  };
  public ADMIN_BOOK_CONFIG: any = {
    GET_BOOK_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'book/getAllBooks',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_BOOK_LIST_ORDER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'book/getAllBooksInBuyer',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DELETE_BOOK: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'book/deleteBook',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    ADD_BOOK: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'book',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    BOOK_GET_BY_ID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'book/getBookById',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
  };
  public SQM_APP_SERVICES_CONFIG: any = {
    GET_DASHBOARD_COUNTS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'dashboard/dashboardCountSqm',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'sqm/dashboardCountSqm.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_TODO_LIST_SQA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'task/getAllBuyerTasks',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'sqm/dashboardCountSqm.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
  };

  public NOTIFICATION_CONFIG: any = {
    GET_NOTIFICATION_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'notification/getAllNotificationsById',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DELETE_NOTIFICATION: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'notification/deleteNotification',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    UPDATE_NOTIFICATION_READ: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'notification/updateRead',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
  };

  public ARCHIVED_TENDER_CONFIG: any = {
    GET_OPEN_TENDOR_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/archiveOpenTender',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/getToDo.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_INVITED_TENDOR_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/archiveInviteTender',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/getToDo.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_TENDOR_VIEW_DATA: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/archiveTenderEdit',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getPricingSummaryTenderDetails.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_TENDER_RESPONSES_RECEIVED: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/archiveTenderOpenInviteSupplierDetails',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_TENDER_RESPONSE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse/archiveTenderResponse',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'common/getToDo.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
  };

  public AUDIT_CONFIG: any = {
    GET_SUPPLIER_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/getAllSuppliers',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_TENDER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/getAllTenders',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_BUYER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyerAdminUser/getAllBuyerList',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_MANAGER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyerAdminUser/getAllManagerList',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_SUPER_MANAGER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyerAdminUser/getAllSuperManagerList',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_SQM: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyerAdminUser/getAllSqmList',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_ENGINEER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'buyerAdminUser/getAllBuyerList',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_SUPPLER_AUDIT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'audit/getSupplierAudit',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_BUYER_AUDIT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'audit/getBuyerAudit',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_TENDER_AUDIT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'audit/getTenderAudit',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    LOGOUT_AUDIT_CALL: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'audit',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
      isMock: false,
    },
    GET_ALL_AUDIT_USERS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'audit/getAuditors',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
  };

  public HOME_MGMT_CONFIG: any = {
    GET_ANNOUNCEMENT_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'announcement/getAllAnnouncements',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ANNOUNCEMENT_LIST_ORDER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'announcement/getAnnouncements',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DELETE_ANNOUNCEMENT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'announcement/deleteAnnouncement',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    ADD_ANNOUNCEMENT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'announcement',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    ANNOUNCEMENT_GET_BY_ID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'announcement/getAnnouncementById',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    ADD_ABOUT_US: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'aboutUs',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    ABOUTUS_GET_BY_ID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'aboutUs/getAboutUs',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_JOIN_US_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'joinUs/getJoinUs',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_JOIN_US_LIST_ORDER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'joinUs/getJoinUsHome',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DELETE_JOIN_US: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'joinUs/deleteJoinUs',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    ADD_JOIN_US: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'joinUs',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    JOIN_US_GET_BY_ID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'joinUs/getJoinUsById',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
  };

  public REVIEW_RATINGS_CONFIG: any = {
    GET_COMPANY_NAME_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'reviewsAndRatings/getCompanyName',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_OVERALL_COUNTS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'reviewsAndRatings/getAllCount',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },

    GET_AWARD_TENDER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'reviewsAndRatings/getTenderByAward',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },

    GET_ALL_REVIEWS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'reviewsAndRatings/getReviews',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    ADD_REVIEW_RATINGS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'reviewsAndRatings',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
  };

  public MARKET_PLACE_CONFIG: any = {
    SUPER_MARKET_GET_RECORDS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/getAllMarketRecords',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_LOCATION_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'location/getLocation',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    ADD_MARKET_PLACE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/createMarketPlace',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_MARKET_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/getMarketRecords',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_MARKET_LIST_WITH_RESPONSE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/getRespondedRecords',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_MARKET_BYID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/getMarketRecordsById',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DOWNLOAD_MARKET_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/download',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DOWNLOAD_ALL_MARKET_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/downloadZip',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    DELETE_MARKET_FILE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/delete',
      reqType: this.CONFIG_KEYS.REQ_TYPE_DELETE,
    },
    GET_MARKET_RESPONSE_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketPlaceResponse/getMarketPlaceResponse',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    MARKET_PLACE_SUPPLIER_RESPONSE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketPlaceResponse',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_PRODUCT_ONSALE_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/getMarketPlaceByCategory',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    PRODUCT_AWARD_MANAGER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/awarded',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'country.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    PRODUCT_SUPPLIER_REQUESTED: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketPlaceResponse/getRespondedRecords',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    PRODUCT_SUPPLIER_AWARDED: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketPlaceResponse/getAwardedRecords',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    PRODUCTS_SOLD_MANAGER: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/getSoldRecords',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },

    GET_SUPER_MGR_PRODUCT_COUNT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/getProductCountSuperManager',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_MANAGER_PRODUCT_COUNT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/getProductCountManager',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getSupplierRequest.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    CLOSE_PRODUCT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/closeMarketPlace',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    GET_CLOSE_MARKET_LIST_WITH_RESPONSE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/getCloseMarketRecords',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    GET_CLOSED_MARKET_RESPONSE_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'marketplace/getBuyerCloseMarketRecordsResponse',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
  };

  public MASTER_SCREEN_CONFIG: any = {
    GET_ALL_INDUSTRIES: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'industryType/getAllIndustryActive',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getIndustryType.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    ADD_INDUSTRY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'industryType/createIndustryType',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    UPDATE_INDUSTRY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'industryType/updateIndustryType',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    GET_INDUSTRY_BYID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'industryType/getIndustryType',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getIndustryType.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DELETE_INDUSTRY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'industryType/deleteIndustryType',
    },
    GET_ALL_SUBINDUSTRIES: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'subIndustryType/getAllSubIndustryActive',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getIndustryType.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    ADD_SUBINDUSTRY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'subIndustryType/createSubIndustryType',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    UPDATE_SUBINDUSTRY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'subIndustryType/updateSubIndustryType',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    GET_SUBINDUSTRY_BYID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'subIndustryType/getSubIndustryType',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getIndustryType.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    DELETE_SUBINDUSTRY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'subIndustryType/deleteSubIndustryType',
    },
    GET_ALL_INCOTERMS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'incoterms',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getIncoterms.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    ADD_INCOTERMS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'incoterms/createIncoterms',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_INCOTERMS_BYID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'incoterms/getIncotermsById',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getIncoterms.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    UPDATE_INCOTERMS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'incoterms/updateIncoterms',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    DELETE_INCOTERMS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'incoterms/deleteIncoterms',
    },
    GET_ALL_PAYMENTS_TERMS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'paymentTerms',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'buyer/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    ADD_PAYMENT_TERMS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'paymentTerms/createPaymenterms',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_PAYMENT_TERMS_BYID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'paymentTerms/getPaymentermsById',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getIncoterms.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    UPDATE_PAYMENT_TERMS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'paymentTerms/updatePaymenterms',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    DELETE_PAYMENT_TERMS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'paymentTerms/deletePaymenterms',
    },

    GET_ALL_CATEGROY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category/getAllActiveCategory',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getIncoterms.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },

    ADD_CATEGROY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category/createCategory',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_CATEGROY_BYID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category/getCategory',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getIncoterms.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    UPDATE_CATEGROY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category/updateCategory',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    DELETE_CATEGROY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category/deleteCategory',
    },

    GET_ALL_SUBCATEGROY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'subCategory/getAllActiveCategoryByCategoryId',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getIncoterms.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    ADD_SUBCATEGROY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'subCategory/createSubCategory',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_SUBCATEGROY_BYID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'subCategory/getSubCategory',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getIncoterms.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    UPDATE_SUBCATEGROY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'subCategory/updateSubCategory',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    DELETE_SUBCATEGROY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'subCategory/deleteSubCategory',
    },
    GET_ALL_SERVICE_CATEGORY: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category/getAllActiveServiceCategory',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/category.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },

    GET_ALL_LOCATION: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'location/getAllActiveLocation',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getIncoterms.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    ADD_LOCATION: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'location/createLocation',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_LOCATION_BYID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'location/getLocationById',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getIncoterms.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    UPDATE_LOCATION: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'location/updateLocation',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
    DELETE_LOCATION: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'location/deleteLocation',
    },
    GET_ALL_CATEGORY2: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category2',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    DELETE_CATEGORY2: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category2/deleteCategory2',
    },
    GET_CATEGORY2_BYID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category2/getCategory2ById',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    UPDATE_CATEGORY2: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category2/createCategory2',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    GET_ALL_CATEGORY1: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category1/getAllActiveCategory1',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    DELETE_CATEGORY1: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category1/deleteCategory',
    },
    GET_CATEGORY1_BYID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category1/getCategory1ById',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
    },
    CREATE_CATEGORY1: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category1/createCategory',
      reqType: this.CONFIG_KEYS.REQ_TYPE_POST,
    },
    UPDATE_CATEGORY1: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'category1/updateCategory',
      reqType: this.CONFIG_KEYS.REQ_TYPE_PUT,
    },
  };

  public WATCH_LIST_CONFIG: any = {
    GET_GRID_WATCH_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplierCategory/getAllCategory',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/getWatchList.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    ADD_WATCH_LIST: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplierCategory',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'supplier/addWatchList.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
  };

  public HOME_PAGE_CONFIG: any = {
    GET_TENDER_COUNT: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'dashboard/dashboardCountHomepage',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getOpenTenders.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_OPEN_TENDERS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/getAllOpenTenders',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getOpenTenders.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_CLOSED_TENDERS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/getAllClosedTenders',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getClosedTenders.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_AWARDED_TENDERS: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/getAllAwardedTenders',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getAwardedTenders.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_OPEN_TENDER_BY_ID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/tenderDetails',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getOpenTender.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_HOME_TENDER_BY_ID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/tenderOpenDetails',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getOpenTender.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_AWARDED_TENDER_BY_ID: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tender/tenderDetails',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getAwardedTender.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_RESPONSES_RECEIVED: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/tenderOpenInviteSupplierDetails',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getResponsesReceived.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_TENDER_RESPONSE: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'tenderResponse',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getTenderResponse.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_AWARDED_RESPONSES: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/tenderOpenInviteSupplierAwardedDetails',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getResponsesReceived.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
    GET_ALL_AWARDED_RESPONSES: {
      url: this.CONFIG_KEYS.SERVICE_REST_URI + 'supplier/tenderAwardedDetails',
      mockUrl: this.CONFIG_KEYS.MOCK_DIR + 'tender/getResponsesReceived.json',
      reqType: this.CONFIG_KEYS.REQ_TYPE_GET,
      isMock: false,
    },
  };
}

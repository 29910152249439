<div fxLayout="row wrap"
     class="register-box">
    <div fxFlex="30"
         fxFlex.lt-md="100"
         class="login-intro"
         [ngStyle.lt-md]="{'background-color': 'red' }">
        <div fxLayout="row"
             class="">
            <img src="assets/images/session/STE Land Systems logo White.svg"
                 class="m-l-24 m-t-24 ste-logo"
                 alt="STE">
        </div>
        <div fxLayout="row"
             fxHide.lt-md>
            <div style="margin-top: 25%;">
                <h1 class="">Hi, Welcome!</h1>
                <p>Please login to your account</p>
                <p> For more information visit our website <a href="https://www.stengg.com/"
                       target="_blank">https://www.stengg.com/</a></p>
            </div>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="center">
            <button class="login-into-button"
                    [routerLink]="['/auth/login']"
                    mat-stroked-button>{{"login-btn" | translate}}</button>
        </div>
        <div fxLayout="row"
             *ngIf="sBrowser === 'IE'">
            <div style="position: absolute;bottom: 0px;"
                 class="alert alert-warning"
                 role="alert">
                This page is best viewed on Desktop with Chrome.
            </div>
        </div>
    </div>
    <div fxFlex.lt-md="100"
         fxFlex="70"
         class="register">
        <div fxLayout="row wrap"
             fxLayoutAlign="space-around center"
             fxLayoutGap="20px">
            <div fxFlex="100"
                 class="right-box"
                 *ngIf="registerVariables.firstPageFlag">
                <div class="right-inner ">
                    <div fxLayout="row">
                        <div fxFlex="100"
                             class="register-as-style m-t-16">Register as a supplier</div>
                    </div>
                </div>
                <form novalidate
                      [formGroup]="registerForm1"
                      class="app-form-horizontal">
                    <div class="m-t-16">
                        <div fxLayout="row"
                             fxLayoutGap="20px"
                             fxLayout.lt-md="column">
                            <div fxFlex="50"
                                 fxFlex.lt-md="100">
                                <div fxLayout="row">
                                    <label class="register-form-label">{{"name-of-comapny"|translate}}*</label>
                                </div>
                                <mat-form-field appearance="outline">
                                    <input matInput
                                           maxlength="80"
                                           formControlName="nameOfTheCompany">
                                    <mat-error *ngIf="hasError('nameOfTheCompany', 'required')">
                                        {{ 'companyName-Required' | translate}}</mat-error>
                                    <mat-error *ngIf="hasError('nameOfTheCompany', 'pattern')">Company Name should not
                                        start with special character</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50"
                                 fxFlex.lt-md="100">
                                <div fxLayout="row">
                                    <label class="register-form-label">{{"email-address"|translate}}*</label>
                                </div>
                                <mat-form-field appearance="outline">
                                    <input matInput
                                           maxlength="95"
                                           formControlName="email"
                                           email
                                           (blur)="validateEmailID($event)">
                                    <mat-error *ngIf="hasError('email', 'email')">
                                        {{ 'email-address-invalid' |translate }}</mat-error>
                                    <mat-error *ngIf="hasError('email', 'required')">
                                        {{ 'email-address-req' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row"
                             fxLayoutGap="20px"
                             fxLayout.lt-md="column">
                            <div fxFlex="50"
                                 fxLayout="column"
                                 fxFlex.lt-md="100">
                                <div fxLayout="row">
                                    <div fxFlex="70"
                                         fxLayoutAlign="start center">
                                        <label class="register-form-label">{{"password"|translate}}*</label>
                                    </div>
                                    <div fxFlex="30"
                                         fxLayoutAlign="end center">
                                        <span class="iconmoon-info password-info-style"
                                              [popperTrigger]="'hover'"
                                              [popperPlacement]="'right'"
                                              [popperApplyClass]="'popper-container'"
                                              [popper]="passwordInfo">
                                        </span>
                                    </div>
                                </div>
                                <popper-content #passwordInfo>
                                    <p class="word-break">Password should contain a minimum of 12 Alphanumeric characters with at least one
                                        Uppercase, one Lowercase and one Symbol(@,#,$,%,^,&,+,=,!).</p>
                                </popper-content>
                                <mat-form-field appearance="outline">
                                    <input matInput
                                           maxlength="20"
                                           formControlName="password"
                                           type="password">
                                    <mat-error *ngIf="hasError('password', 'pattern')">
                                        {{ 'password-invalid' |translate }}</mat-error>
                                    <mat-error *ngIf="hasError('password', 'required')">
                                        {{ 'login-userPasswordRequired' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50"
                                 fxFlex.lt-md="100">
                                <div fxLayout="row">
                                    <label class="register-form-label">{{"confirm-password"|translate}}*</label>
                                </div>
                                <mat-form-field appearance="outline">
                                    <input maxlength="20"
                                           matInput
                                           type="password"
                                           formControlName="confirmPassword">
                                    <mat-error *ngIf="hasError('confirmPassword', 'required')">
                                        {{ 'confirm-password-req' | translate}}</mat-error>
                                    <mat-error *ngIf="hasError('confirmPassword' ,'mustMatch')">
                                        {{ 'match-password' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="m-t-8"
                             fxLayout="row"
                             fxLayoutGap="20px"
                             fxLayout.lt-md="column">
                            <div fxFlex="50"
                                 fxFlex.lt-md="100">
                                <div fxLayout="row">
                                    <label class="register-form-label">Name of Authorised User*</label>
                                </div>
                                <mat-form-field appearance="outline">
                                    <input matInput
                                           maxlength="45"
                                           formControlName="userName">
                                    <mat-error *ngIf="hasError('userName', 'required')">{{ 'name-required' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="hasError('userName', 'pattern')">Name should not start with
                                        special character</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50"
                                 fxFlex.lt-md="100">
                                <div fxLayout="row">
                                    <label class="register-form-label">{{"country"|translate}}*</label>
                                </div>
                                <mat-form-field appearance="outline">
                                    <mat-select disableOptionCentering
                                                panelClass="common-drop-down-style"
                                                placeholder="select"
                                                formControlName="countryId"
                                                (selectionChange)="getCountryObject($event);">
                                        <mat-option>
                                            <ngx-mat-select-search [placeholderLabel]="'Search'"
                                                                   [noEntriesFoundLabel]="'No matching Country found'"
                                                                   [formControl]="countryFilterCtrl">
                                                <span ngxMatSelectSearchClear></span>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let data of filteredCountries | async; index as i;"
                                                    [value]="data.id">{{data.countryName}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="hasError('countryId', 'required')">{{ 'country-req' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row"
                             fxLayoutGap="20px"
                             fxLayout.lt-md="column">
                            <div fxFlex="50"
                                 fxFlex.lt-md="100"
                                 fxLayout.lt-md="column">
                                <div fxLayout="row">
                                    <label class="register-form-label">{{"register-number"|translate}}*</label>
                                </div>
                                <mat-form-field appearance="outline"
                                                class="isdcode">
                                    <span matPrefix>+{{isdcode}}&nbsp;</span>
                                    <input matInput
                                           formControlName="contactNumber"
                                           autocomplete="off"
                                           [maxlength]="isdlength"
                                           onlyNumberOnPress>
                                    <mat-error *ngIf="hasError('contactNumber', 'required')">
                                        {{ 'register-number-req' | translate}}</mat-error>
                                    <mat-error *ngIf="hasError('contactNumber', 'pattern')">
                                        {{ 'number-invalid' |translate }}</mat-error>
                                    <mat-error
                                               *ngIf="hasError('contactNumber', 'minlength') && !hasError('contactNumber','pattern')">
                                        Contact Number should be {{isdlength}} digits</mat-error>
                                </mat-form-field>
                            </div>
                            <!-- <div fxFlex="50"
                                 fxFlex.lt-md="100"
                                 fxLayout="row"
                                 fxLayoutGap="12px"
                                 fxLayout.lt-md="column">
                                <div>
                                    <label class="register-form-label">{{"did"|translate}}</label>
                                    <mat-form-field appearance="outline">
                                        <input maxlength="15"
                                               matInput
                                               formControlName="didNumber"
                                               autocomplete="off"
                                               (blur)="validateDidNumber()">
                                        <mat-error *ngIf="hasError('didNumber', 'pattern')">
                                            {{ 'didNumber-invalid' |translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <label class="register-form-label">{{"extension"|translate}}</label>
                                    <mat-form-field appearance="outline">
                                        <input maxlength="10"
                                               matInput
                                               formControlName="extension"
                                               autocomplete="off"
                                               [readonly]="!registerForm1.get('didNumber').value">
                                        <mat-error *ngIf="hasError('extension', 'pattern')">
                                            {{ 'didNumber-invalid' |translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div> -->
                            <div fxFlex="50"
                                 fxFlex.lt-md="100">
                                <div fxLayout="row">
                                    <div fxFlex="70"
                                         fxLayoutAlign="start center">
                                         <label class="register-form-label">{{"type-of-industry"|translate}}</label> <span style="font-size: small;color: grey;">(Select upto 3 industry types)</span>
                                    </div>
                                    <div fxFlex="30"
                                         fxLayoutAlign="end center">
                                        <span class="iconmoon-info password-info-style"
                                              [popperTrigger]="'hover'"
                                              [popperPlacement]="'right'"
                                              [popperApplyClass]="'popper-container'"
                                              [popper]="industryTypeInfo">
                                        </span>
                                    </div>
                                    <popper-content #industryTypeInfo>
                                        <p>Type of industry can have only upto 3 values</p>
                                    </popper-content>
                                </div>
                                <mat-form-field appearance="outline">
                                    <mat-select multiple (selectionChange)="getSubIndustryType($event)"
                                                disableOptionCentering
                                                panelClass="common-drop-down-style"
                                                placeholder="select"
                                                formControlName="industryTypesList">
                                        <mat-option>
                                            <ngx-mat-select-search [placeholderLabel]="'Search'"
                                                                   [noEntriesFoundLabel]="'No matching Industry Type found'"
                                                                   [formControl]="industryFilterCtrl">
                                                <span ngxMatSelectSearchClear></span>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [disabled]="isIndustryTypeDisabled && !registerForm1.get('industryTypesList').value?.includes(data.id)" matTooltip="{{data.industryName}}"
                                                    *ngFor="let data of filteredIndustryTypes | async; index as i;"
                                                    [value]="data.id">{{data.industryName}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="hasError('industryTypesList', 'required')">
                                        {{ 'type-of-industry-req' | translate}}</mat-error>
                                        <mat-error *ngIf="hasError('industryTypesList', 'industryLength')">
                                            Type of industry can only have upto 3 values</mat-error>
                                </mat-form-field>
                                <!-- <mat-form-field class="form-field-style" appearance="outline">
                                    <mat-select multiple disableOptionCentering panelClass="common-drop-down-style"
                                        placeholder="select" formControlName="lstCompanyTenderMapDTO">
                                        <mat-option>
                                            <ngx-mat-select-search [placeholderLabel]="'Search'"
                                                [noEntriesFoundLabel]="'No matching Company found'"
                                                [formControl]="companyFilterCtrl">
                                                <span ngxMatSelectSearchClear></span>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let data of filteredCountryTypes | async; index as i;"
                                            [value]="data.companyId">{{data.nameOfTheCompany}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="hasError('lstCompanyTenderMapDTO', 'required')">
                                        {{ 'supplierList-required' | translate}}</mat-error>
                                </mat-form-field> -->
                            </div>
                        </div>
                        <!-- <div fxLayout="row"
                             fxLayoutGap="20px"
                             fxLayout.lt-md="column">
                            
                            <div fxFlex="50"
                                 fxFlex.lt-md="100">
                                <div fxLayout="row">
                                    <label class="register-form-label">{{"type-of-sub-industry"|translate}}</label>
                                </div>
                                <mat-form-field appearance="outline">
                                    <mat-select disableOptionCentering
                                                panelClass="common-drop-down-style"
                                                placeholder="select"
                                                formControlName="subIndustryId">
                                        <mat-option>
                                            <ngx-mat-select-search [placeholderLabel]="'Search'"
                                                                   [noEntriesFoundLabel]="'No matching Sub Industry Type found'"
                                                                   [formControl]="subIndustryFilterCtrl">
                                                <span ngxMatSelectSearchClear></span>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let data of filteredSubIndustryTypes | async; index as i;"
                                                    [value]="data.id">{{data.subIndustryName}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="hasError('subIndustryId', 'required')">
                                        {{ 'sub-industry-required' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div> -->
                    </div>
                </form>
                <div class="button-box-first"
                     fxLayout="row"
                     fxLayoutAlign="center">
                    <button class="cancel-button m-r-8"
                            (click)="CancelButton1click($event)"
                            mat-stroked-button>{{"cancel-btn" | translate}}</button>
                    <button [ngStyle]="{'opacity':!registerForm1.valid ? '0.7' : '1' }"
                            class="submit-button"
                            [disabled]="!registerForm1.valid"
                            (click)="nextButtonClick($event)"
                            mat-stroked-button>{{"next-btn" | translate}}</button>
                </div>
            </div>
            <!--  Second Page of the register flag [disabled]="!registerForm1.valid"   -->
            <div fxFlex="100"
                 class="right-box"
                 *ngIf="registerVariables.secondPageFlag">
                <div class="right-inner">
                    <div fxLayout="row">
                        <div fxFlex="100"
                             class="register-as-style m-t-16 ">Register as a supplier</div>
                    </div>
                </div>
                <form novalidate
                      [formGroup]="registerForm2"
                      class="app-form-horizontal">
                    <div class="m-t-16">
                        <div fxLayout="row"
                             fxLayoutGap="20px"
                             fxLayout.lt-md="column">
                            <div fxFlex="50"
                                 fxFlex.lt-md="100">
                                 <div fxLayout="row">
                                        <label class="register-form-label">{{"company-website"|translate}}</label>
                                    </div>
                                    <mat-form-field appearance="outline">
                                        <input maxlength="95"
                                               matInput
                                               formControlName="websiteUrl">
                                        <mat-error *ngIf="hasError2('websiteUrl', 'required')">
                                            {{ 'company-website-req' | translate}}</mat-error>
                                        <mat-error *ngIf="hasError2('websiteUrl', 'pattern')">
                                            {{ 'company-website-invalid' | translate}}</mat-error>
                                    </mat-form-field>
                                <!-- <div>
                                    <mat-form-field class="width-hundread-percent"
                                                    appearance="outline">
                                        <input maxlength="95"
                                               class="placeholder-style"
                                               matInput
                                               formControlName="officeAddressLine2"
                                               placeholder="{{ 'address-2' | translate }}">
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="width-hundread-percent"
                                                    appearance="outline">
                                        <input maxlength="15"
                                               class="placeholder-style"
                                               matInput
                                               formControlName="officepostalCode"
                                               placeholder="{{ 'postal-code' | translate }}">
                                        <mat-error *ngIf="hasError2('officepostalCode', 'required')">
                                            {{ 'postal-req' | translate}}</mat-error>
                                        <mat-error *ngIf="hasError2('officepostalCode','pattern')">
                                            {{ 'postal-invalid' |translate }}</mat-error>
                                    </mat-form-field>
                                </div> -->
                            </div>
                            <div fxFlex="50"
                                    fxFlex.lt-md="100">
                                <div fxLayout="row">
                                    <label class="register-form-label">{{"business-reg-number"|translate}}*</label>
                                </div>
                                <mat-form-field appearance="outline">
                                    <input maxlength="45"
                                            matInput
                                            formControlName="businessRegistrationNumber"
                                            (blur)="validateBusinessRegistrationNumber($event)">
                                    <mat-error *ngIf="hasError2('businessRegistrationNumber', 'required')">
                                        {{ 'business-reg-number-req' | translate}}</mat-error>
                                    <mat-error *ngIf="hasError2('businessRegistrationNumber', 'pattern')">Business
                                        Registration Number should not start with special character</mat-error>
                                </mat-form-field>
                            </div>
                            <!-- <div fxFlex="50"
                                 fxFlex.lt-md="100">
                                <div fxLayout="row">
                                    <label class="register-form-label">{{"manufacturing-address"|translate}}*</label>
                                </div>
                                <div>
                                    <mat-form-field class="width-hundread-percent"
                                                    appearance="outline">
                                        <input maxlength="95"
                                               class="placeholder-style"
                                               matInput
                                               formControlName="manufacturingAddressLine1"
                                               placeholder="{{ 'address-1' | translate }}">
                                        <mat-error *ngIf="hasError2('manufacturingAddressLine1', 'required')">
                                            {{ 'address1-req' | translate}}</mat-error>
                                        <mat-error *ngIf="hasError2('manufacturingAddressLine1', 'pattern')">
                                            Manufacturing Address should not start with special character</mat-error>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="width-hundread-percent"
                                                    appearance="outline">
                                        <input maxlength="95"
                                               class="placeholder-style"
                                               matInput
                                               formControlName="manufacturingAddressLine2"
                                               placeholder="{{ 'address-2' | translate }}">
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="width-hundread-percent"
                                                    appearance="outline">
                                        <input maxlength="15"
                                               class="placeholder-style"
                                               matInput
                                               formControlName="manufacturingpostalCode"
                                               placeholder="{{ 'postal-code' | translate }}">
                                        <mat-error *ngIf="hasError2('manufacturingpostalCode', 'required')">
                                            {{ 'postal-req' | translate}}</mat-error>
                                        <mat-error *ngIf="hasError2('manufacturingpostalCode','pattern')">
                                            {{ 'postal-invalid' |translate }}</mat-error>

                                    </mat-form-field>
                                </div>
                            </div> -->
                        </div>

                        <div>
                            <div fxLayout="row"
                                 fxLayoutGap="20px"
                                 fxLayout.lt-md="column">
                                
                                <div fxFlex="50"
                                     fxFlex.lt-md="100">
                                    <div fxLayout="row">
                                        <label class="register-form-label">{{"office-address"|translate}}*</label>
                                    </div>
                                    <div>
                                        <mat-form-field class="width-hundread-percent"
                                                        appearance="outline">
                                            <!-- <input maxlength="95"
                                                   class="placeholder-style"
                                                   matInput
                                                   formControlName="officeAddressLine1"
                                                   > -->
                                                <textarea maxlength="500"
                                                   matInput
                                                   style="resize: none;height: -webkit-fill-available;"
                                                   formControlName="officeAddressLine1">
                                                </textarea>
                                            <mat-error *ngIf="hasError2('officeAddressLine1', 'required')">
                                                {{ 'address1-req' | translate}}</mat-error>
                                            <mat-error *ngIf="hasError2('officeAddressLine1', 'pattern')">Office Address
                                                should not start with special character</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="50"
                                     fxFlex.lt-md="100">
                                    <div fxLayout="row">
                                        <label class="register-form-label">Core Business
                                            {{"description"|translate}}*</label>
                                    </div>
                                    <mat-form-field class="form-field-text-area-style"
                                                    appearance="outline">
                                        <textarea maxlength="500"
                                                  matInput
                                                  style="resize: none;height: -webkit-fill-available;"
                                                  formControlName="description">
                                    </textarea>
                                        <mat-error *ngIf="hasError2('description', 'required')">Please describe your
                                            Business and Services</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <!-- <div fxLayout="row"
                                 fxLayoutGap="20px"
                                 fxLayout.lt-md="column">
                                
                            </div> -->

                            <!-- <div fxLayout="row"
                                 fxLayoutGap="20px"
                                 fxLayout.lt-md="column">
                                <div fxFlex="50">
                                    <div fxLayout="row">
                                        <label class="register-form-label">{{"company-profile"|translate}}*</label>
                                    </div>
                                    <mat-form-field class="upload-file-field"
                                                    appearance="outline">
                                        <div fxFlex="100"
                                             fxLayout="row">
                                            <div fxFlex="50"
                                                 fxLayoutAlign="start center">
                                                <input matInput
                                                       formControlName="attachmentFile"
                                                       readonly>
                                            </div>
                                            <div fxFlex="50"
                                                 fxLayoutAlign="end end">
                                                <button mat-flat-button
                                                        type="button"
                                                        class="choose-file-button-style"
                                                        (click)="fileUpload.click()">{{'choose-file' | translate}}</button>
                                            </div>
                                        </div>
                                        <input type="file"
                                               style="display:none;"
                                               #fileUpload
                                               (click)="fileUpload.value = null"
                                               value=""
                                               (change)="CompanyProfileUplaod($event)" />
                                        <mat-error *ngIf="hasError2('attachmentFile', 'required')">
                                            {{ 'company-profile-file-req' | translate}}</mat-error>
                                        <mat-hint>Note:Each file uploaded should be less than or equal to 10MB
                                        </mat-hint>
                                    </mat-form-field>
                                    <div class="attachment-file" *ngIf="this.registerForm2.value.attachmentFile != '' && this.registerForm2.value.attachmentFile != undefined && this.registerForm2.value.attachmentFile != null"
                                         fxLayout="row">
                                        <span matPrefix>
                                            <mat-icon (click)="DeleteFile()"
                                                      class="attach-icon-style cursor-pointer">delete</mat-icon>&nbsp;
                                        </span>
                                        <label>{{this.registerForm2.value.attachmentFile}} - Uploaded</label>
                                    </div>
                                </div>
                            </div> -->
                        </div>

                    </div>
                </form>
                <div class="button-box-second"
                     fxLayout="row"
                     fxLayoutAlign="center"
                     fxLayoutGap="10px">
                    <button class="cancel-button m-r-8"
                            type="button"
                            (click)="CancelButton2click($event)"
                            mat-stroked-button>{{"cancel-btn" | translate}}</button>
                    <button class="cancel-button m-r-8"
                            type="button"
                            (click)="previousButtonClick($event)"
                            mat-stroked-button>{{"previous-btn" | translate}}</button>
                    <button class="submit-button"
                            type="submit"
                            appPreventDoubleClick
                            (throttledClick)="RegisterButtonClick()"
                            [disabled]="registerFlag"
                            mat-stroked-button>{{"register-btn" | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SessionsService, HttpService, ServiceConfigs } from '@core';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class AdminLoginComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public loginForm: UntypedFormGroup;
  public hasDangerAlert: boolean = false;
  public errorMessage: string;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
    private toastr: ToastrServiceClass,
    private sessionsSrv: SessionsService) { }

  // life cycle hooks

  ngOnInit() {
    const sessionUser = this.sessionsSrv.getCurrentUser();
    this.sessionsSrv.setStorage({});
    // if (sessionUser['access_token'] && sessionUser['userType'] === '1' || sessionUser['access_token'] && sessionUser['userType'] === '2' ) {
    //   this.router.navigate(['/dashboard']);
    // }
    // if (sessionUser['access_token'] && sessionUser['userType'] === '0') {
    //   this.router.navigate(['/admin/dashboard']);
    // }
    // if (sessionUser['access_token'] && sessionUser['userType'] === '4') {
    //   this.router.navigate(['/admin/dashboard']);
    // }
    // if (sessionUser['access_token'] && sessionUser['userType'] === '7') {
    //   this.router.navigate(['/admin-role/dashboard']);
    // }
    // if (sessionUser['access_token'] && sessionUser['userType'] === '3') {
    //   this.router.navigate(['/buyer/dashboard']);
    // }
    // if (sessionUser['access_token'] && sessionUser['userType'] === '5') {
    //   this.router.navigate(['/sqa/supplier-details']);
    // }
    // if (sessionUser['access_token'] && sessionUser['userType'] === '6') {
    //   this.router.navigate(['/engineer/supplier-details']);
    // }
    this.buildLoginForm();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  private buildLoginForm() {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  public onSubmit() {
    if (this.loginForm.valid) {
      const request = 'username=' + this.loginForm.value.username + '&password=' +  encodeURIComponent(this.loginForm.value.password);
      this.toastr.loaderShow();
      this.httpService.invokePost(this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.ADMIN_LOGIN, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        try {
          if (data?.body !== undefined && data?.body !== '' && data?.body?.isError === false) {
            this.toastr.loaderHide();
            // this.oauthInit(data?.body?.payload.email, this.loginForm.value.password);
            localStorage.setItem('supplier-tuser', this.loginForm.value.username);
            localStorage.setItem('supplier-tpass', this.loginForm.value.password);
            localStorage.setItem('supplier-temail', data?.body?.payload.email);
            localStorage.setItem('isFrom', 'adminLogin');
            this.router.navigate(['/enter-otp']);

          } else if (data?.body?.isError === true) {
            if (data?.body?.code === 205) {
              this.toastr.error(data?.body?.message);
              this.router.navigate(['/auth/forgot-password/mngr/false']);
            } else {
              if (data?.body?.code === 100) {
                this.toastr.error(data?.body?.message);
                localStorage.setItem('supplier-temail', this.loginForm.value.username);
                this.router.navigate(['/auth/forgot-password/mngr/true']);
              } else if(data?.body?.code === 204) {
                this.errorMessage = data?.body?.message;
                this.hasDangerAlert = true;
              } else {
                this.errorMessage = 'Unable to Login!';
                this.hasDangerAlert = true;
              }
            }
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      }, () => {

        this.toastr.loaderHide();
      });
    }
  }

  public cancelClick() {
    this.loginForm.patchValue({ username: '' });
    this.loginForm.patchValue({ password: '' });
    this.loginForm.controls['username'].setErrors({ 'incorrect': true });
    this.loginForm.controls['password'].setErrors({ 'incorrect': true });
    this.loginForm.markAsUntouched();
  }

  public forgotPassword() {
    const user = 'mngr';
    this.router.navigate(['/auth/forgot-password/' + user + '/false']);
  }

  public login() {
    this.router.navigateByUrl('/');
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  }

  public oauthInit(user, pass) {
    const request = 'username=' + user + '&password=' + pass + '&grant_type=password';
    this.httpService.invokePost(this.serviceConfigs.AUTH_LOGIN_SERVICES_CONFIG.OAUTH_LOGIN, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data?.body !== undefined && data?.body !== '') {
          // Login succeeded
          if (data?.body?.userType === '0') {
            this.sessionsSrv.setStorage(data?.body);
            setTimeout(() => {
              this.router.navigate(['/admin/dashboard']);
            }, 100);
          } else if (data?.body?.userType === '4') {
            this.sessionsSrv.setStorage(data?.body);
            setTimeout(() => {
              this.router.navigate(['/admin/dashboard']);
            }, 100);
          }
          else if (data?.body?.userType === '3') {
            this.sessionsSrv.setStorage(data?.body);
            setTimeout(() => {
              this.router.navigate(['/buyer/dashboard']);
            }, 100);
          } else if (data?.body?.userType === '5') {
            this.sessionsSrv.setStorage(data?.body);
            setTimeout(() => {
              this.router.navigate(['/sqa/dashboard']);
            }, 100);
          } else if (data?.body?.userType === '6') {
            this.sessionsSrv.setStorage(data?.body);
            setTimeout(() => {
              this.router.navigate(['/engineer/supplier-details']);
            }, 100);
          } else if (data?.body?.userType === '7') {
            this.sessionsSrv.setStorage(data?.body);
            setTimeout(() => {
              this.router.navigate(['/admin-role/dashboard']);
            }, 100);
          }
        } else {
          this.hasDangerAlert = true;
        }
      } catch (ex) {

      }
    }, (err) => {


    });
  }

}


<div>
    <h2 mat-dialog-title>Supplier Name : {{supplierDetails.productName}}  <span><mat-icon (click)="dialogclose()" class="float-right cursor-pointer icon-18">close</mat-icon> </span> </h2>
  </div>
  <mat-dialog-content class="mat-typography view-rfq-respond add-product">
      <div style="padding-bottom: 32px;" class="p-l-16 w-full" fxFlex="90"  fxLayout="row wrap">
          <div fxFlex="100" fxLayout="row wrap" fxLayoutGap="20px grid">
              <div fxFlex="50" fxFlex.lt-sm="100">
                  <div class="text-grey-500" >Supplier name</div> 
                  <div class="text-grey-900" > {{  supplierDetails.userName  }} </div>
              </div>
              <div fxFlex="50" fxFlex.lt-sm="100">
                <div class="text-grey-500" >Email </div> 
                <div class="text-grey-900" > {{ supplierDetails.email }}</div>
              </div>
              <div fxFlex="50" fxFlex.lt-sm="100">
                <div class="text-grey-500" >Offer Price /Unit (in SGD)</div>
                <div class="text-grey-900" >{{ supplierDetails.offerPrice }} </div>
              </div>
              <div fxFlex="50" fxFlex.lt-sm="100">
                <div class="text-grey-500" > Requested Quantity </div> 
                <div class="text-grey-900" >{{ supplierDetails.interestedQty }} </div>
              </div>
              <div fxFlex="50" fxFlex.lt-sm="100">
                <div class="text-grey-500" >Requested Date </div> 
                <div class="text-grey-900" > {{ supplierDetails.createdOn | date: 'dd MMM yyyy h:mm a' }}</div>
              </div>
              <div fxFlex="50" fxFlex.lt-sm="100">
                <div class="text-grey-500" > Total Quantity </div> 
                <div class="text-grey-900" >{{ supplierDetails.totalQty }} </div>
              </div>
              <div fxFlex="50" fxFlex.lt-sm="100">
                <div class="text-grey-500" > Available Quantity </div> 
                <div class="text-grey-900" >{{ supplierDetails.qtyAvailable }} </div>
              </div>
              <div fxFlex="50" fxFlex.lt-sm="100">
                  <div class="text-grey-500" > Make Price /Unit (In SGD)</div>
                  <div class="text-grey-900" >
                    <!-- {{ supplierDetails.makeOffer }}  -->
                    {{supplierDetails.makeOffer ? supplierDetails.makeOffer : 'NA'}}
                  </div>
              </div>
          </div>
          <form *ngIf="data.screen !== 'sold'" novalidate [formGroup]="productForm" class="app-form-horizontal">
            <div fxFlex="100" fxLayout="row wrap" class="m-t-48">
                <div fxLayout="row" fxFlex="100" fxLayoutGap="20px" fxLayout.lt-md="column">  
                  <div  fxFlex="50" fxFlex.lt-sm="100">
                    <label class="register-form-label">{{"selling-qty"|translate}}*</label>
                     <mat-form-field class="form-field-style" appearance="outline">
                     <input onlyNumberOnDown (blur)="checkInterestedQty()" maxlength="12" matInput formControlName="qtyAwarded" >
                     <mat-error *ngIf="hasError('qtyAwarded', 'required')">{{ 'selling-qty-required' |translate }}</mat-error>
                     <mat-error *ngIf="hasError('qtyAwarded', 'pattern')">{{ 'didNumber-invalid' | translate }}</mat-error>  
                  </mat-form-field>
                </div>
                  <div  fxFlex="50" fxFlex.lt-sm="100">
                    <label class="register-form-label">{{"selling-price"|translate}}* (In SGD)</label>
                    <mat-form-field class="form-field-style" appearance="outline">
                    <input maxlength="15" matInput formControlName="awardPrice" onlyNumberOnDown>
                    <mat-error *ngIf="hasError('awardPrice', 'required')">{{ 'selling-price-required' |translate }}</mat-error>
                    <mat-error *ngIf="!hasError('awardPrice', 'required') && hasError('awardPrice', 'awardPriceValidate')">{{ 'decimal-invalid' | translate }}</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" fxFlex="100" class="m-t-32" fxLayoutGap="20px" fxLayout.lt-md="column">
                    <div fxFlex="50" fxFlex.lt-sm="100">
                        <div class="text-grey-500" > Total Selling Value (in SGD)</div> 
                        <div class="text-grey-900" >{{  productForm.value.qtyAwarded * productForm.value.awardPrice }} </div>
                    </div> 
                </div>    
            </div>  
          </form>

        </div>  
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="data.screen !== 'sold'" align="center">
      <button class="m-r-8 cancel-button" (click)="dialogclose()" mat-stroked-button > Cancel </button>
      <button class="m-r-8 action-button" (click)="Award()" mat-stroked-button > Sell </button>
  </mat-dialog-actions>
  
  
  
import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService, ServiceConfigs } from '@core';
import { SmartDialog } from 'app/shared/components/smart-dialog/smart-dialog';
import { CustomDateRemoverPipe } from '@shared/pipes/custom-date-remover.pipe';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Helper } from '@core/helper';



@Component({
  selector: 'app-view-response',
  templateUrl: './view-response.component.html',
  styleUrls: ['./view-response.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class ViewResponseComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public buyerDetails: any = {};
  public companyName: any = '';

  public columns: any[] = [
    {
      title: 'Item No.',
      index: 'sNo',
      width: 'auto',
      sort: false
    },
    {
      title: 'Part Number',
      index: 'partNo',
      width: 'auto'
    },
    {
      title: 'Name',
      index: 'productName',
      width: 'auto'
    },
    {
      title: 'Description',
      index: 'productDescription',
      type: 'format',
      format: (data: any) => {
        const shortText = data.productDescription.length > 100 ? data.productDescription.substr(0, 100) + '...' : data.productDescription;
        return `<span matTooltip="${data.productDescription}" >${shortText}</span>`;
      }
    },
    {
      title: 'UOM',
      index: 'uom',
      width: 'auto'
    },
    {
      title: 'Required Quantity',
      index: 'quantity',
      width: 'auto'
    }
  ];
  public list: any[] = [];
  public isLoading: boolean = true;
  public status: any;
  public tabName: string;
  constructor(private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
    private smartDialog: SmartDialog,
    private dateRemover: CustomDateRemoverPipe,
    private dialogRef: MatDialogRef<ViewResponseComponent>,
    private toastr: ToastrServiceClass,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.status = data.status;
    this.tabName = data.tabName;
  }

  // life cycle hooks

  ngOnInit() {
    this.companyName = this.data.companyName;
    this.getBuyerRFQDetails();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  public getBuyerRFQDetails() {
    const requestObject = '?tenderId=' + this.data.tenderId + '&userId=' + this.data.userId + '&companyId=' + this.data.companyId;
    this.httpService.invokeService(this.serviceConfigs.HOME_PAGE_CONFIG.GET_TENDER_RESPONSE, requestObject).then((response: any) => {
      if (response) {
        this.buyerDetails = response?.payload;
        this.buyerDetails?.tenderResponseDetailsDTO.map((item: any, index: any) => {
          item.itemNo = index + 1;
        })
      }
    });
  }

  public downloadFileView(fileName: any) {

    this.toastr.loaderShow();
    const request = '?filename=' + encodeURIComponent(fileName) + '&userId=' + this.data.userId + '&tenderId=' + this.data.tenderId;
    this.httpService.invokePostWithParams(this.serviceConfigs.RFQ_TENDOR_SERVICES.DOWNLOAD_FILE, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data) {
          if (data?.isError === false) {
            if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
              Helper.downloadIE(data?.payload, this.dateRemover.transform(fileName));
            } else {
              Helper.download(data?.payload, this.dateRemover.transform(fileName));
            }

          } else {
            this.toastr.error(data?.message);
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    });
  }

  public downloadFile(fileName: any) {

    this.toastr.loaderShow();
    const request = '?filename=' + encodeURIComponent(fileName) + '&companyId=' + this.data.companyId + '&tenderId=' + this.data.tenderId;
    this.httpService.invokePostWithParams(this.serviceConfigs.RFQ_TENDOR_SERVICES.DOWNLOAD_RESPONSE, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data) {
          if (data?.isError === false) {
            if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
              Helper.downloadIE(data?.payload, this.dateRemover.transform(fileName));
            } else {
              Helper.download(data?.payload, this.dateRemover.transform(fileName));
            }

          } else {
            this.toastr.error(data?.message);
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    });
  }

  public dialogclose() {
    this.dialogRef.close();
  }

  public print() {
    const pageTitle = `<div fxFlex="100" fxFlex.lt-sm="100" style="text-align:right"> 
        ${this.data.tenderRefNumber}
      </div>`
    const title = `<title>STE - ${this.data.tenderName} - ${this.data.tenderRefNumber} </title>`;

    const contents = document.getElementById('print-content').innerHTML;
    let frame1: any = document.createElement('iframe');
    frame1.name = "frame3";
    frame1.style.position = "absolute";
    frame1.style.top = "-1000000px";
    document.body.appendChild(frame1);
    let frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
    frameDoc.document.open();
    frameDoc.document.write('<html><head> ' + title + '<style>#print-header { font-family: sans-serif, Arial, Helvetica; border-collapse: collapse;    width: 100%;	padding: 10px;}h1{  font-size: 18px;  color: #263237;  font-weight: 600;  text-align: right;}#print-content {    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;  border-collapse: collapse; width: 100%;	padding: 10px;	font-size:12px;	table-layout: fixed;} .text-grey-500, .key-style {font-weight: 600;}</style>');
    frameDoc.document.write('</head><body>');
    frameDoc.document.write(pageTitle + contents);
    frameDoc.document.write('</body></html>');
    frameDoc.document.close();
    setTimeout(function () {
      window.frames["frame3"].focus();
      window.frames["frame3"].print();
      document.body.removeChild(frame1);
    }, 500);
    return false;

  }

  public awardTender() {
    if (this.data.status === 'NotAwarded' || this.data.status === 'Awarded') {
      this.toastr.info('The tender is already Awarded');
    } else {
      this.smartDialog.open({
        title: 'Award Tender',
        description: 'Are you sure to award the tender ?',
        buttons: [
          {
            type: '',
            text: 'Cancel',
            onClick: () => {
              return;
            },
          },
          {
            type: 'primary',
            text: 'Confirm',
            onClick: () => {
              const requestObject = '?tenderId=' + this.data.tenderId + '&status=' + 'Awarded' + '&userId=' + this.data.userId;
              this.httpService.invokePostWithParams(this.serviceConfigs.RFQ_TENDOR_SERVICES.AWARD_TENDER, requestObject).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
                try {
                  if (data && data?.isError === false) {
                    this.toastr.success(data?.message);
                    this.dialogRef.close();
                  } else if (data && data?.isError === true) {
                    this.toastr.error(data?.message);
                    this.dialogRef.close();
                  }
                } catch (ex) {
                  this.toastr.error(ex);
                }
              }, (err: any) => {

              });
            },
          }
        ],
      });
    }
  }
}

<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content *ngIf="data.description">
  <p>{{data.description}}</p>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button *ngFor="let btn of data.buttons"
          mat-button
          [color]="btn.type"
          (click)="onClick(btn.onClick)">{{btn.text}}</button>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionsService, HttpService, ServiceConfigs, WebsocketService } from '@core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-user-panel',
  template: `
    <div
      class="app-user-panel"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <img
        class="app-user-panel-avatar" [class.cursor-pointer]="isSupplier === true"
        src="assets/images/supplier-avatar.png"
        alt="avatar" (click)="supplierProfile()"
        *ngIf="imageName === ''"
      />
      <img
        class="app-user-panel-avatar" [class.cursor-pointer]="isSupplier === true"
        [src]="transform()"
        alt="avatar" (click)="supplierProfile()"
        *ngIf="imageName !== ''"
      />
      <h4 class="app-user-panel-name m-t-0 m-b-8 f-w-400">{{currentUser.username}}</h4>
      <h5 class="app-user-panel-email m-t-0 m-b-8 f-w-400">{{currentUser.email}}</h5>
      <div class="app-user-panel-icons text-nowrap">
        
        <a mat-icon-button class="m-r-16" (click)="notificationNav()"  *ngIf="showNotification" >
          <mat-icon class="icon-24" [matBadge]="notifiyCounts"  >notifications_none</mat-icon>
        </a>
        <!--
        <a mat-icon-button class="m-r-16" style="pointer-events: none;" >
          <mat-icon class="icon-24">mail_outline</mat-icon>
        </a> -->
        <a (click)="logout()" mat-icon-button >
          <mat-icon class="icon-24"  matTooltip="Logout" >exit_to_app</mat-icon>
        </a>
      </div>
    </div>
  `,
  providers: [HttpService, ServiceConfigs, WebsocketService]
})
export class UserPanelComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public currentUser: any;
  public imageSource: any;
  public imageName: any = '';
  public isSupplier: boolean = false
  public showNotification: boolean = false;
  public notifiyCounts: any = '';
  public topicName: string = '';
  public messageName: string = '';
  public isLogout: boolean = false;
  constructor(
    private sessionsSrv: SessionsService,
    private router: Router,
    private httpService: HttpService,
    private serviceConfigs: ServiceConfigs,
    private sanitizer: DomSanitizer,
    private wsService: WebsocketService) {
    this.currentUser = this.sessionsSrv.getCurrentUser();
    this.sessionsSrv.refreshPrfileImage.subscribe(item => {
      this.getProfileImage();
    });

    if (this.currentUser['userType'] === '1' || this.currentUser['userType'] === '2') {
      this.isSupplier = true;
      this.topicName = '/topic/supplierBellNotification';
      this.messageName = '/notifications/supplierBell';
    } else if (this.currentUser['userType'] === '3') {
      this.topicName = '/topic/buyerBellNotification';
      this.messageName = '/notifications/buyerBell';
    } else {
      this.topicName = '/topic/adminBellNotification';
      this.messageName = '/notifications/adminBell';
    }

    if (this.currentUser['userType'] === '0' || this.currentUser['userType'] === '1' || this.currentUser['userType'] === '2'
      || this.currentUser['userType'] === '3' || this.currentUser['userType'] === '4') {
      this.showNotification = true;
      const websocketUserID = (this.currentUser['userType'] === '1' || this.currentUser['userType'] === '2') ? this.currentUser['companyId'] : this.currentUser['userId'];
      setTimeout(() => {
        this.wsService.initializeWebSocket(this.topicName, this.messageName, websocketUserID, this.bellNotificationSuccessCallback.bind(this));
      }, 100);

    }

  }

  // life cycle hooks

  ngOnInit() {
    this.getProfileImage();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
    const sessionUser = this.sessionsSrv.getCurrentUser();
    this.wsService.disconnect();

    if (sessionUser['userId'] && this.isLogout !== true) {
      const userID = sessionUser['userId'];
      this.logoutAudit(userID);
      setTimeout(() => {
        this.sessionsSrv.setStorage({});
        // sessionStorage.removeItem('BuyerTenderFilter');
        // sessionStorage.removeItem('BuyerInvitedTenderFilter');
        // sessionStorage.removeItem('tenderFilter');
        // sessionStorage.removeItem('invitedTenderFilter');
        // sessionStorage.removeItem('BuyerSecondaryTenderFilter');
        // sessionStorage.removeItem('BuyerSecondaryInvitedTenderFilter');
      }, 100);
    }
  }

  // functions

  public bellNotificationSuccessCallback(data) {
    this.notifiyCounts = (data?.body?.payload.count != undefined && data?.body?.payload.count > 0) ? data?.body?.payload.count : '';
  }

  public logout() {
    const sessionUser = this.sessionsSrv.getCurrentUser();
    this.logoutAudit(sessionUser['userId']);
    this.isLogout = true;
    setTimeout(() => {
      this.sessionsSrv.setStorage({});
      // sessionStorage.removeItem('BuyerTenderFilter');
      // sessionStorage.removeItem('BuyerInvitedTenderFilter');
      // sessionStorage.removeItem('tenderFilter');
      // sessionStorage.removeItem('invitedTenderFilter');
      // sessionStorage.removeItem('BuyerSecondaryTenderFilter');
      // sessionStorage.removeItem('BuyerSecondaryInvitedTenderFilter');
      if (sessionUser['userType'] === '1' || sessionUser['userType'] === '2') {
        this.router.navigate(['/auth/login']);
      }
      if (sessionUser['userType'] === '0' || sessionUser['userType'] === '3' || sessionUser['userType'] === '5' || sessionUser['userType'] === '6' || sessionUser['userType'] === '4' || sessionUser['userType'] === '7') {
        this.router.navigate(['/admin-login']);
      }
    }, 200);
  }

  public notificationNav() {
    const sessionUser = this.sessionsSrv.getCurrentUser();
    if (sessionUser['userType'] === '1' || sessionUser['userType'] === '2') {
      this.router.navigate(['/message-notifications/notifications']);
    }
    if (sessionUser['userType'] === '3') {
      this.router.navigate(['/buyer/message-notifications/notifications']);
    }
    if (sessionUser['userType'] === '0' || sessionUser['userType'] === '4' || sessionUser['userType'] === '7') {
      this.router.navigate(['/admin/notifications']);
    }
  }

  public supplierProfile() {
    const sessionUser = this.sessionsSrv.getCurrentUser();
    if (sessionUser['userType'] === '1' || sessionUser['userType'] === '2') {
      this.router.navigate(['/supplier-profile']);
    }
  }

  public getProfileImage() {
    const request = '?userId=' + this.sessionsSrv.getUserId();
    this.httpService.invokeGet(this.serviceConfigs.SUPPLIER_APP_SERVICES_CONFIG.GET_SUPPLIER_PROFILE_IMAGE, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data) {
          if (data?.payload) {
            this.imageName = data?.message;
            this.imageSource = 'data:image/png;base64,' + data?.payload;
          } else {
            this.imageName = '';
          }
        }
      } catch (ex) {

      }
    }, (err) => {


    }, () => {


    });
  }

  public transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.imageSource);
  }
  
  public logoutAudit(userID) {
    if (userID !== '') {
      const request = '?action=logout&userId=' + userID;
      this.httpService.invokePostWithParams(this.serviceConfigs.AUDIT_CONFIG.LOGOUT_AUDIT_CALL, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        try {
          if (data) {
            // this.sessionsSrv.setStorage({});
          }
        } catch (ex) {

        }
      }, (err) => {

      }, () => {

      });
    }
  }
}

<div fxLayout="column" id="otp-expired-page">
    <!-- <img src="assets/images/session/left-flower.png" class="left-flower" alt="left-flower">
    <img src="assets/images/session/full-flower.png" class="full-flower" alt="Full-flower">
    <img src="assets/images/session/bottom-flower.png" class="bottom-flower" alt="bottom-flower"> -->
    <div fxFlex="30">
        <div class="header-box" fxLayout="row" fxFlex="100">
            <div fxFlex fxLayoutAlign="start center"><img src="../../../../assets/images/session/STE Land Systems logos.png" [ngStyle.gt-lg]="{'width.px': 240}"  [ngStyle.lt-lg]="{'width.px': 180}" class="ste-logo" alt="STE"></div>
        </div>
    </div>
    <div fxFlex="70" fxLayoutAlign="space-around start">
        <div fxLayout="column" fxLayoutAlign="center" class="inner-box">
            <div class="sub-heading-style">{{messageService.displayBoxMessage}}</div>
            <div fxLayoutAlign="space-around center"><button class="back-to-login-button" [routerLink]="['/']" mat-stroked-button >{{"back-to-home" | translate}}</button></div>
        </div>
    </div>
</div>

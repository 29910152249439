import { Component, OnInit, Optional, Inject , OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService, ServiceConfigs } from '@core';
import { SmartColumn } from 'app/shared/components/smart-table/smart-table.interface';
import { ExcelService } from 'app/shared/services/excel.service';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { MarketPlaceResponse, MarketPlaceResponsesList } from '@core/interfaces/market-place';


 
 
@Component({
	selector: 'app-sold-report-response',
	templateUrl: './view-report.component.html',
	styleUrls: ['./view-report.component.scss'],
	providers: [HttpService, ServiceConfigs, DatePipe]
})
export class ViewSoldReportComponent implements OnInit, OnDestroy {
    private destroySubject: Subject<void> = new Subject();
	public listSupplier: MarketPlaceResponse[];
	public total: number = 0;
	public query: any = {
		search: '',
		sort: '',
		order: '',
		page: 0,
		size: 5,
	};
	public supplierColumns: SmartColumn[] = [
		{
			title: 'Supplier name',
			index: 'userName',
			width: '180px',
		},
		{
			title: 'Email',
			index: 'email',
			width: '180px',
		},
		{
			title: 'Selling Price/Unit (in SGD)',
			index: 'awardPrice',
			width: '210px',
		},
		{
			title: 'Sold Quantity',
			index: 'qtyAwarded',
			width: '180px',
		},
		{
			title: 'Total Selling Value (in SGD)',
			index: 'sellingValue',
			width: '210px',
		},
		{
			title: 'Sold Date',
			index: 'sellingDate',
			width: '180px',
			sort: true,
			type: 'format',
			format: (data: any) => {
				return this.datePipe.transform(data.sellingDate, "dd MMM yyyy HH:mm:ss");
			}
		}
	];

	constructor(
		private serviceConfigs: ServiceConfigs,
		private httpService: HttpService,
		private datePipe: DatePipe,
		private dialogRef: MatDialogRef<ViewSoldReportComponent>,
		private xls: ExcelService,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
		) { }

    // life cycle hooks

	ngOnInit() {
		this.getSupplierListData();
	}

	ngOnDestroy() {
		this.destroySubject.next();
		this.destroySubject.complete();
	}

	// functions

	public getSupplierListData() {
		this.listSupplier = [];
		const id = this.data.marketPlaceId;
		if (id) {
			const requestObject = '?marketPlaceId=' + id;
			this.httpService.invokeService(this.serviceConfigs.MARKET_PLACE_CONFIG.GET_MARKET_RESPONSE_LIST, requestObject).then((response: MarketPlaceResponsesList) => {
				if (response) {
					this.listSupplier = response?.payload;
					this.total = response?.payload?.length;
				}
			});
		}
	}


	public dialogclose() {
		this.dialogRef.close();
	}
	
	public ExportToExcel() {
		// succeeded
		  if (this.listSupplier.length > 0) {
			const ppData = this.listSupplier;
			let tempData = [ ];
		   
			ppData.forEach( (data: any , index ) => {
			  tempData.push({
			   'Supplier name': data['userName'],
			   'Email': data['email'],
			   'Selling Price/ Unit ( in SGD)' : data['awardPrice'],
			   'Sold Quantity' : data['qtyAwarded'],
			   'Total Selling Value (in SGD)': data['sellingValue'],
			   'Sold Date': this.datePipe.transform( data['sellingDate'],"dd MMM yyyy HH:mm:ss") 
		   });
		   });
		   const excelData = tempData;
			if (excelData && excelData?.length > 0) {
			  this.xls.exportToExcel(excelData, 'STELands Sold Products' , 20);
			}
		  }
	  
	}
}


<div>
    <h2 mat-dialog-title>Edit Service Information <span>
    <mat-icon (click)="dialogclose()" class="float-right cursor-pointer icon-18">close</mat-icon>
    </span> 
</h2>
</div>

<div class="add-service" fxLayout="column">
        <form novalidate [formGroup]="serviceForm" class="app-form-horizontal" >       
                <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
                    <div fxFlex="50" fxFlex.lt-sm="100">
                        <label class="register-form-label">{{"service-offered"|translate}}*</label>
                        <mat-form-field class="form-field-style" appearance="outline">
                            <input matInput maxlength="50" formControlName="serviceName" >
                            <mat-error *ngIf="hasError('serviceName', 'required')">{{ 'service-offered-required' | translate}}</mat-error>
                            <mat-error *ngIf="hasError('serviceName', 'pattern')">{{ 'service-offered-invalid' |translate }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxFlex.lt-sm="100">
                        <label class="register-form-label">{{"Category"|translate}}*</label>
                        <mat-form-field appearance="outline">
                            <mat-select (selectionChange)="getsubCategoryList($event)" disableOptionCentering panelClass="common-drop-down-style" placeholder="select" formControlName="category">
                                <mat-option *ngFor="let data of categoryList" [value]="data.id">{{data.categoryName}}</mat-option> 
                            </mat-select> 
                            <mat-error *ngIf="hasError('category', 'required')">{{ 'category-required' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
                    <div fxFlex="50" fxFlex.lt-sm="100">
                        <label class="register-form-label">{{'subcategory'|translate}}*</label>
                        <mat-form-field  appearance="outline">
                            <mat-select disableOptionCentering panelClass="common-drop-down-style" placeholder="select" formControlName="subcategory">
                                <mat-option *ngFor="let data of subcategoryList" [value]="data.id">{{data.subCategoryName}}</mat-option> 
                            </mat-select>  
                            <mat-error *ngIf="hasError('subcategory', 'required')">{{ 'subcategory-required' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxFlex.lt-sm="100">
                        <label class="register-form-label">{{"Hyperlink / File url"|translate}}</label>
                        <mat-form-field class="form-field-style" appearance="outline">
                            <input matInput maxlength="50" formControlName="hyperLink" >
                           <mat-error *ngIf="hasError('hyperLink', 'pattern')">{{ 'hyper-link-invalid' |translate }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
    
                <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column"> 
                    <div fxFlex="100" >
                        <label class="register-form-label">{{"description"|translate}}*</label>
                        <mat-form-field class="form-field-text-area-style" appearance="outline">
                            <textarea maxlength="1000" matInput style="resize: none;height: -webkit-fill-available;" formControlName="description">
                            </textarea>
                            <mat-error *ngIf="hasError('description', 'required')">{{ 'description-required' | translate}}</mat-error>
                        </mat-form-field> 
                    </div>
                </div>
    
                <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column"> 
                    <div  fxFlex="50">
                        <div fxLayout="row">
                            <div fxFlex="90" fxLayoutAlign="start center"> 
                                 <label class="register-form-label">{{"Upload Attachments"|translate}}*</label>
                            </div>
                            <div fxFlex="10" fxLayoutAlign="end center">     
                                <span class="iconmoon-info hyperlink-info-style"
                                    [popperTrigger]="'hover'"
                                    [popperPlacement]="'top'"
                                    [popperApplyClass]="'popper-container'"
                                    [popper]="hyperLinkInfo">
                               </span>
                            </div>   
                            <popper-content #hyperLinkInfo  >
                                <p>You can upload only one file. If you upload the second one, existing one will get replaced.</p>
                            </popper-content>    
                        </div>
                        
                        <mat-form-field class="upload-file-field" appearance="outline">
                            <div fxFlex="100" fxLayout="row">
                                <div fxFlex="50" fxLayoutAlign="start center">
                                   <input matInput formControlName="uploadAttachements" readonly >
                                </div>
                                <div fxFlex="50" fxLayoutAlign="end end">
                                 <button mat-flat-button type="button" class="choose-file-button-style" (click)="fileUpload.click()">{{'choose-file' | translate}}</button>
                                </div>
                             </div>
                            <input type="file" style="display:none;" accept=".pdf, .xls, .xlsx" #fileUpload (click)="fileUpload.value = null" (change)="AttachmentScannUplaod($event)" value="" />  
                            <mat-error *ngIf="hasError('uploadAttachements', 'required')">{{ 'company-profile-file-req' | translate}}</mat-error>
                            <mat-hint>Note:Each file uploaded should be less than or equal to 10MB</mat-hint>
                        </mat-form-field>
                    </div>
                    <div  fxLayoutGap="10px" fxLayout="column" fxFlex="50" *ngIf="!isExistAttachDelete" >
                        <label class="register-form-label">{{"attachments" | translate}}</label>
                        <div fxLayout="row">
                            <span *ngIf="!isExistAttachDelete" ><mat-icon (click)="confirmDelete()" class="attach-icon-style">delete</mat-icon>&nbsp;</span>
                            <span style="text-decoration: underline;cursor: pointer;" (click)="downloadFile(attachment1)">{{attachment1 | customDateRemover}}</span>
                        </div>
                    </div>
                </div>
      </form>
    </div>
      <div  fxLayout="row" fxLayoutAlign="center center">
        <button class="cancel-button m-r-8" type="button" mat-stroked-button (click)="dialogclose()">{{"cancel-btn" | translate}}</button>
        <button class="action-button" type="submit"
        appPreventDoubleClick (throttledClick)="UpdateService()" mat-stroked-button >{{"submit-btn" | translate}}</button>
     </div>

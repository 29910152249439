import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { HttpService, ServiceConfigs, SessionsService } from '@core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Helper } from '@core/helper';



@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class SendMessageComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public messageForm: UntypedFormGroup;
  public date = new Date();
  public fileNameList: any = [];
  public fileSetList: any = [];
  public formDataList: any = [];
  constructor(
    private dialogRefMessage: MatDialogRef<SendMessageComponent>,
    private toastr: ToastrServiceClass,
    private httpService: HttpService,
    private sessionService: SessionsService,
    private serviceConfigs: ServiceConfigs,
    private router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  // life cycle hooks

  ngOnInit() {
    this.buildProductForm();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions 

  public buildProductForm() {
    this.messageForm = new UntypedFormGroup({
      supplierMailId: new UntypedFormControl(this.data.supplierMailId, []),
      sendMailStatus: new UntypedFormControl(false, []),
      subject: new UntypedFormControl(this.data.tenderName, [Validators.required]),
      description: new UntypedFormControl('', [Validators.required]),
      uploadAttachements: new UntypedFormControl('', [])
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.messageForm.controls[controlName].hasError(errorName);
  }

  public sendMail() {
    if (this.messageForm.valid) {
      this.toastr.loaderShow();
      // const formData = new FormData();
      // this.formDataList.forEach(element => {
      //     formData.append('file', element.file, element.name );
      // });
      // Call to upload the files
      if (this.formDataList.length > 0) {
        //  this.UploadFile(formData);
      }
      if (this.data.screen === 'rfq') {

        const request = {
          senderUid: this.sessionService.getUserId(),
          receiverUid: this.data.userId,
          tenderId: this.data.tenderId,
          subject: this.messageForm.value.subject,
          content: this.messageForm.value.description,
          isDefault: this.messageForm.value.sendMailStatus,
          listOfAttachments: Helper.removeStringEmptySpace(this.fileSetList, true),
          msgId: this.data?.messageId ? this.data?.messageId : ''
        };
        this.httpService.invokePost(this.serviceConfigs.BUYER_APP_SERVICES_CONFIG.SEND_MAIL_POPUP, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
          try {
            if (data) {
              if (data.body.isError === false) {
                this.toastr.success(data?.body?.message);
                this.fileSetList = [];
                this.dialogRefMessage.close();
              } else {
                this.toastr.error(data?.body?.message);
              }
            }
            this.toastr.loaderHide();
          } catch (ex) {

            this.toastr.loaderHide();
          }
        }, (err) => {

          this.toastr.loaderHide();
        }, () => {

          this.toastr.loaderHide();
        });
      }
      else if (this.data.screen === 'buyer') {
        const bodyContent = {
          senderUid: this.sessionService.getUserId(),
          receiverUid: this.data.userId,
          tenderId: this.data.tenderId,
          content: this.messageForm.value.description,
          isDefault: this.messageForm.value.sendMailStatus,
          listOfAttachments: Helper.removeStringEmptySpace(this.fileSetList, true),
          msgId: this.data?.messageId ? this.data?.messageId : ''
        };
        this.toastr.loaderShow();
        this.httpService.invokePost(this.serviceConfigs.BUYER_APP_SERVICES_CONFIG.SEND_BUYER_REPLY, bodyContent).subscribe((data: any) => {
          try {
            if (data) {
              if (data.body.isError === false) {
                this.toastr.success(data?.body?.message);
                this.fileSetList = [];
                if (this.sessionService.getUserType() === '0' || this.sessionService.getUserType() === '4') {
                  this.router.navigate(['/admin/notifications']);
                } else {
                  this.router.navigate(['/buyer/message-notifications/message']);
                }

                this.dialogRefMessage.close();
              } else {
                this.toastr.error(data?.body?.message);
              }
            }
            this.toastr.loaderHide();
          } catch (ex) {

            this.toastr.loaderHide();
          }
        }, (err) => {

          this.toastr.loaderHide();
        }, () => {

          this.toastr.loaderHide();
        });
      }
      else if (this.data.screen === 'supplier') {
        const bodyContent = {
          senderUid: this.sessionService.getUserId(),
          receiverUid: this.data.userId,
          tenderId: this.data.tenderId,
          content: this.messageForm.value.description,
          isDefault: this.messageForm.value.sendMailStatus,
          listOfAttachments: Helper.removeStringEmptySpace(this.fileSetList, true),
          msgId: this.data?.messageId ? this.data?.messageId : ''
        };
        const request = '?companyId=' + this.sessionService.getCompanyUniqueId();
        this.toastr.loaderShow();
        this.httpService.invokePostWithParamsAndBody(this.serviceConfigs.SUPPLIER_APP_MESSAGE_CONFIG.SEND_REPLY_SUPPLIER, request, bodyContent).subscribe((data: any) => {
          try {
            if (data) {
              if (data.body.isError === false) {
                this.toastr.success(data?.body?.message);
                this.router.navigate(['/message-notifications/message']);
                this.fileSetList = [];
                this.dialogRefMessage.close();
              } else {
                this.toastr.error(data?.body?.message);
              }
            }
            this.toastr.loaderHide();
          } catch (ex) {

            this.toastr.loaderHide();
          }
        }, (err) => {

          this.toastr.loaderHide();
        }, () => {

          this.toastr.loaderHide();
        });
      }
      else if (this.data.screen === 'market') {
        const request = {
          senderUid: this.sessionService.getUserId(),
          subject: this.messageForm.value.subject,
          receiverUid: this.data.userId,
          tenderId: this.data.marketplaceId,
          content: this.messageForm.value.description,
          isDefault: this.messageForm.value.sendMailStatus,
          listOfAttachments: Helper.removeStringEmptySpace(this.fileSetList, true),
          companyId: this.data.companyId,
          msgId: this.data?.messageId ? this.data?.messageId : ''
        };
        this.toastr.loaderShow();
        this.httpService.invokePost(this.serviceConfigs.BUYER_APP_SERVICES_CONFIG.SEND_MAIL_POPUP, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
          try {
            if (data) {
              if (data.body.isError === false) {
                this.toastr.success(data?.body?.message);
                // no redirected here..
                this.fileSetList = [];
                this.dialogRefMessage.close();
              } else {
                this.toastr.error(data?.body?.message);
              }
            }
            this.toastr.loaderHide();
          } catch (ex) {

            this.toastr.loaderHide();
          }
        }, (err) => {

          this.toastr.loaderHide();
        }, () => {

          this.toastr.loaderHide();
        });
      } else {
        this.validateAllFormFields(this.messageForm);
      }
    }
  }

  public validateAllFormFields(formGroup: UntypedFormGroup) {
    this.toastr.info('Please fill all the Required fields.');
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public dialogclose() {
    this.dialogRefMessage.close();
  }

  public AttachmentUplaod(event: any) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    const allowedExtensions = /(\.pdf|\.xls|\.xlsx)$/i;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        if (!allowedExtensions.exec(files[i].name)) {
          this.toastr.error('Only .pdf , .xls and .xlsx supported !');
          input.value = '';
          return;
        }
      }
    }
    let i = 0;
    const file = event.target.files[0];
    if (!Helper.validateFilename(file.name)) {
      this.toastr.error("File name should not contain these special characters @$%&\/:?\"'<>|~`#^={}[];!");
      return;
    } else {
      if (file && file != null && file.size > 10615705) {
        this.toastr.info('The attachment file should not be more than 10 MB size');
        this.toastr.loaderHide();
        return false;
      }
      this.fileNameList = [...new Set(this.fileSetList)];
      if (event.target.files.length > 5 || this.fileSetList.length > 5) {
        this.toastr.info('You can upload only Five files');
      } else if ((event.target.files.length + this.fileSetList.length) > 5) {
        this.toastr.info('You can upload only Five files');
      } else {
        const files = event.target.files;
        setTimeout(() => {
          this.toastr.loaderShow('Scanning in progress...');
        }, 250);
        for (i = 0; i < files.length; i++) {
          const file = files[i];
          const formData = new FormData();
          formData.append('file', file, file.name);
          const requestFile = formData;
          this.httpService.invokeScanFileUpload(this.serviceConfigs.FILE_SCAN_SERVICES_CONFIG.UPLOAD, requestFile).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
            try {
              if (data && data?.type !== 0) {
                if (data.body.isError === false) {
                  this.fileNameList.push(file.name);
                  if (this.formDataList.length > 0) {
                    const tempList = this.formDataList.filter(data => data.name === file.name);
                    if (tempList !== undefined && tempList.length === 0) {
                      this.formDataList.push({ name: file.name, file: file });
                    }
                  } else {
                    this.formDataList.push({ name: file.name, file: file });
                  }
                  this.fileSetList = [...new Set(this.fileNameList)];
                  // 
                  this.messageForm.controls.uploadAttachements.setValue(file.name);
                  this.saveScanAduit(data?.body?.message, file.name, 'File Scan');
                } else {
                  this.toastr.error(data?.body?.message);
                  this.saveScanAduit(data?.body?.message, file.name, 'File Scan');
                }
              }
              setTimeout(() => {
                this.toastr.loaderHide();
              }, 500);
            } catch (ex) {

              setTimeout(() => {
                this.toastr.loaderHide();
              }, 500);
            }
          }, (err) => {

            setTimeout(() => {
              this.toastr.loaderHide();
            }, 500);
          }, () => {

            setTimeout(() => {
              this.toastr.loaderHide();
            }, 500);
          });
          setTimeout(() => {
            this.toastr.loaderHide();
          }, 500);
        }
      }
    }
  }
  
  public deleteFile(name: any) {
    this.fileSetList = this.fileSetList.filter((data) => (name !== data));
    this.formDataList = this.formDataList.filter((data) => (data.name !== name));
    let msg = "The following file deleted successfully!";
    this.saveScanAduit(msg, name, 'File Delete');
    if (this.fileSetList.length === 0) {
      this.messageForm.controls.uploadAttachements.setValue('');
    }
  }

  public UploadFile(formData: any) {
    const requestFile = formData;
    const id = this.sessionService.getUserId();
    this.httpService.invokeMessageFileUpload(this.serviceConfigs.BUYER_APP_SERVICES_CONFIG.UPLOAD_MESSAGE_FILE, requestFile, id).subscribe((data: any) => {
      try {
        if (data && data?.type !== 0) {
          if (data.body.isError === false) {

          } else {
            this.toastr.error(data?.body?.message);
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    });
  }

  public saveScanAduit(msg: any, fileName: any, type: any) {
    const request = {
      tableKey: this.sessionService.getUserId(),
      email: this.sessionService.getUserMailId(),
      description: msg,
      fileName: fileName,
      action: type
    }
    this.httpService.invokePost(this.serviceConfigs.FILE_SCAN_SERVICES_CONFIG.SCAN_AUDIT, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      try {
        if (data) {
          if (data.body.isError === false) {
            this.toastr.success(data?.body?.message);
          } else {
            this.toastr.error(data?.body?.message);
          }
        }
        this.toastr.loaderHide();
      } catch (ex) {

        this.toastr.loaderHide();
      }
    }, (err) => {

      this.toastr.loaderHide();
    }, () => {

      this.toastr.loaderHide();
    });

  }
}

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoadingScreenService } from '@shared/components/loading-screen/loading-screen.service';

@Injectable({
  providedIn: 'root'
})
export class ToastrServiceClass {
   public closeButton = true;
  constructor(private toastr: ToastrService, private loader: LoadingScreenService) {
  }

  info(message: string) {
    this.toastr.info(message, 'Information:', {
      tapToDismiss: false,
      closeButton: true
    });
  }
  success(message: string) {
    this.toastr.success(message, 'Success:', {
      tapToDismiss: false,
      closeButton: true
    });
  }
  warning(message: string) {
    this.toastr.warning(message, 'Warning:', {
      tapToDismiss: true,
      disableTimeOut: true,
      closeButton: true
    });
  }
  error(message: string) {
    this.toastr.error(message, 'Error:' , {
      tapToDismiss: true,
      // disableTimeOut: true,
      closeButton: true
    });
  }
  errorWithTimeOut(message: string, time: any) {
    this.toastr.error(message, undefined, {
      timeOut: time
    });
  }
  infoWithTimeOut(message: string, title: string, time: any) {
    this.toastr.info(message, title, {
      timeOut: time
    });
  }
  loaderShow(message?: string) {
    const text = (message != '') ? message : '';
    this.loader.startLoading(text);
  }
  loaderHide() {
     this.loader.stopLoading();
  }
}

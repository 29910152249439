import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceConfigs, HttpService, SessionsService } from '@core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrServiceClass } from '@core/services/toastr.service';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { Regex } from '@core/regex';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-product-respond',
  templateUrl: './product-respond.component.html',
  styleUrls: ['./product-respond.component.scss'],
  providers: [HttpService, ServiceConfigs]
})
export class ProductRespondComponent implements OnInit, OnDestroy {
  private destroySubject: Subject<void> = new Subject();
  public productData: any;
  public productForm: UntypedFormGroup;
  public qtyFlag = false;
  constructor(
    private serviceConfigs: ServiceConfigs,
    private httpService: HttpService,
    private router: Router,
    private sessionService: SessionsService,
    private dialogRef: MatDialogRef<ProductRespondComponent>,
    private toastr: ToastrServiceClass,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.productData = data;
  }

  // life cycle hooks

  ngOnInit() {
    this.buildProductForm();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  // functions

  public buildProductForm() {
    if (this.productData.negotiable) {
      this.productForm = new UntypedFormGroup({
        makeOffer: new UntypedFormControl('', [Validators.required, this.makeOfferValidate()]),
        interestedQty: new UntypedFormControl('', [Validators.required, Validators.pattern(Regex.interestedQtyPattern)]),
      });
    } else {
      this.productForm = new UntypedFormGroup({
        makeOffer: new UntypedFormControl(0, [Validators.pattern(Regex.currencyRatePattern)]),
        interestedQty: new UntypedFormControl('', [Validators.required]),
      });
    }

  }
  
  public makeOfferValidate(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      let number = /^([1-9][0-9]{0,9})(\.([0-9]{1,4})?)?$/.test(c.value) ? +c.value : NaN;
      if (number !== number) {
        return { 'makeOfferValidate': true };
      }

      return null;
    };
  }
   
  public hasError = (controlName: string, errorName: string) => {
    return this.productForm.controls[controlName].hasError(errorName);
  }

  public checkInterestedQty() {
    const qty = this.productForm.value.interestedQty;
    if (qty >= 1 && qty <= this.productData.qtyavailable) {
      this.qtyFlag = true;
    } else {
      this.qtyFlag = false;
    }
  }

  public dialogclose() {
    this.dialogRef.close();
  }

  public Respond() {
    if (this.productForm.valid === true && this.qtyFlag) {
      const request = this.productForm.value;
      request.marketPlaceId = this.productData.marketPlaceId;
      request.userId = this.sessionService.getUserId();
      request.companyId = this.sessionService.getCompanyUniqueId();

      this.toastr.loaderShow();
      this.httpService.invokePost(this.serviceConfigs.MARKET_PLACE_CONFIG.MARKET_PLACE_SUPPLIER_RESPONSE, request).pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
        try {
          if (data) {
            if (data.body.isError === false) {
              this.toastr.success(data?.body?.message);
              this.sessionService.changeSupplierProductCounts(true);
              this.dialogRef.close();
              this.router.navigate(['/market-place/product-on-sale']);
            } else {
              this.toastr.error(data?.body?.message);
            }
          }
          this.toastr.loaderHide();
        } catch (ex) {

          this.toastr.loaderHide();
        }
      }, (err) => {

        this.toastr.loaderHide();
      }, () => {

        this.toastr.loaderHide();
      });
    } else if (this.productForm.valid === true && this.qtyFlag === false) {
      this.toastr.info('Interested Quantity should be less than or equal to Available Quantity');

    } else if (this.productForm.valid === false) {
      this.validateAllFormFields(this.productForm);
    }
  }

  public validateAllFormFields(formGroup: UntypedFormGroup) {
    this.toastr.info('Please fill all the Required fields.');
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}

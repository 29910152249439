<div fxLayout="column" id="otp-expired-page">
    <!-- <img src="assets/images/session/left-flower.png" class="left-flower" alt="left-flower">
    <img src="assets/images/session/full-flower.png" class="full-flower" alt="Full-flower">
    <img src="assets/images/session/bottom-flower.png" class="bottom-flower" alt="bottom-flower"> -->
    <div>
        <div class="header-box" fxLayout="row" fxFlex="100">
            <div fxFlex fxLayoutAlign="start center"><img src="../../../../assets/images/session/STE Land Systems logos.png" [ngStyle.gt-lg]="{'width.px': 240}"  [ngStyle.lt-lg]="{'width.px': 180}" class="ste-logo" alt="STE"></div>
        </div>
    </div>
    <div>
        <div fxLayout="column" fxLayoutAlign="space-around center" class="inner-box" >
            <form  [formGroup]="otpForm">

            <div class="login-style">{{"login" | translate}}</div>
            <div class="enter-otp-style">{{"otp-enter-request" | translate}}</div>
            <div class="otp-field">
              <div>
                 <mat-form-field class="form-field-style" appearance="outline">
                    <span class="text-icon"><img src="assets/images/icons/opt-icon.svg"  alt="password"></span>
                    <input matInput formControlName="otpInput" class="label-font-style" placeholder='{{"otp-label" | translate}}' maxLength="6" (keypress)="onlyNumber($event)">
                    <mat-error *ngIf="hasError('otpInput', 'required')" >{{"otp-req" | translate}}</mat-error>
                    <mat-error *ngIf="hasError('otpInput', 'minlength')" > OTP should be 6 digits </mat-error>
                </mat-form-field>
                </div>
            </div>
            <div class="red-font link" fxLayoutAlign="center" (click)="resendOTP()">{{"resend-otp" | translate}}</div>
            <div fxLayout="row"  fxLayoutAlign="center" fxLayoutGap="10px" class="button-box">
                <div><button class="cancel-button" type="button" [routerLink]="['/auth/login']" mat-stroked-button >{{"cancel-btn" | translate}}</button></div>
                <div><button class="submit-button" type="submit" (click)="onSubmit()" mat-stroked-button >{{"submit-btn" | translate}}</button></div>
            </div>
            </form>
          </div>
    </div>
</div>

<div fxLayout="column" id="otp-expired-page">
    <!-- <img src="assets/images/session/left-flower.png" class="left-flower" alt="left-flower">
    <img src="assets/images/session/full-flower.png" class="full-flower" alt="Full-flower">
    <img src="assets/images/session/bottom-flower.png" class="bottom-flower" alt="bottom-flower"> -->
    <div>
        <div class="header-box" fxLayout="row" fxFlex="100">
            <div fxFlex fxLayoutAlign="start center"><img src="../../../../assets/images/session/STE Land Systems logos.png" class="ste-logo" [ngStyle.gt-lg]="{'width.px': 240}"  [ngStyle.lt-lg]="{'width.px': 180}" alt="STE"></div>
        </div>
    </div>
    <div>
        <div fxLayout="column" fxLayoutAlign="center" class="inner-box">
            <div class="heading-style">{{"otp-expired" | translate}}</div>
            <div class="sub-heading-style">{{"goto-login-page" | translate}}</div>
            <div fxLayoutAlign="space-around center"><button [routerLink]="['/auth/login']" class="back-to-login-button" mat-stroked-button >{{"back-to-login" | translate}}</button></div>
        </div>
    </div>
</div>

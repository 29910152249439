import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';

import { DashboardComponent } from './supplier/dashboard/dashboard.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { EnterOtpComponent } from './sessions/enter-otp/enter-otp.component';
import { OtpExpiredComponent } from './sessions/otp-expired/otp-expired.component';
import { HomeScreenComponent } from './home/home-screen.component';
import { MessageScreenComponent } from './sessions/message-screen/message-screen.component';
import { ForgotPasswordComponent } from './sessions/forgot-password/forgot-password.component';
import { AdminLoginComponent } from './sessions/admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin/dashboard/dashboard.component';

const COMPONENTS = [DashboardComponent, AdminDashboardComponent, LoginComponent, ForgotPasswordComponent, RegisterComponent, EnterOtpComponent, HomeScreenComponent, MessageScreenComponent, OtpExpiredComponent, AdminLoginComponent];
const COMPONENTS_DYNAMIC = [];

@NgModule({
    imports: [SharedModule, PagesRoutingModule],
    declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC]
})
export class PagesModule {}


<div>
    <h2 mat-dialog-title>Edit Product Information <span><mat-icon (click)="dialogclose()" class="float-right cursor-pointer icon-18">close</mat-icon> </span> </h2>
</div>
<div class="add-product" fxLayout="column">
        <form novalidate [formGroup]="productForm" class="app-form-horizontal" >
                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxFlex="100" fxLayoutGap="20px" class="w-full">
                 <div fxLayout="row" fxFlex="100" fxLayoutGap="20px" fxLayout.lt-md="column">
                        <div fxFlex="50" fxFlex.lt-sm="100">
                            <label class="register-form-label">{{"Product Name"|translate}}*</label>
                            <mat-form-field class="form-field-style" appearance="outline">
                                <input matInput maxlength="50" formControlName="productName" >
                                <mat-error *ngIf="hasError('productName', 'required')">{{ 'productname-required' | translate}}</mat-error>
                                <mat-error *ngIf="hasError('productName', 'pattern')">{{ 'product-name-invalid' |translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50" fxFlex.lt-sm="100">
                            <label class="register-form-label">{{"Category"|translate}}*</label>
                            <mat-form-field appearance="outline">
                                <mat-select (selectionChange)="getsubCategoryList($event)" disableOptionCentering panelClass="common-drop-down-style" placeholder="select" formControlName="category">
                                    <mat-option>
                                        <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No matching Category found'" [formControl]="categoryFilterCtrl">
                                           <span ngxMatSelectSearchClear></span>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let data of filteredCategoryTypes | async; index as i;" [value]="data.id">{{data.categoryName}}</mat-option> 
                                </mat-select> 
                                <mat-error *ngIf="hasError('category', 'required')">{{ 'category-required' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100" fxLayoutGap="20px" fxLayout.lt-md="column">
                        <div fxFlex="50" fxFlex.lt-sm="100">
                            <label class="register-form-label">{{'subcategory'|translate}}*</label>
                            <mat-form-field  appearance="outline">
                                <mat-select disableOptionCentering panelClass="common-drop-down-style" placeholder="select" formControlName="subcategory">
                                    <mat-option>
                                        <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No matching Sub Category found'" [formControl]="subCategoryFilterCtrl">
                                           <span ngxMatSelectSearchClear></span>
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let data of filteredSubCategoryTypes | async; index as i;" [value]="data.id">{{data.subCategoryName}}</mat-option> 
                                </mat-select>  
                                <mat-error *ngIf="hasError('subcategory', 'required')">{{ 'subcategory-required' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50" fxFlex.lt-sm="100">
                            <label class="register-form-label">{{"Part Number"|translate}}</label>
                            <mat-form-field class="form-field-style" appearance="outline">
                                <input matInput maxlength="50" formControlName="partNumber" >
                               <mat-error *ngIf="hasError('partNumber', 'pattern')">{{ 'part-number-invalid' |translate }}</mat-error>
                            </mat-form-field>
                       </div>
                    </div>
                    <div fxLayout="row" fxFlex="100" fxLayoutGap="20px" fxLayout.lt-md="column">
                        <div fxFlex="100" >
                            <label class="register-form-label">{{"description"|translate}}*</label>
                            <mat-form-field class="form-field-text-area-style" appearance="outline">
                                <textarea maxlength="1000" matInput style="resize: none;height: -webkit-fill-available;" formControlName="description">
                                </textarea>
                                <mat-error *ngIf="hasError('description', 'required')">{{ 'description-required' | translate}}</mat-error>
                            </mat-form-field> 
                        </div>
                    </div>
                    
                    <div fxLayout="row" fxFlex="100" fxLayoutGap="20px" fxLayout.lt-md="column">
                        <div fxFlex="50" fxFlex.lt-sm="100">
                            <label class="register-form-label">{{"Hyperlink / File url"|translate}}
                            </label>
        
                            <mat-form-field class="form-field-style" appearance="outline">
                                <input matInput maxlength="50" formControlName="hyperLink" >
                               <mat-error *ngIf="hasError('hyperLink', 'pattern')">{{ 'hyper-link-invalid' |translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50" fxFlex.lt-sm="100">
                            <div  fxLayout="row">
                                <div fxFlex="90" fxLayoutAlign="start center"> 
                                    <label class="register-form-label">{{"Upload Attachments"|translate}}*</label>
                                </div> 
                                <div fxFlex="10" fxLayoutAlign="end center">   
                                    <span class="iconmoon-info hyperlink-info-style"
                                        [popperTrigger]="'hover'"
                                        [popperPlacement]="'top'"
                                        [popperApplyClass]="'popper-container'"
                                        [popper]="hyperLinkInfo">
                                   </span>
                               </div>
                                <popper-content #hyperLinkInfo  >
                                    <p>You can upload a maximum of three files.</p>
                                </popper-content>
                            </div>
                           
                            <mat-form-field class="upload-file-field" appearance="outline">
                                <div fxFlex="100" fxLayout="row">
                                    <div fxFlex="50" fxLayoutAlign="start center">
                                       <input matInput formControlName="uploadAttachements" readonly>
                                    </div>
                                    <div fxFlex="50" fxLayoutAlign="end end">
                                     <button mat-flat-button type="button" class="choose-file-button-style" (click)="fileUpload.click()">{{'choose-file' | translate}}</button>
                                    </div>
                                 </div>
                                <input type="file" multiple accept=".pdf, .xls, .xlsx" style="display:none;" #fileUpload (click)="fileUpload.value = null" (change)="AttachmentUplaod($event)" value="" />
                                <input type="file"  style="display:none;" #fileUpload (click)="fileUpload.value = null" (change)="AttachmentUplaod($event)" value="" />
                                <mat-hint>Note:Each file uploaded should be less than or equal to 10MB</mat-hint>
                                <mat-error *ngIf="hasError('uploadAttachements', 'required')">{{ 'company-profile-file-req' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                       
                    </div>
                           
                    <div fxLayout="row" fxLayout.lt-sm="column" fxFlex="100" fxLayoutGap="20px" fxLayoutGap.lt-sm="20px">
                        <div fxFlex="50"></div>
                        <div fxFlex="50" *ngFor="let data of attachments">
                            <div style="display: flex;align-items: center;">
                                <span><mat-icon (click)="confirmDelete(data)" class="attach-icon-style">delete</mat-icon>&nbsp;</span>
                                <span class="dowload-link-style" (click)="downloadFile(data)">{{data | customDateRemover}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
          <div fxLayout="row" fxLayoutAlign="center center" >
           <button class="cancel-button m-r-8" type="button" mat-stroked-button (click)="dialogclose()" >{{"cancel-btn" | translate}}</button>
           <button class="action-button" type="submit"
           appPreventDoubleClick (throttledClick)="UpdateProduct()" mat-stroked-button >{{"submit-btn" | translate}}</button>
         </div>
        

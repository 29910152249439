import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { SessionsService } from './sessions.service';

@Injectable({ providedIn: 'root' })
export class GaurdService implements CanActivate {
    constructor(
        private router: Router,
        private sessionsService: SessionsService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUserType = this.sessionsService.getUserType();

        let userRole: string;
        switch (currentUserType) {
            case '0':
                userRole = 'admin';
                break;
            case '1':
                userRole = 'supplier';
                break;
            case '2':
                userRole = 'supplier';
                break;
            case '3':
                userRole = 'buyer';
                break;
            case '4':
                userRole = 'admin';
                break;
            case '5':
                userRole = 'sqa';
                break;
            case '6' :
                 userRole = 'engineer';
                 break;
            case '7' :
                userRole = 'admin-role';
                break;
            }


        if (currentUserType) {
            // check if route is restricted by role
            if (route.data.roles && route.data.roles.indexOf(userRole) === -1) {
                // role not authorised so redirect to home page
                this.router.navigate(['/']);
                return false;
            }
            // authorised so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        return false;
    }
}
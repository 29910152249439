import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[onlyNumberOnPress]',
})
export class OnlyNumberOnPress {

    constructor(private el: ElementRef) { }

    @Input() OnlyNumber: boolean;

    @HostListener('keypress', ['$event']) onKeyPress(event) {
        let e = <KeyboardEvent>event;
        const charCode = (e.which) ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
        return;
    }
}

@Directive({
    selector: '[onlyNumberOnDown]',
})
export class OnlyNumberOnDown {

    constructor(private el: ElementRef) { }

    @Input() OnlyNumber: boolean;

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        let e = <KeyboardEvent>event;
        const charCode = (e.which) ? e.which : e.keyCode;
        if (charCode === 190) {
            return;
        } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
        return;
    }
}
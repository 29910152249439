import { Injectable, Pipe, PipeTransform } from "@angular/core";
@Pipe({
    name: 'lazyLoadData',
    pure: false,
  })
@Injectable()
export class LazyLoadDataPipe implements PipeTransform {
    intervalFlag: number = 0;
    loadRows: number = 50;
    items = [];
    transform(items: any,
        intialLoadRows: number = 25,
        intervalLoadRows: number = 20,
        interval: number = 50): any {
            this.items = items;
            if (this.intervalFlag == 0) {
              this.intervalFlag = 1;
              const that = this;
              this.loadRows = intialLoadRows;
              const inter = setInterval(function () {
                if (that.items && that.items?.length) {
                  if (that.items?.length > that.loadRows) {
                    that.loadRows = that.loadRows + intervalLoadRows;
                  } else {
                    if (inter) {
                      clearInterval(inter);
                    }
                  }
                }
              }, interval);
            }
            if (items && items?.length) {
              // console.log('counter', this.loadRows);
              return items.slice(0, this.loadRows);
            }
          } 
    }
    
    @Pipe({
        name: 'filter',
      })
    export class FilterPipe implements PipeTransform {
        transform(items: any[], searchText: string): any[] {
          if (!items) return [];
          if (!searchText) return items;
      
          return items.filter((item) => {
            return Object.keys(item).some((key) => {
              return String(item[key])
                .toLowerCase()
                .includes(searchText.toLowerCase());
            });
          });
        }
      }

<div>
    <h2 mat-dialog-title>View Product Information <span><mat-icon (click)="dialogclose()" class="float-right cursor-pointer icon-18">close</mat-icon> </span> </h2>
</div>
<mat-dialog-content class="mat-typography">
    <div fxLayout="row wrap"  fxLayoutAlign="space-around center" fxLayoutGap="16px grid"  >
        <div fxFlex="50" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Product Name </div> 
            <div class="text-grey-900" > {{product.productName}} </div>
        </div>

        <div fxFlex="50" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Category</div> 
            <div class="text-grey-900" > {{product.categoryName}} </div>
        </div>
        <div fxFlex="50" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Sub-Category </div> 
            <div class="text-grey-900" > {{product.subCategoryName}}</div>
        </div>
        <div fxFlex="50" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Part Number </div> 
            <div class="text-grey-900" > {{product.partNo}}</div>
        </div>
        <div *ngIf="product.url !== null && product.url !== ''" fxFlex="100" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Hyperlink / File url</div> 
            <div class="text-grey-900 " > <a style="color:red; text-decoration: underline;" href="https://{{product.url}}" target="_blank">{{ product.url != undefined && product.url != 'null' && product.url != null && product.url != '' ? product.url : '-' }}</a></div>
        </div>
        <div fxFlex="100" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Description</div> 
            <div class="text-grey-900 " >  {{product.productDescription}}</div>
        </div>
        <div fxFlex="100" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Attachments</div> 
                <div fxLayout="row wrap" fxLayoutGap="20px" fxLayoutGap.lt-sm="20px" class="text-grey-900" >
                    <div *ngFor="let data of product.listOfAttachments"> 
                        <span matPrefix><mat-icon class="attach-icon-style"> attach_file</mat-icon>&nbsp;</span> 
                        <span style="text-decoration: underline;cursor: pointer;" (click)="downloadFile(data)">{{data | customDateRemover }}</span>
                    </div>
                </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="m-r-8 cancel-button" (click)="dialogclose()" mat-stroked-button >
        Back
    </button>
</mat-dialog-actions>


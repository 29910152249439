import { Component, OnInit, Inject, Optional, ViewChild , OnDestroy } from '@angular/core';
import { ServiceConfigs, HttpService } from '@core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { UntypedFormControl } from '@angular/forms';
import { Subject, ReplaySubject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { SupplierCategory2, SupplierCategory2Response } from '@core/interfaces/user';

 
 
@Component({
	selector: 'app-approvepopup',
	templateUrl: './approve-popup.component.html',
	styleUrls: ['./approve-popup.component.scss'],
	providers: [HttpService, ServiceConfigs]
})
export class ApprovePopupComponent implements OnInit, OnDestroy {
	@ViewChild('multiSelect1', { static: true }) multiSelect1: MatSelect;
	@ViewChild('multiSelect2', { static: true }) multiSelect2: MatSelect;
	protected _onDestroy = new Subject<void>()
	public popupData: any;
	public CategoryList1: any[] = [];
	public CategoryList2: SupplierCategory2[] = [];
	public savedCategories: any[] = [];

	public category1Ctrl: UntypedFormControl = new UntypedFormControl();
	public CategoryList1FilterCtrl: UntypedFormControl = new UntypedFormControl();
	public filteredCategoryList1: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

	public category2Ctrl: UntypedFormControl = new UntypedFormControl();
	public CategoryList2FilterCtrl: UntypedFormControl = new UntypedFormControl();
	public filteredCategoryList2: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

	constructor(
		private serviceConfigs: ServiceConfigs,
		private httpService: HttpService,
		private dialogRef: MatDialogRef<ApprovePopupComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) { }

	// life cycle hooks 

	ngOnInit() {
		this.popupData = this.data;
		this.getCategory1List();
		this.getCategory2List();
		if (this.popupData.length > 0) {
			this.category1Ctrl.setValue(this.popupData[0].category1);
			this.category2Ctrl.setValue(this.popupData[0].category2);
		}
	}

	ngAfterViewInit() {
		if (this.popupData.length > 0) {
			this.setInitialValue();
		}
	}

	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}

	// functions

	public searchFilterHandler() {
		this.CategoryList1FilterCtrl?.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterCategoryList1();
			});
		this.CategoryList2FilterCtrl?.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterCategoryList2();
			});
	}

	public getCategory1List() {
		const requestObject: string = '';
		this.httpService.invokeService(this.serviceConfigs.ADMIN_APP_CONFIG.GET_ALL_CATEGORY1_LIST, requestObject).then((response: any) => {
			if (response && response?.payload) {
				this.CategoryList1 = response?.payload;
				this.filteredCategoryList1.next(this.CategoryList1);
			}
		});
	}

	public getCategory2List() {
		const requestObject: string = '';
		this.httpService.invokeService(this.serviceConfigs.ADMIN_APP_CONFIG.GET_ALL_CATEGORY2_LIST, requestObject).then((response: SupplierCategory2Response) => {
			if (response && response?.payload) {
				this.CategoryList2 = response?.payload;
				this.filteredCategoryList2.next(this.CategoryList2);
			}
		});
	}

	public save() {
		this.savedCategories = [];
		let json: any = {
			"category1": this.category1Ctrl.value,
			"category2": this.category2Ctrl.value
		}
		this.savedCategories.push(json);
		this.dialogclose();
	}
	
	protected setInitialValue() {
		this.filteredCategoryList1
			.pipe(take(1), takeUntil(this._onDestroy))
			.subscribe(() => {
				this.multiSelect1.compareWith = (a: any, b: any) => a && b && a.categoryId === b.categoryId;
			});

		this.filteredCategoryList2
			.pipe(take(1), takeUntil(this._onDestroy))
			.subscribe(() => {
				this.multiSelect2.compareWith = (a: any, b: any) => a && b && a.categoryId === b.categoryId;
			});
	}

	protected filterCategoryList1() {
		if (!this.CategoryList1) {
			return;
		}
		// get the search keyword
		let search: string = this.CategoryList1FilterCtrl.value;
		if (!search) {
			this.filteredCategoryList1.next(this.CategoryList1.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		// filter the banks
		this.filteredCategoryList1.next(
			this.CategoryList1.filter(data => data.categoryName.toLowerCase().indexOf(search) > -1)
		);
	}
	
	protected filterCategoryList2() {
		if (!this.CategoryList2) {
			return;
		}
		// get the search keyword
		let search = this.CategoryList2FilterCtrl.value;
		if (!search) {
			this.filteredCategoryList2.next(this.CategoryList2.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		// filter the banks
		this.filteredCategoryList2.next(
			this.CategoryList2.filter(data => data.categoryName.toLowerCase().indexOf(search) > -1)
		);
	}

	public dialogclose() {
		this.dialogRef.close(this.savedCategories);
	}
}

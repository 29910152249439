
<div>
  <h2 mat-dialog-title>View Supplier Response  <span><mat-icon (click)="dialogclose()" class="float-right cursor-pointer icon-18">close</mat-icon> </span> </h2>
</div>
<mat-dialog-content class="mat-typography view-rfq-respond" id="print-content">
    <div style="padding-bottom: 32px;" class="p-l-16 w-full" fxFlex="90"  fxLayout="row wrap">
        <div fxFlex="100" fxLayout="row wrap" fxLayoutGap="20px grid">
          <div fxFlex="100" fxFlex.lt-sm="100">
              <div style="padding: 10px 0px; font-weight: bold; font-size: 14px; line-height: 19px; color: #22181C;" >Responded By {{ companyName }}</div> 
          </div>

            <div fxFlex="50" fxFlex.lt-sm="100">
                <div class="text-grey-500" >Incoterms</div> 
                <div class="text-grey-900" > {{  buyerDetails?.incotermsDescription  }} </div>
            </div>
            <div fxFlex="50" fxFlex.lt-sm="100">
              <div class="text-grey-500">Address</div>
              <div class="text-grey-900">{{ buyerDetails?.address ? buyerDetails?.address : '-' }}</div>
            </div>
            <!-- <div fxFlex="50" fxFlex.lt-sm="100">
              <div class="text-grey-500" >Validity Date </div> 
              <div class="text-grey-900" > {{ buyerDetails?.validityDate | date: 'dd MMM yyyy h:mm a' }}</div>
            </div> -->
            <div fxFlex="50" fxFlex.lt-sm="100">
              <div class="text-grey-500" >Currency</div> 
              <div class="text-grey-900" >{{ buyerDetails?.currencyName }} </div>
            </div>
            <div fxFlex="50" fxFlex.lt-sm="100">
              <div class="text-grey-500" >Local Agent or Distributor </div> 
              <div class="text-grey-900" >{{ buyerDetails?.isDistributor == true ? 'Yes' : 'No' }} </div>
            </div>
        </div>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutGap="20px grid">
          <div fxFlex="50" fxFlex.lt-sm="100">
            <div style="padding: 10px 0px; font-weight: bold; font-size: 14px; line-height: 19px; color: #22181C;" >Products and Services</div>
          </div>
        </div>
        
          <ng-container  *ngFor="let data of buyerDetails?.tenderResponseDetailsDTO" >
            <div fxFlex="100" fxLayout="row wrap" class="w-full" style="border: 1px solid #DBDBDB; margin: 10px 0px;">     
                    <div fxLayout="row wrap" fxFlex="100"  fxLayoutGap="20px grid" style="background-color: #F9F9F9;padding:10px 10px 0px 10px">
                      <div fxFlex="50" fxFlex.lt-sm="100">
                        <span class="key-style" >Item No : </span> 
                        <span class="value-style" >{{data?.sNo}}</span>
                      </div>
                        <div fxFlex="50" fxFlex.lt-sm="100">
                          <span class="key-style" >Part Number : </span> 
                          <span class="value-style" >{{data.partNo}}</span>
                      </div>
                      <div fxFlex="50" fxFlex.lt-sm="100">
                          <span class="key-style" >Name : </span> 
                          <span class="value-style" >{{data.productName}}</span>
                      </div>
                      <div fxFlex="50" fxFlex.lt-sm="100">
                          <span class="key-style" >Required Quantity : </span> 
                          <span class="value-style" >{{data.quantity}}</span>
                        </div>
                        <div fxFlex="50" fxFlex.lt-sm="100">
                          <span class="key-style" >UOM : </span> 
                          <span class="value-style" >{{data.uom}}</span>
                        </div>
                        <div fxFlex="100" fxLayout="row wrap">
                          <span class="key-style" >Description :&nbsp;</span> 
                          <span class="value-style" >{{data.productDescription}}</span>
                      </div>
                    </div>
                 
                      <div fxLayout="row wrap" fxFlex="100" fxLayoutGap="20px grid" style="padding:20px 10px 10px 10px;">

                          <div fxFlex="50" fxFlex.lt-sm="100">
                              <div class="text-grey-500" >Unit Price (Exclusive of Goods & Services Tax)</div> 
                              <div class="text-grey-900" > {{  data.unitPrice  }} </div>
                          </div>
                          <div fxFlex="50" fxFlex.lt-sm="100">
                            <div class="text-grey-500" >EUC required</div> 
                            <div class="text-grey-900" > {{ data.eucRequired == true ? 'Yes' : 'No' }}</div>
                        </div>
                          <div fxFlex="50" fxFlex.lt-sm="100">
                            <div class="text-grey-500" >Country of Origin</div> 
                            <div class="text-grey-900" >{{data.countryName}} </div>
                        </div>
                        <div fxFlex="50" fxFlex.lt-sm="100">
                          <div class="text-grey-500" >Delivery Lead-Time (in calendar days)</div> 
                          <div class="text-grey-900" >{{data.leadTime}} </div>
                        </div>
                        <div fxFlex="50" fxFlex.lt-sm="100">
                          <div class="text-grey-500" >Storage Shelf-Life (in calendar days)</div> 
                          <div class="text-grey-900" >{{data.storageShelfLife}} </div>
                        </div>
                        <div fxFlex="50" fxFlex.lt-sm="100">
                          <div class="text-grey-500" >Obsolence {{data.obsolence ? ((data.obsolenceMonths !== null && data.obsolenceMonths !== 0 && data.obsolenceMonths > 60) ? 'Beyond 5 years' : 'in Next 1-5 years') : ''}}</div>
                          <div class="text-grey-900" >{{ data.obsolence ? 'Yes' : 'No' }} {{data.obsolenceMonths !== null && data.obsolenceMonths !== 0 ? data.obsolenceMonths + ' Months': ''}}</div>
                        </div>
                        <div fxFlex="50" fxFlex.lt-sm="100">
                          <div class="text-grey-500" >Warranty (in months)</div> 
                          <div class="text-grey-900" >{{data.warrentyMonths}} </div>
                        </div>
                        <div fxFlex="100" fxFlex.lt-sm="100">
                          <div class="text-grey-500" >Remarks</div> 
                          <div class="text-grey-900" >{{data.remarks}} </div>
                        </div>
                    </div>
                  </div>
            </ng-container>
            <!-- <div fxFlex="100" fxLayout="row wrap" fxLayoutGap="20px grid">
              <div fxFlex="50" fxFlex.lt-sm="100">
                <div style="padding: 10px 0px; font-weight: bold; font-size: 14px; line-height: 19px; color: #22181C;" >Pricing Summary</div>
              </div>
            </div>
            <ng-container *ngFor="let data of buyerDetails?.tenderResponsePricingSummaryDTOList">
              <div fxFlex="100"
                    fxLayout="row wrap"
                    class="w-full"
                    style="border: 1px solid #DBDBDB; margin: 10px 0px;">
                <div fxLayout="row wrap"
                      fxFlex="100"
                      fxLayoutGap="20px grid"
                      style="background-color: #F9F9F9;padding:10px 10px 0px 10px">
                  <div fxFlex="100"
                        fxLayout="row wrap">
                    <span class="key-style">Description :&nbsp;</span>
                    <span class="value-style word-break">{{data.description}}</span>
                  </div>
                </div>
                
                <div fxLayout="row wrap"
                      fxFlex="100"
                      fxLayoutGap="20px grid"
                      style="padding:20px 10px 10px 10px;">
  
                  <div fxFlex="50"
                        fxFlex.lt-sm="100">
                    <div class="text-grey-500">Price</div>
                    <div class="text-grey-900"> {{  data.price  }} </div>
                  </div>
                  <div fxFlex="50" fxFlex.lt-sm="100">
                    <div class="text-grey-500" >Currency</div> 
                    <div class="text-grey-900" > {{ data.currencyName }}</div>
                  </div>
                  <div fxFlex="100"
                        fxFlex.lt-sm="100">
                    <div class="text-grey-500">Remarks</div>
                    <div class="text-grey-900">{{data.pricingRemarks}} </div>
                  </div>
                </div>
              </div>
            </ng-container> -->
        <div fxFlex="100" fxLayout="row wrap" >
            <div class="text-view-heading m-t-32" fxFlex="100">Supplier Note to Buyer</div>
            <div class="text-grey-900" fxFlex="100">{{buyerDetails?.note | dashIfEmpty}}</div>
        </div>  
           
        <div class="m-t-32" fxFlex="100" fxLayout="row wrap" fxLayoutGap="30px grid">
          <div class="text-view-heading m-t-32" fxFlex="100">Document Details</div> 
          <!-- <div fxFlex="50" fxFlex.lt-sm="100">
              <div class="text-grey-500" >File name of the product</div> 
              <div class="text-grey-900" > {{buyerDetails?.filename != undefined && buyerDetails?.filename != '' && buyerDetails?.filename != null ? buyerDetails?.filename : '-'}} </div>
          </div> -->
          <div  *ngIf="buyerDetails?.listOfAttachments !== undefined && buyerDetails?.listOfAttachments.length > 0" fxFlex="100" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Attachments </div> 
            <div class="value-name m-t-8" fxLayout="row wrap" fxLayoutGap="10px"> 
              <div *ngFor="let data of buyerDetails?.listOfAttachments"> 
                  <div *ngIf="data != ''"  class="text-grey-900 " ><span matPrefix><mat-icon class="icon-12">attach_file</mat-icon>&nbsp;</span> 
                        <span style="text-decoration: underline;cursor: pointer;" (click)="downloadFile(data)">{{data}}</span>
                    </div>
              </div> 
            </div>
          </div>
          <div fxFlex="50" *ngIf="buyerDetails?.link != null" fxFlex.lt-sm="100">
            <div class="text-grey-500" >Website url </div> 
            <div class="text-grey-900 " > <a style="color:red; text-decoration: underline;" href="https://{{buyerDetails?.link}}" target="_blank">{{buyerDetails?.link | dashIfEmpty}}</a></div>
          </div>
        </div>
      </div>  
</mat-dialog-content>
<mat-dialog-actions *ngIf="data.screen === 'award'" align="center">
    <button class="m-r-8 cancel-button" (click)="dialogclose()" mat-stroked-button > Cancel </button>
    <button class="m-r-8 action-button"  mat-stroked-button (click)="print()" > Print</button>
    <button class="m-r-8 action-button" mat-stroked-button > Send Message </button>
</mat-dialog-actions>


